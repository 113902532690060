import { tabletSize } from "src/utils/layout";
import styled from "styled-components";

export const PriceSelection = styled.section`
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  box-sizing: border-box;
  padding: 20px;

  .price-inner-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 100%;
    background-color: #eeeff2;
    border-radius: 6px;
    padding: 20px;
  }

  .left-area {
    position: relative;

    .tooltip {
      position: absolute;
      top: -65px;
      left: -270px;
      width: 253px;

      font-size: 12px;
      line-height: 1.42;
      font-weight: 500;
    }

    .help-btn {
      height: 23px;
      box-sizing: border-box;
      padding: 0 6px;
      border-radius: 12px;
      border: solid 0.5px rgba(151, 151, 151, 0.2);
      background-color: rgba(248, 248, 252, 0.2);

      .text {
        font-size: 9.8px;
        margin-left: 5px;
        color: black;
        vertical-align: 1px;
      }
    }

    .guide-msg {
      margin-top: 5px;
      font-size: 12px;
      line-height: 1.4;
    }
  }

  .right-area {
    text-align: right;

    .text {
      font-size: 13px;
      font-weight: 600;

      color: #262e3a;
      vertical-align: middle;
    }

    .amount {
      font-size: 28px;
      font-weight: 600;
      color: #262e3a;
      margin-left: 5px;
      vertical-align: -3px;
    }
  }

  @media (min-width: ${tabletSize}) {
    margin-top: 30px;
    margin-bottom: 16px;
    border: solid 1px #d0d2e1;
    box-shadow: 0 1px 5px 0 rgba(122, 139, 169, 0.1);
    background-color: #e1e2e8;
    border-radius: 11.2px;
    padding: 0;

    .price-inner-wrapper {
      background-color: transparent;
    }

    .left-area {
      .guide-msg {
        font-size: 14px;
      }
    }
  }
`;
