import axios from "axios";
import apiS3 from "src/api/service/s3";
import { API_SERVER } from "src/constants/configs";
import { API_SERVER_TIMEOUT } from "src/constants/api";
import { loggingAction } from "./logging";
import apiGQL from "src/api/service/gql";

const axiosAPI = axios.create({
  baseURL: API_SERVER,
  timeout: API_SERVER_TIMEOUT,
  withCredentials: true,
});

const api = Object.freeze({
  s3: apiS3(axiosAPI),
  gql: apiGQL(axiosAPI),
  loggingAction,
});

export default api;
