import { gql, useQuery } from "@apollo/client";

const sources: { code: string; name: string }[] = [
  { code: "NOVEL", name: "소설" },
  { code: "SCREENPLAY", name: "대본" },
  { code: "SYNOPSIS", name: "시놉시스" },
  { code: "INTRODUCTION", name: "소개서" },
  { code: "LECTURE", name: "강의자료" },
  { code: "THESIS", name: "논문" },
  { code: "CONTRACT", name: "계약서" },
  { code: "OTHER", name: "기타" },
];

function useSourceCode(code?: string) {
  return {
    source: sources.find((item) => item.code === code),
  };
}

export default useSourceCode;
