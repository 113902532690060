import * as S from "./Overlay.style";

interface IProps {
  handleCancelClick?: (e?: React.MouseEvent<HTMLElement>) => void;
}

const Overlay = ({ handleCancelClick }: IProps) => {
  return <S.Overlay onClick={handleCancelClick} />;
};

export default Overlay;
