import { tabletSize } from "src/utils/layout";
import styled from "styled-components";

export const TextTranslationWrapper = styled.section`
  width: 100%;
  box-sizing: border-box;

  .estimate-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  .estimate-form-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
  }

  @media (min-width: ${tabletSize}) {
    padding: 0;
    .estimate-form-wrapper {
      gap: 20px;
      position: relative;

      .estimate-form {
        width: 840px;
      }
    }
  }
`;
