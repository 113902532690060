import { Hash } from "fast-sha256";

// File Select
export interface SelectFileOptions {
  accept?: string | string[];
  multiple?: boolean;
  directory?: boolean;
}

export function selectFile(options: SelectFileOptions = {}) {
  const { accept = "", multiple = false, directory = false } = options;

  const input = document.createElement("input");
  input.type = "file";
  input.accept = Array.isArray(accept) ? accept.join(",") : accept;
  input.multiple = multiple;
  if (directory) {
    input.setAttribute("webkitdirectory", "");
  }

  return new Promise<File[] | null>((res) => {
    function onChange(e: Event) {
      const input = e.currentTarget as HTMLInputElement;
      if (!input.files) {
        return res(null);
      }

      const arr = Array.from(input.files).filter((it) => !/^\./.test(it.name));
      return res(arr);
    }

    function onBlur() {
      document.removeEventListener("mousedown", onBlur);
      document.removeEventListener("keydown", onBlur);

      res(null);
    }

    input.addEventListener("change", onChange);

    document.addEventListener("mousedown", onBlur, { once: true });
    document.addEventListener("keydown", onBlur, { once: true });
    input.click();
  }).finally(() => {
    input.remove();
  });
}

// Download File
interface DownloadOption {
  filename?: string;
}

/**
 * Download File by url
 * @param url     File link address
 * @param options {@link DownloadOption}
 */
export function download(url: string, options?: DownloadOption) {
  const { filename = "" } = options ?? {};

  const anchor = document.createElement("a");
  anchor.style.display = "none";
  anchor.href = url;
  anchor.setAttribute("download", filename);

  if (!("download" in anchor) || typeof anchor.download === "undefined") {
    anchor.setAttribute("target", "_blank");
  }

  document.body.appendChild(anchor);
  anchor.click();
  anchor.remove();
}

/**
 * Download File by blob using {@link download} function
 * @param blob      Blob data
 * @param filename  Download file name
 */
export function downloadBlob(blob: Blob, filename: string) {
  const blobURL = URL.createObjectURL(blob);
  download(blobURL, { filename });
  URL.revokeObjectURL(blobURL);
}

export async function getFileChecksumSha256(file: File) {
  const hash = new Hash();
  const stream = file.stream();
  await stream.pipeTo(
    new WritableStream({
      write: (chunk) => {
        hash.update(chunk);
      },
    }),
  );

  const sha256Array = hash.digest();
  const sha256String = [...sha256Array]
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
  return sha256String;
}

export const extensionCode = [
  {
    code: "EXC0001",
    name: "MP4",
  },
  {
    code: "EXC0002",
    name: "AVI",
  },
  {
    code: "EXC0003",
    name: "MKV",
  },
  {
    code: "EXC0004",
    name: "MPG",
  },
  {
    code: "EXC0005",
    name: "FLV",
  },
  {
    code: "EXC0006",
    name: "WMV",
  },
  {
    code: "EXC0007",
    name: "MOV",
  },
  {
    code: "EXC0008",
    name: "OGV",
  },
  {
    code: "EXC0009",
    name: "OGM",
  },
  {
    code: "EXC0010",
    name: "ASF",
  },
  {
    code: "EXC0011",
    name: "ASX",
  },
  {
    code: "EXC0012",
    name: "SMI",
  },
  {
    code: "EXC0013",
    name: "SRT",
  },
  {
    code: "EXC0014",
    name: "ASS",
  },
  {
    code: "EXC0015",
    name: "IDX",
  },
  {
    code: "EXC0016",
    name: "SUB",
  },
  {
    code: "EXC0017",
    name: "SUP",
  },
  {
    code: "EXC0018",
    name: "PNG",
  },
  {
    code: "EXC0019",
    name: "JPEG",
  },
  {
    code: "EXC0020",
    name: "JPG",
  },
  {
    code: "EXC0021",
    name: "JPE",
  },
  {
    code: "EXC0022",
    name: "BMP",
  },
  {
    code: "EXC0023",
    name: "GIF",
  },
  {
    code: "EXC0024",
    name: "SVG",
  },
  {
    code: "EXC0025",
    name: "PSD",
  },
  {
    code: "EXC0026",
    name: "PSB",
  },
  {
    code: "EXC0027",
    name: "TXT",
  },
  {
    code: "EXC0028",
    name: "DOC",
  },
  {
    code: "EXC0029",
    name: "DOCX",
  },
  {
    code: "EXC0030",
    name: "HWP",
  },
  {
    code: "EXC0031",
    name: "HWPX",
  },
  {
    code: "EXC0032",
    name: "PDF",
  },
  {
    code: "EXC0033",
    name: "PPT",
  },
  {
    code: "EXC0034",
    name: "PPTX",
  },
  {
    code: "EXC0035",
    name: "XLS",
  },
  {
    code: "EXC0036",
    name: "XLSX",
  },
  {
    code: "EXC0037",
    name: "SUBTITLE_EDITOR_SOURCE",
  },
  {
    code: "EXC0038",
    name: "DOCUMENT_EDITOR_SOURCE",
  },
  {
    code: "EXC0039",
    name: "EVALUATE_EDITOR_SOURCE",
  },
  {
    code: "EXC0040",
    name: "QA_EDITOR_SOURCE",
  },
  {
    code: "EXC0041",
    name: "TOON_EDITOR_SOURCE",
  },
  {
    code: "EXC0042",
    name: "VIDEO_SOURCE",
  },
  {
    code: "EXC0043",
    name: "TOON_SOURCE",
  },
  {
    code: "EXC0044",
    name: "TIF",
  },
];

export const arrowFileType = ["docx", "xlsx", "txt", "srt", "pptx", "pdf"];
