import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  JSON: any;
  LocalDate: any;
  Long: any;
  OffsetDateTime: any;
  UUID: any;
};

export enum AcceptanceStatus {
  Accepted = "ACCEPTED",
  Rejected = "REJECTED",
  Waiting = "WAITING",
}

export type AggregateAssociation = {
  __typename?: "AggregateAssociation";
  id: Scalars["UUID"];
};

export type AggregateAssociationInput = {
  id: Scalars["UUID"];
};

export type ApplicablePositionState = {
  __typename?: "ApplicablePositionState";
  alreadyProcessingApplication: Scalars["Boolean"];
  reApplicationEarly: Scalars["Boolean"];
  skillObtained: Scalars["Boolean"];
};

export type Application = BaseAggregateRoot & {
  __typename?: "Application";
  applicationMethod: ApplicationMethod;
  attachments: Array<ApplicationAttachment>;
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  canceled: Scalars["Boolean"];
  careerMonth: Scalars["Int"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  currentProcess?: Maybe<CurrentProcess>;
  documentProcess: ApplicationDocumentProcess;
  evaluation?: Maybe<ApplicationEvaluation>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  pass?: Maybe<Scalars["Boolean"]>;
  position: Position;
  positionUuid: Scalars["UUID"];
  recommendCodePromotionType: RecommendCodePromotionType;
  recruitmentEvaluationItems: Array<RecruitmentEvaluationItem>;
  recruitmentEvaluationResults: Array<RecruitmentEvaluationResult>;
  testProcesses: Array<ApplicationTestProcess>;
  uuid: Scalars["UUID"];
};

export type ApplicationRecruitmentEvaluationItemsArgs = {
  deleted?: InputMaybe<Scalars["Boolean"]>;
  itemPurposes?: InputMaybe<Array<RecruitmentEvaluationItemPurpose>>;
};

export type ApplicationRecruitmentEvaluationResultsArgs = {
  itemPurposes?: InputMaybe<Array<RecruitmentEvaluationItemPurpose>>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type ApplicationAttachment = {
  __typename?: "ApplicationAttachment";
  name: Scalars["String"];
  s3Path: Scalars["String"];
  type: AttachmentType;
  volume?: Maybe<Scalars["Int"]>;
};

export type ApplicationCount = {
  __typename?: "ApplicationCount";
  failCount: Scalars["Int"];
  notEvaluatedCount: Scalars["Int"];
  passCount: Scalars["Int"];
  total: Scalars["Int"];
};

export type ApplicationDocumentProcess = {
  __typename?: "ApplicationDocumentProcess";
  createdDate: Scalars["OffsetDateTime"];
  evaluation?: Maybe<DocumentEvaluation>;
  evaluator: Auth;
  modifiedDate: Scalars["OffsetDateTime"];
  skip: Scalars["Boolean"];
  skippedBy?: Maybe<Auth>;
  step: Scalars["Int"];
  uuid: Scalars["UUID"];
};

export type ApplicationEvaluation = BaseAggregateRoot & {
  __typename?: "ApplicationEvaluation";
  application: Application;
  applicationUuid: Scalars["UUID"];
  bid: Scalars["String"];
  comment?: Maybe<Scalars["String"]>;
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  grade: EvaluationGrade;
  modifiedBy?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  pass: Scalars["Boolean"];
  uuid: Scalars["UUID"];
};

export type ApplicationEvaluationPageResponse = {
  __typename?: "ApplicationEvaluationPageResponse";
  content: Array<ApplicationEvaluation>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export enum ApplicationMethod {
  PocEvaluationCriteriaMet = "POC_EVALUATION_CRITERIA_MET",
  SystemPositionApplication = "SYSTEM_POSITION_APPLICATION",
  UserPositionApplication = "USER_POSITION_APPLICATION",
}

export type ApplicationPageResponse = {
  __typename?: "ApplicationPageResponse";
  content: Array<Application>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type ApplicationStateCount = {
  __typename?: "ApplicationStateCount";
  applicationCount: ApplicationCount;
  processStateCount: ProcessStateCount;
};

export type ApplicationTestProcess = {
  __typename?: "ApplicationTestProcess";
  allCandidateTestGuides: Array<TestGuide>;
  application: Application;
  applicationUuid: Scalars["UUID"];
  createdDate: Scalars["OffsetDateTime"];
  equalStepPositionTestProcess?: Maybe<PositionTestProcess>;
  evaluation?: Maybe<TestEvaluation>;
  evaluator?: Maybe<Auth>;
  examEvaluationTasks: Array<Task>;
  examTask?: Maybe<Task>;
  limitMinuit: Scalars["Int"];
  modifiedDate: Scalars["OffsetDateTime"];
  skippedBy?: Maybe<Auth>;
  state: TestProcessState;
  step: Scalars["Int"];
  test?: Maybe<Test>;
  testGuideUuids: Array<Scalars["UUID"]>;
  testGuides: Array<TestGuide>;
  testSet?: Maybe<TestSet>;
  testSetUuid?: Maybe<Scalars["UUID"]>;
  uuid: Scalars["UUID"];
};

export type ApplicationTestProcessExamEvaluationTasksArgs = {
  assignees?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type ApplicationTestProcessExamTaskArgs = {
  assignees?: InputMaybe<Array<Scalars["UUID"]>>;
};

export enum AttachmentType {
  AdditionalAttachment = "ADDITIONAL_ATTACHMENT",
  Portfolio = "PORTFOLIO",
  Resume = "RESUME",
}

export type Auth = BaseAggregateRoot & {
  __typename?: "Auth";
  assignedExamEvaluationTask: Array<Task>;
  assignedTask: Array<Task>;
  banned: Scalars["Boolean"];
  bannedDate?: Maybe<Scalars["OffsetDateTime"]>;
  bannedReason?: Maybe<Scalars["String"]>;
  bid: Scalars["String"];
  blocked: Scalars["Boolean"];
  blockedDate?: Maybe<Scalars["OffsetDateTime"]>;
  blockedReason?: Maybe<Scalars["String"]>;
  /** @deprecated Move to user */
  capability?: Maybe<Capability>;
  /** @deprecated Move to user */
  contact?: Maybe<Contact>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  /** @deprecated Move to user */
  deposit?: Maybe<Deposit>;
  email: Scalars["String"];
  evaluablePOCTasks: TaskPageResponse;
  googleEmail?: Maybe<Scalars["String"]>;
  googleOauthId?: Maybe<Scalars["String"]>;
  issuedRecommendCode?: Maybe<RecommendCode>;
  jobProcessRole: Array<Scalars["String"]>;
  leaved: Scalars["Boolean"];
  leavedDate?: Maybe<Scalars["OffsetDateTime"]>;
  leavedReason?: Maybe<Scalars["String"]>;
  maskedEmail: Scalars["String"];
  modifiedBy?: Maybe<Scalars["UUID"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  monthlyTaskCount: MonthlyTaskCount;
  oauths?: Maybe<Array<OAuth>>;
  phone?: Maybe<AuthPhone>;
  /** @deprecated Move to user */
  profile?: Maybe<Profile>;
  rangeSchedule: RangeSchedule;
  recommendCodeUseHistory?: Maybe<RecommendCodeUseHistory>;
  restrictionHistory: AuthRestrictionHistoryPageResponse;
  restrictionHistoryRecent?: Maybe<AuthRestrictionHistory>;
  roles: Array<Role>;
  skills?: Maybe<Array<Skill>>;
  /** @deprecated Move to user */
  termAgreement?: Maybe<TermAgreement>;
  usedRecommendCodeHistoriesPage: RecommendCodeUseHistoryPageResponse;
  user: User;
  uuid: Scalars["UUID"];
  watched: Scalars["Boolean"];
  watchedDate?: Maybe<Scalars["OffsetDateTime"]>;
  watchedReason?: Maybe<Scalars["String"]>;
  worker?: Maybe<Worker>;
};

export type AuthAssignedExamEvaluationTaskArgs = {
  applicationTestProcessUuidIsNull?: InputMaybe<Scalars["Boolean"]>;
  positionTestProcessUuidIsNull?: InputMaybe<Scalars["Boolean"]>;
  sort?: InputMaybe<Array<SortInput>>;
  states?: InputMaybe<Array<TaskState>>;
  workingPeriodEndDate?: InputMaybe<Scalars["OffsetDateTime"]>;
  workingPeriodStartDate?: InputMaybe<Scalars["OffsetDateTime"]>;
};

export type AuthAssignedTaskArgs = {
  applicationTestProcessUuidIsNull?: InputMaybe<Scalars["Boolean"]>;
  dueDateIsNull?: InputMaybe<Scalars["Boolean"]>;
  payIsNull?: InputMaybe<Scalars["Boolean"]>;
  positionTestProcessUuidIsNull?: InputMaybe<Scalars["Boolean"]>;
  sort?: InputMaybe<Array<SortInput>>;
  states?: InputMaybe<Array<TaskState>>;
  taskPurposes?: InputMaybe<Array<TaskPurpose>>;
  workingPeriodEndDate?: InputMaybe<Scalars["OffsetDateTime"]>;
  workingPeriodStartDate?: InputMaybe<Scalars["OffsetDateTime"]>;
};

export type AuthEvaluablePocTasksArgs = {
  criteria: EvaluablePocTaskCriteriaInput;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type AuthJobProcessRoleArgs = {
  jobProcessUuid: Scalars["UUID"];
};

export type AuthMonthlyTaskCountArgs = {
  standardDate: Scalars["OffsetDateTime"];
};

export type AuthRangeScheduleArgs = {
  endDate: Scalars["OffsetDateTime"];
  startDate: Scalars["OffsetDateTime"];
};

export type AuthRestrictionHistoryArgs = {
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<AuthRestrictionType>;
  value?: InputMaybe<Scalars["Boolean"]>;
};

export type AuthRestrictionHistoryRecentArgs = {
  type: AuthRestrictionType;
  value?: InputMaybe<Scalars["Boolean"]>;
};

export type AuthUsedRecommendCodeHistoriesPageArgs = {
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type AuthPageResponse = {
  __typename?: "AuthPageResponse";
  content: Array<Auth>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type AuthPhone = {
  __typename?: "AuthPhone";
  callingCode: Scalars["String"];
  number: Scalars["String"];
};

export type AuthRestrictionHistory = BaseAggregateRoot & {
  __typename?: "AuthRestrictionHistory";
  auth: Auth;
  authUuid: Scalars["UUID"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  reason?: Maybe<Scalars["String"]>;
  restrictedBy: Auth;
  type: AuthRestrictionType;
  uuid: Scalars["UUID"];
  value: Scalars["Boolean"];
};

export type AuthRestrictionHistoryPageResponse = {
  __typename?: "AuthRestrictionHistoryPageResponse";
  content: Array<AuthRestrictionHistory>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export enum AuthRestrictionType {
  Banned = "BANNED",
  Blocked = "BLOCKED",
  Watch = "WATCH",
}

export type BankCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "BankCode";
    bankNumeric: Scalars["String"];
    code: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description: Scalars["String"];
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
  };

export type BaseAggregateRoot = {
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
};

export type CallingCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "CallingCode";
    callingNumeric: Scalars["String"];
    code: Scalars["String"];
    countryCode: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
  };

export type Capability = BaseAggregateRoot & {
  __typename?: "Capability";
  adult?: Maybe<Scalars["Boolean"]>;
  allGenre?: Maybe<Scalars["Boolean"]>;
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  bl?: Maybe<Scalars["Boolean"]>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  genreCodes: Array<Scalars["String"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  night?: Maybe<Scalars["Boolean"]>;
  notWorkableDays?: Maybe<Array<DayOfWeek>>;
  positions: Array<CapabilityPosition>;
  timezoneCode?: Maybe<Scalars["String"]>;
  tools: Array<ToolType>;
  translationStyle?: Maybe<TranslationStyle>;
  uuid: Scalars["UUID"];
};

export type CapabilityPageResponse = {
  __typename?: "CapabilityPageResponse";
  content: Array<Capability>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type CapabilityPosition = {
  __typename?: "CapabilityPosition";
  skillTypeCode: Scalars["String"];
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
};

export type CareerAttachment = BaseAggregateRoot & {
  __typename?: "CareerAttachment";
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deleted: Scalars["Boolean"];
  deletedBy?: Maybe<Auth>;
  deletedDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name: Scalars["String"];
  s3Path: Scalars["String"];
  type: AttachmentType;
  uuid: Scalars["UUID"];
  volume: Scalars["Int"];
};

export type CareerAttachmentPageResponse = {
  __typename?: "CareerAttachmentPageResponse";
  content: Array<CareerAttachment>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type CarrierCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "CarrierCode";
    code: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    iamportCarrierName: Scalars["String"];
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    mvno: Scalars["Boolean"];
    name: Scalars["String"];
  };

export type Client = BaseAggregateRoot & {
  __typename?: "Client";
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name: Scalars["String"];
  originAccountId?: Maybe<Scalars["String"]>;
  originId?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
};

export type ClientPageResponse = {
  __typename?: "ClientPageResponse";
  content: Array<Client>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type ClientQueryRequestInput = {
  charge?: InputMaybe<Scalars["UUID"]>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  name?: InputMaybe<Scalars["String"]>;
  originAccountId?: InputMaybe<Scalars["String"]>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type CodeAssociation = {
  __typename?: "CodeAssociation";
  id: Scalars["String"];
};

export type CodeAssociationInput = {
  id: Scalars["String"];
};

export type Comment = BaseAggregateRoot & {
  __typename?: "Comment";
  attachments: Array<CommentAttachment>;
  checked: Scalars["Boolean"];
  clientChecked?: Maybe<Scalars["Boolean"]>;
  content?: Maybe<Scalars["String"]>;
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deleted: Scalars["Boolean"];
  deletedBy: Auth;
  important: Scalars["Boolean"];
  jobProcessUuid?: Maybe<Scalars["UUID"]>;
  jobUuid?: Maybe<Scalars["UUID"]>;
  modifiedBy: Auth;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  operationTypeCode?: Maybe<Scalars["String"]>;
  operationUuid?: Maybe<Scalars["UUID"]>;
  projectTypeCode?: Maybe<Scalars["String"]>;
  projectUuid?: Maybe<Scalars["UUID"]>;
  resolved: Scalars["Boolean"];
  scope: CommentScope;
  tagUuids: Array<Scalars["UUID"]>;
  tags: Array<Tag>;
  task: Task;
  taskUuid?: Maybe<Scalars["UUID"]>;
  todos: Array<CommentTodo>;
  type: CommentType;
  unitPointerGroupUuids: Array<Scalars["UUID"]>;
  unitPointerGroups: Array<UnitPointerGroup>;
  uuid: Scalars["UUID"];
};

export type CommentAttachment = BaseAggregateRoot & {
  __typename?: "CommentAttachment";
  comment: Comment;
  commentUuid: Scalars["UUID"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deleted: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  fileName: Scalars["String"];
  fileTypeCode?: Maybe<Scalars["String"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  s3Path: Scalars["String"];
  uuid: Scalars["UUID"];
};

export type CommentCriteriaInput = {
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  checked?: InputMaybe<Scalars["Boolean"]>;
  important?: InputMaybe<Scalars["Boolean"]>;
  jobProcessUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  jobUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  operationTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  operationUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  projectUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  resolved?: InputMaybe<Scalars["Boolean"]>;
  taskUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  types?: InputMaybe<Array<CommentType>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type CommentMention = BaseAggregateRoot & {
  __typename?: "CommentMention";
  auth: Auth;
  authUuid?: Maybe<Scalars["UUID"]>;
  checked: Scalars["Boolean"];
  comment: Comment;
  commentScope: CommentScope;
  commentType: CommentType;
  commentUuid: Scalars["UUID"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  important: Scalars["Boolean"];
  jobProcessUuid?: Maybe<Scalars["UUID"]>;
  jobUuid?: Maybe<Scalars["UUID"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  operationTypeCode?: Maybe<Scalars["String"]>;
  operationUuid?: Maybe<Scalars["UUID"]>;
  projectUuid?: Maybe<Scalars["UUID"]>;
  read: Scalars["Boolean"];
  taskUuid?: Maybe<Scalars["UUID"]>;
  uuid: Scalars["UUID"];
};

export type CommentPageResponse = {
  __typename?: "CommentPageResponse";
  content: Array<Comment>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  sort: CommentPageResponse;
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type CommentPageResponseSortArgs = {
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export enum CommentPositionType {
  Image = "IMAGE",
  Text = "TEXT",
}

export enum CommentScope {
  Job = "JOB",
  JobProcess = "JOB_PROCESS",
  Operation = "OPERATION",
  Project = "PROJECT",
  Task = "TASK",
}

export type CommentTodo = BaseAggregateRoot & {
  __typename?: "CommentTodo";
  checked: Scalars["Boolean"];
  checkedBy?: Maybe<Auth>;
  checkedDate?: Maybe<Scalars["OffsetDateTime"]>;
  checkedTask?: Maybe<Task>;
  checkedTaskUuid?: Maybe<Scalars["UUID"]>;
  comment: Comment;
  commentUuid: Scalars["UUID"];
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  createdTask: Task;
  createdTaskUuid: Scalars["UUID"];
  deleted: Scalars["Boolean"];
  deletedBy?: Maybe<Auth>;
  deletedDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  operationTypeCode: Scalars["String"];
  uncheckedBy?: Maybe<Auth>;
  uncheckedDate?: Maybe<Scalars["OffsetDateTime"]>;
  uuid: Scalars["UUID"];
};

export enum CommentType {
  Feedback = "FEEDBACK",
  LegacySupport = "LEGACY_SUPPORT",
  Normal = "NORMAL",
}

export type CommonCode = {
  code: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name: Scalars["String"];
};

export type CommonResponse = {
  succeed: Scalars["Boolean"];
};

export type CompatibilityPaySpecInput = {
  currency: CodeAssociationInput;
  pay: AggregateAssociationInput;
};

export type Contact = BaseAggregateRoot & {
  __typename?: "Contact";
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  callingCode?: Maybe<Scalars["String"]>;
  carrierCode?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  kakaoId?: Maybe<Scalars["String"]>;
  lineId?: Maybe<Scalars["String"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
  wechatId?: Maybe<Scalars["String"]>;
};

export type ContactPageResponse = {
  __typename?: "ContactPageResponse";
  content: Array<Contact>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type ContentGqlModel = {
  __typename?: "ContentGqlModel";
  bid: Scalars["String"];
  createdBy: Auth;
  createdDate: Scalars["OffsetDateTime"];
  creator?: Maybe<Scalars["String"]>;
  eventVersion: Scalars["Long"];
  id: Scalars["UUID"];
  metadata: ContentMetadata;
  name?: Maybe<Scalars["String"]>;
  prefix: Scalars["String"];
  type: ContentType;
  version: Scalars["Long"];
};

export type ContentGqlModelBidArgs = {
  format?: InputMaybe<GqlBusinessIdFormat>;
};

export type ContentMetadata = DocumentContentMetadata | ToonContentMetadata;

export type ContentQuerySpecInput = {
  ids?: InputMaybe<Array<Scalars["UUID"]>>;
  searchKeyword?: InputMaybe<Scalars["String"]>;
  types?: InputMaybe<Array<ContentType>>;
};

export type ContentTranslation = BaseAggregateRoot & {
  __typename?: "ContentTranslation";
  contentUuid: Scalars["UUID"];
  createdBy?: Maybe<Auth>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  language: Scalars["String"];
  modifiedBy?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  translation: Scalars["String"];
  uuid: Scalars["UUID"];
};

export enum ContentType {
  Document = "DOCUMENT",
  Toon = "TOON",
}

export type ContentTypeCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "ContentTypeCode";
    code: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
  };

export type Contract = BaseAggregateRoot & {
  __typename?: "Contract";
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  canceled: Scalars["Boolean"];
  /** @deprecated Move to auth */
  contractee: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  fileName?: Maybe<Scalars["String"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  modusignDocumentId?: Maybe<Scalars["UUID"]>;
  modusignSigningParticipantId?: Maybe<Scalars["String"]>;
  s3Path?: Maybe<Scalars["String"]>;
  signed: Scalars["Boolean"];
  signedDate?: Maybe<Scalars["OffsetDateTime"]>;
  uuid: Scalars["UUID"];
};

export type ContractPageResponse = {
  __typename?: "ContractPageResponse";
  content: Array<Contract>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type CountryCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "CountryCode";
    code: Scalars["String"];
    countryAlpha2: Scalars["String"];
    countryAlpha3: Scalars["String"];
    countryNumeric: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
  };

export type Coupon = BaseAggregateRoot & {
  __typename?: "Coupon";
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  count: Scalars["Int"];
  couponHistories: Array<CouponHistory>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  uuid: Scalars["UUID"];
};

export type CouponHistory = BaseAggregateRoot & {
  __typename?: "CouponHistory";
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  chargeCount?: Maybe<Scalars["Int"]>;
  count: Scalars["Int"];
  coupon: Coupon;
  couponSourceType: CouponSourceType;
  couponUuid: Scalars["UUID"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  historyType: CouponHistoryType;
  modifiedBy?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  useCount?: Maybe<Scalars["Int"]>;
  uuid: Scalars["UUID"];
};

export enum CouponHistoryType {
  Charge = "CHARGE",
  Init = "INIT",
  Use = "USE",
}

export enum CouponSourceType {
  AuthCreated = "AUTH_CREATED",
  InitialAuthVerificated = "INITIAL_AUTH_VERIFICATED",
  PocDelivered = "POC_DELIVERED",
  PocEvaluationExpertCreated = "POC_EVALUATION_EXPERT_CREATED",
  PocEvaluationExpertRequested = "POC_EVALUATION_EXPERT_REQUESTED",
  PocEvaluationNormalCreated = "POC_EVALUATION_NORMAL_CREATED",
  SkillCreated = "SKILL_CREATED",
}

export type CurrencyCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "CurrencyCode";
    code: Scalars["String"];
    countryCode?: Maybe<Scalars["String"]>;
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    currencyAlpha: Scalars["String"];
    currencyNumeric: Scalars["String"];
    description?: Maybe<Scalars["String"]>;
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
  };

export type CurrentProcess = {
  __typename?: "CurrentProcess";
  applicationUuid: Scalars["UUID"];
  documentProcess?: Maybe<ApplicationDocumentProcess>;
  state: CurrentProcessState;
  testProcess?: Maybe<ApplicationTestProcess>;
};

export enum CurrentProcessState {
  Evaluated = "EVALUATED",
  Start = "START",
  Submitted = "SUBMITTED",
}

export type CustomerGqlModel = {
  __typename?: "CustomerGqlModel";
  bid: Scalars["String"];
  compatibilityClient: Client;
  createdBy: Auth;
  createdDate: Scalars["OffsetDateTime"];
  description?: Maybe<Scalars["String"]>;
  email?: Maybe<Email>;
  eventVersion: Scalars["Long"];
  id: Scalars["UUID"];
  name: Scalars["String"];
  phone?: Maybe<Phone>;
  prefix: Scalars["String"];
  temporary: Scalars["Boolean"];
  version: Scalars["Long"];
};

export type CustomerGqlModelBidArgs = {
  format?: InputMaybe<GqlBusinessIdFormat>;
};

export type CustomerQuerySpecInput = {
  email?: InputMaybe<EmailInput>;
  ids?: InputMaybe<Array<Scalars["UUID"]>>;
  name?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<PhoneInput>;
  searchKeyword?: InputMaybe<Scalars["String"]>;
  temporary?: InputMaybe<Scalars["Boolean"]>;
};

export enum DayAndNight {
  Day = "DAY",
  DayAndNight = "DAY_AND_NIGHT",
  Night = "NIGHT",
}

export enum DayOfWeek {
  Friday = "FRIDAY",
  Monday = "MONDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
  Thursday = "THURSDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
}

export type DaySchedule = {
  __typename?: "DaySchedule";
  day: Scalars["OffsetDateTime"];
  dayAndNight?: Maybe<DayAndNight>;
  notWorkableDayOfWeeks: Array<NotWorkableDayOfWeek>;
  notWorkableDays: Array<NotWorkableDay>;
  workable: Scalars["Boolean"];
  workableDay?: Maybe<WorkableDay>;
};

export type Delivery = BaseAggregateRoot & {
  __typename?: "Delivery";
  bid: Scalars["String"];
  confirmedBy?: Maybe<Auth>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deliveredBy?: Maybe<Auth>;
  deliveredDate?: Maybe<Scalars["OffsetDateTime"]>;
  editorResource?: Maybe<EditorResource>;
  editorResourceUuid?: Maybe<Scalars["UUID"]>;
  languageCode?: Maybe<Scalars["String"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  operation: Operation;
  operationUuid: Scalars["UUID"];
  originalEditorResourceUuid?: Maybe<Scalars["UUID"]>;
  rejectedBy?: Maybe<Scalars["UUID"]>;
  resourceTypeCode: Scalars["String"];
  state: DeliveryState;
  task: Task;
  taskUuid: Scalars["UUID"];
  unchanged?: Maybe<Scalars["Boolean"]>;
  uuid: Scalars["UUID"];
};

export type DeliveryEditorResourceArgs = {
  includeDeleted?: InputMaybe<Scalars["Boolean"]>;
  lastUpdate?: InputMaybe<Scalars["OffsetDateTime"]>;
};

export enum DeliveryState {
  Confirmed = "CONFIRMED",
  Delivered = "DELIVERED",
  Init = "INIT",
  Rejected = "REJECTED",
}

export enum DeliveryType {
  BurnInAdvanced = "BURN_IN_ADVANCED",
  BurnInBasic = "BURN_IN_BASIC",
  NoBurnIn = "NO_BURN_IN",
  NoTypesetter = "NO_TYPESETTER",
  TypesettingPageToPage = "TYPESETTING_PAGE_TO_PAGE",
  TypesettingPageToScroll = "TYPESETTING_PAGE_TO_SCROLL",
  TypesettingScrollToPage = "TYPESETTING_SCROLL_TO_PAGE",
  TypesettingScrollToScroll = "TYPESETTING_SCROLL_TO_SCROLL",
}

export type Deposit = BaseAggregateRoot & {
  __typename?: "Deposit";
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  businessRegistration?: Maybe<S3File>;
  countryCode: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  korea?: Maybe<KoreaDeposit>;
  maskedTaxReturnNumber?: Maybe<Scalars["String"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  otherAccount?: Maybe<OtherAccountDeposit>;
  paypal?: Maybe<PaypalDeposit>;
  taxReturnNumber?: Maybe<Scalars["String"]>;
  taxReturnNumberSet: Scalars["Boolean"];
  type: DepositType;
  uuid: Scalars["UUID"];
};

export type DepositPageResponse = {
  __typename?: "DepositPageResponse";
  content: Array<Deposit>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export enum DepositType {
  AccountKo = "ACCOUNT_KO",
  AccountOther = "ACCOUNT_OTHER",
  Paypal = "PAYPAL",
}

export type DeprecatedSkill = {
  __typename?: "DeprecatedSkill";
  skillTypeCode: Scalars["String"];
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
};

export enum DifficultyLevel {
  Easy = "EASY",
  Hard = "HARD",
  None = "NONE",
  Normal = "NORMAL",
  VeryEasy = "VERY_EASY",
  VeryHard = "VERY_HARD",
}

export type DirectoryGqlModel = {
  __typename?: "DirectoryGqlModel";
  bid: Scalars["String"];
  createdBy: Auth;
  createdDate: Scalars["OffsetDateTime"];
  deleted: Scalars["Boolean"];
  descendants: Array<FileSystemGqlModel>;
  directories: Array<DirectoryGqlModel>;
  eventVersion: Scalars["Long"];
  files: Array<FileGqlModel>;
  id: Scalars["UUID"];
  name?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  parent?: Maybe<AggregateAssociation>;
  parentId?: Maybe<Scalars["UUID"]>;
  prefix: Scalars["String"];
  root: AggregateAssociation;
  rootId: Scalars["UUID"];
  version: Scalars["Long"];
};

export type DirectoryGqlModelBidArgs = {
  format?: InputMaybe<GqlBusinessIdFormat>;
};

export type DirectoryQuerySpecInput = {
  deleted?: InputMaybe<Scalars["Boolean"]>;
  ids?: InputMaybe<Array<Scalars["UUID"]>>;
  parents?: InputMaybe<Array<Scalars["UUID"]>>;
  roots?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type DocumentContentMetadata = {
  __typename?: "DocumentContentMetadata";
  type: DocumentType;
};

export type DocumentEvaluation = BaseAggregateRoot & {
  __typename?: "DocumentEvaluation";
  application: Application;
  applicationUuid: Scalars["UUID"];
  bid: Scalars["String"];
  comment?: Maybe<Scalars["String"]>;
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  document: ApplicationDocumentProcess;
  documentProcessUuid: Scalars["UUID"];
  grade: EvaluationGrade;
  modifiedBy?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  pass: Scalars["Boolean"];
  uuid: Scalars["UUID"];
};

export type DocumentEvaluationPageResponse = {
  __typename?: "DocumentEvaluationPageResponse";
  content: Array<DocumentEvaluation>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type DocumentQuotationCreateRequestInput = {
  deadline?: InputMaybe<Scalars["OffsetDateTime"]>;
  delivery: DocumentQuotationDeliveryInput;
  notes?: InputMaybe<Scalars["String"]>;
  proposedPrice: Scalars["BigDecimal"];
  quotationRequest: AggregateAssociationInput;
  sourceLanguage: CodeAssociationInput;
  sourceType: DocumentType;
  targetLanguages: Array<CodeAssociationInput>;
  workload: WorkloadInput;
};

export type DocumentQuotationCreateResponse = CommonResponse & {
  __typename?: "DocumentQuotationCreateResponse";
  quotation: QuotationGqlModel;
  succeed: Scalars["Boolean"];
};

export type DocumentQuotationDelivery = {
  __typename?: "DocumentQuotationDelivery";
  fileExtension: CodeAssociation;
};

export type DocumentQuotationDeliveryInput = {
  fileExtension: CodeAssociationInput;
};

export type DocumentQuotationRejectRequestInput = {
  quotationRequest: AggregateAssociationInput;
  reason?: InputMaybe<Scalars["String"]>;
};

export type DocumentQuotationRejectResponse = CommonResponse & {
  __typename?: "DocumentQuotationRejectResponse";
  quotation: QuotationGqlModel;
  succeed: Scalars["Boolean"];
};

export type DocumentQuotationRequestCreateRequestInput = {
  contact: QuotationRequestContactRequestInput;
  estimatedProductPrice?: InputMaybe<Scalars["BigDecimal"]>;
  requirement: QuotationRequestRequirementInput;
  source: SourceGqlModelInput;
  workload: WorkloadInput;
  works: Array<TextWorkRequestInput>;
};

export type DocumentQuotationRequestCreateResponse = CommonResponse & {
  __typename?: "DocumentQuotationRequestCreateResponse";
  quotationRequest: QuotationRequestGqlModel;
  succeed: Scalars["Boolean"];
};

export type DocumentQuotationRequestSourceGqlModel = {
  __typename?: "DocumentQuotationRequestSourceGqlModel";
  directory: AggregateAssociation;
  sourceDirectory: DirectoryGqlModel;
  type: DocumentType;
};

export type DocumentQuotationRequestWork = {
  __typename?: "DocumentQuotationRequestWork";
  sourceLanguage: CodeAssociation;
  targetLanguages: Array<CodeAssociation>;
};

export type DocumentQuotationSchedule = {
  __typename?: "DocumentQuotationSchedule";
  deliveryDeadline?: Maybe<Scalars["OffsetDateTime"]>;
};

export type DocumentQuotationWork = {
  __typename?: "DocumentQuotationWork";
  notes?: Maybe<Scalars["String"]>;
  sourceLanguage: CodeAssociation;
  targetLanguages: Array<CodeAssociation>;
  workload: Workload;
};

export type DocumentResource = {
  __typename?: "DocumentResource";
  letterCount?: Maybe<Scalars["Long"]>;
  wordCount?: Maybe<Scalars["Long"]>;
};

export enum DocumentType {
  Contract = "CONTRACT",
  Introduction = "INTRODUCTION",
  Lecture = "LECTURE",
  Novel = "NOVEL",
  Other = "OTHER",
  Screenplay = "SCREENPLAY",
  Synopsis = "SYNOPSIS",
  Thesis = "THESIS",
}

export type Editor = {
  __typename?: "Editor";
  inputs: Array<EditorSource>;
  outputs: Array<EditorSource>;
};

export type EditorCommentReadCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "EditorCommentReadCode";
    code: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    feedback: Scalars["Boolean"];
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
    operationTypeCode: Scalars["String"];
    positionType: CommentPositionType;
    sourceOperationTypeCode: Scalars["String"];
  };

export type EditorCommentWriteCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "EditorCommentWriteCode";
    code: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    feedback: Scalars["Boolean"];
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
    operationTypeCode: Scalars["String"];
    positionType: CommentPositionType;
  };

export type EditorResource = BaseAggregateRoot & {
  __typename?: "EditorResource";
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  editorResourceTypeCode: Scalars["String"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  units: Array<EditorUnit>;
  uuid: Scalars["UUID"];
};

export type EditorResourceTypeCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "EditorResourceTypeCode";
    code: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
  };

export type EditorSource = {
  __typename?: "EditorSource";
  editorResource: EditorResource;
  editorResourceTypeCode: Scalars["String"];
  editorResourceUuid: Scalars["UUID"];
  languageCode?: Maybe<Scalars["String"]>;
  resourceTypeCode: Scalars["String"];
};

export type EditorUnit = {
  __typename?: "EditorUnit";
  createdBy?: Maybe<Auth>;
  createdDate: Scalars["OffsetDateTime"];
  deleted: Scalars["Boolean"];
  downloadUrl?: Maybe<Scalars["String"]>;
  editorResourceTypeCode: Scalars["String"];
  editorResourceUuid: Scalars["UUID"];
  history: Array<EditorUnit>;
  key: Scalars["UUID"];
  modifiedDate: Scalars["OffsetDateTime"];
  originEditorUnitUuids: Array<Scalars["UUID"]>;
  originEditorUnits: Array<EditorUnit>;
  position: Scalars["JSON"];
  psdPreview?: Maybe<PsdPreview>;
  s3Path?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
  value: Scalars["JSON"];
  versionHistory: Array<EditorUnit>;
};

export type EditorUnitVersionHistoryArgs = {
  languageCode?: InputMaybe<Scalars["String"]>;
  resourceTypeCode?: InputMaybe<Scalars["String"]>;
  taskUuid: Scalars["UUID"];
};

export type EducationQualification = BaseAggregateRoot & {
  __typename?: "EducationQualification";
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  endDate?: Maybe<Scalars["OffsetDateTime"]>;
  graduated: Scalars["Boolean"];
  majorName: Scalars["String"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  schoolName: Scalars["String"];
  startDate: Scalars["OffsetDateTime"];
  uuid: Scalars["UUID"];
};

export type Email = {
  __typename?: "Email";
  address: Scalars["String"];
};

export type EmailInput = {
  address: Scalars["String"];
};

export type EmptyModel = {
  empty: Scalars["Boolean"];
};

export type ErrorGqlModel = {
  __typename?: "ErrorGqlModel";
  code: ExceptionCode;
  message: Scalars["String"];
  type: ExceptionType;
};

export type ErrorResponse = CommonResponse & {
  __typename?: "ErrorResponse";
  errors: Array<ErrorGqlModel>;
  succeed: Scalars["Boolean"];
};

export type EvaluablePocTaskCriteriaInput = {
  adult?: InputMaybe<Scalars["Boolean"]>;
  blglGenre?: InputMaybe<Scalars["Boolean"]>;
  contentTypeCode?: InputMaybe<Scalars["String"]>;
  expertPocEvaluationRequested?: InputMaybe<Scalars["Boolean"]>;
  skillTypeCode?: InputMaybe<Scalars["String"]>;
  sourceLanguageCodeOrTargetLanguageCode?: InputMaybe<Scalars["String"]>;
};

export enum EvaluationGrade {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
}

export type ExamEvaluationPayInfo = {
  __typename?: "ExamEvaluationPayInfo";
  currencyCode: Scalars["String"];
  pay: Pay;
  payUuid: Scalars["UUID"];
  payValue: Scalars["Float"];
};

export type ExceptionCode = {
  __typename?: "ExceptionCode";
  code: Scalars["String"];
  prefix: Scalars["String"];
};

export enum ExceptionType {
  Business = "BUSINESS",
  Forbidden = "FORBIDDEN",
  InvalidRequest = "INVALID_REQUEST",
  System = "SYSTEM",
}

export type ExtensionCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "ExtensionCode";
    code: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    fileTypeCode: Scalars["String"];
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
  };

export type ExtensionGroupCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "ExtensionGroupCode";
    code: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    extensionCodes: Array<Scalars["String"]>;
    fileTypeCode: Scalars["String"];
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
  };

export type FeedbackTagAttribute = BaseAggregateRoot & {
  __typename?: "FeedbackTagAttribute";
  bid: Scalars["String"];
  contentTypeCode: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deleted: Scalars["Boolean"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  operationTypeCode: Scalars["String"];
  tagUuid: Scalars["UUID"];
  targetType: FeedbackTargetType;
  uuid: Scalars["UUID"];
};

export type FeedbackTagCriteriaInput = {
  contentTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  operationTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  parentUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  rootUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  targetTypes?: InputMaybe<Array<Scalars["String"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export enum FeedbackTargetType {
  DeliveryFile = "DELIVERY_FILE",
  TextBox = "TEXT_BOX",
  TypesettingImage = "TYPESETTING_IMAGE",
}

export type File = BaseAggregateRoot & {
  __typename?: "File";
  bid: Scalars["String"];
  createdBy?: Maybe<Auth>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  creationType: FileCreationType;
  hide: Scalars["Boolean"];
  job?: Maybe<Job>;
  jobUuid?: Maybe<Scalars["UUID"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name?: Maybe<Scalars["String"]>;
  original: Scalars["Boolean"];
  project?: Maybe<Project>;
  projectUuid?: Maybe<Scalars["UUID"]>;
  psdPreview?: Maybe<PsdPreview>;
  s3Path?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
};

export enum FileCreationType {
  Direct = "DIRECT",
  Reference = "REFERENCE",
  Resource = "RESOURCE",
}

export enum FileDirectoryType {
  Directory = "DIRECTORY",
  File = "FILE",
}

export enum FileDownloadHttpMethod {
  Get = "GET",
  Head = "HEAD",
}

export type FileDownloadUrlGenerationRequestInput = {
  fileName?: InputMaybe<Scalars["String"]>;
  httpMethod: FileDownloadHttpMethod;
  s3Path: Scalars["String"];
};

export type FileDownloadUrlGenerationResponse = {
  __typename?: "FileDownloadUrlGenerationResponse";
  downloadUrl: Scalars["String"];
  s3Path: Scalars["String"];
};

export type FileGqlModel = {
  __typename?: "FileGqlModel";
  bid: Scalars["String"];
  createdBy: Auth;
  createdDate: Scalars["OffsetDateTime"];
  deleted: Scalars["Boolean"];
  directory?: Maybe<AggregateAssociation>;
  directoryId?: Maybe<Scalars["UUID"]>;
  editable: Scalars["Boolean"];
  eventVersion: Scalars["Long"];
  id: Scalars["UUID"];
  name: Scalars["String"];
  order?: Maybe<Scalars["Int"]>;
  prefix: Scalars["String"];
  resource?: Maybe<FileResource>;
  resourceSync?: Maybe<Scalars["Boolean"]>;
  resourceType?: Maybe<ResourceType>;
  s3Object: S3ObjectGqlModel;
  size: Scalars["Long"];
  temporary: Scalars["Boolean"];
  version: Scalars["Long"];
};

export type FileGqlModelBidArgs = {
  format?: InputMaybe<GqlBusinessIdFormat>;
};

export type FileQuerySpecInput = {
  deleted?: InputMaybe<Scalars["Boolean"]>;
  directories?: InputMaybe<Array<Scalars["UUID"]>>;
  ids?: InputMaybe<Array<Scalars["UUID"]>>;
  roots?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type FileResource = DocumentResource | ToonResource;

export type FileSystemGqlModel = DirectoryGqlModel | FileGqlModel;

export type FileSystemRestructureSpecInput = {
  contents?: InputMaybe<Array<FileSystemRestructureSpecInput>>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  fileSystemElement?: InputMaybe<AggregateAssociationInput>;
  name?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  type: FileDirectoryType;
  updatedDate?: InputMaybe<Scalars["OffsetDateTime"]>;
};

export type FileTemporaryCreateRequestInput = {
  extension: CodeAssociationInput;
  name: Scalars["String"];
  s3ObjectUrl: Scalars["String"];
  size: Scalars["Long"];
};

export type FileTemporaryCreateResponse = CommonResponse & {
  __typename?: "FileTemporaryCreateResponse";
  file: FileGqlModel;
  succeed: Scalars["Boolean"];
};

export type FileTypeCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "FileTypeCode";
    code: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
  };

export type FixedSettlement = BaseAggregateRoot & {
  __typename?: "FixedSettlement";
  auth: Auth;
  authUuid: Scalars["UUID"];
  baseDate: Scalars["OffsetDateTime"];
  completedDate?: Maybe<Scalars["OffsetDateTime"]>;
  createdBy?: Maybe<Auth>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  depositType: DepositType;
  korea?: Maybe<KoreaResponse>;
  modifiedBy?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  otherAccount?: Maybe<OtherAccountResponse>;
  paypal?: Maybe<PaypalResponse>;
  rejectReason?: Maybe<Scalars["String"]>;
  rejectedBy: Auth;
  rejectedDate?: Maybe<Scalars["OffsetDateTime"]>;
  state: FixedSettlementState;
  targetSettlementUuids: Array<Scalars["UUID"]>;
  targetSettlements: Array<TargetSettlement>;
  uuid: Scalars["UUID"];
};

export type FixedSettlementPageResponse = {
  __typename?: "FixedSettlementPageResponse";
  content: Array<FixedSettlement>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type FixedSettlementQueryRequestInput = {
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  baseDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  baseDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  depositType?: InputMaybe<DepositType>;
  states?: InputMaybe<Array<FixedSettlementState>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export enum FixedSettlementState {
  Completed = "COMPLETED",
  Fixed = "FIXED",
  Modified = "MODIFIED",
  Rejected = "REJECTED",
}

export enum Gender {
  Female = "FEMALE",
  Male = "MALE",
}

export type GenreCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "GenreCode";
    code: Scalars["String"];
    contentTypeCode: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
  };

export enum GqlBusinessIdFormat {
  Legacy = "LEGACY",
  Prefix = "PREFIX",
  PrefixShort = "PREFIX_SHORT",
}

export type IdentifiableAssociationInput = {
  id: Scalars["UUID"];
};

export type Job = BaseAggregateRoot & {
  __typename?: "Job";
  bid: Scalars["String"];
  contentTypeCode: Scalars["String"];
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deadline?: Maybe<Scalars["OffsetDateTime"]>;
  disabled: Scalars["Boolean"];
  examSourceJob?: Maybe<Job>;
  examSourceJobUuid?: Maybe<Scalars["UUID"]>;
  files: Array<File>;
  index?: Maybe<Scalars["Int"]>;
  jobProcess: Array<JobProcess>;
  jobTypeCode?: Maybe<Scalars["String"]>;
  links: Array<Link>;
  liveTranslation: Scalars["Boolean"];
  manager: Auth;
  modifiedBy?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name: Scalars["String"];
  operations: Array<Operation>;
  pocDifficulty?: Maybe<Scalars["String"]>;
  pocEvaluationLimitMinute?: Maybe<Scalars["Long"]>;
  pocSourceJob?: Maybe<Job>;
  pocSourceJobUuid?: Maybe<Scalars["UUID"]>;
  project: Project;
  projectUuid: Scalars["UUID"];
  recommended: Scalars["Boolean"];
  referenceTypeCode?: Maybe<Scalars["String"]>;
  references: Array<Reference>;
  resources: Array<Resource>;
  taskPurpose: TaskPurpose;
  taskState?: Maybe<JobTaskState>;
  transfer?: Maybe<Transfer>;
  uuid: Scalars["UUID"];
};

export type JobCriteriaInput = {
  clientUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  contentTypeCode?: InputMaybe<Scalars["String"]>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  deadlineFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  deadlineTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  disableDeadline?: InputMaybe<Scalars["Boolean"]>;
  disabled?: InputMaybe<Scalars["Boolean"]>;
  jobProcessManagerUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  liveTranslation?: InputMaybe<Scalars["Boolean"]>;
  managerUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  name?: InputMaybe<Scalars["String"]>;
  pocSourceJobUuid?: InputMaybe<Scalars["UUID"]>;
  projectUuid?: InputMaybe<Scalars["UUID"]>;
  projectUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  recommended?: InputMaybe<Scalars["Boolean"]>;
  taskPurposes?: InputMaybe<Array<TaskPurpose>>;
  taskState?: InputMaybe<JobTaskState>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type JobPageResponse = {
  __typename?: "JobPageResponse";
  content: Array<Job>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type JobProcess = {
  __typename?: "JobProcess";
  currencyCode?: Maybe<CurrencyCode>;
  directedReference?: Maybe<Reference>;
  dueDateModificationReason?: Maybe<JobProcessDueDateModificationReason>;
  endDate?: Maybe<Scalars["OffsetDateTime"]>;
  history: Array<JobProcessHistory>;
  job: Job;
  jobUuid: Scalars["UUID"];
  manager?: Maybe<Auth>;
  operations: Array<Operation>;
  priceValue?: Maybe<Scalars["Float"]>;
  progress?: Maybe<JobProcessProgress>;
  projectOrder: ProjectOrder;
  resources: Array<Resource>;
  siblings: Array<JobProcess>;
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["OffsetDateTime"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  taskState: JobProcessTaskState;
  uuid: Scalars["UUID"];
};

export enum JobProcessDueDateModificationReason {
  CustomerRequest = "CUSTOMER_REQUEST",
  Etc = "ETC",
  InternalRequest = "INTERNAL_REQUEST",
  Retake = "RETAKE",
}

export type JobProcessHistory = BaseAggregateRoot & {
  __typename?: "JobProcessHistory";
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  dueDateModificationReason?: Maybe<JobProcessDueDateModificationReason>;
  endDate?: Maybe<Scalars["OffsetDateTime"]>;
  historyType: JobProcessHistoryType;
  jobProcessUuid: Scalars["UUID"];
  modifiedBy?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  progress?: Maybe<JobProcessProgress>;
  startDate?: Maybe<Scalars["OffsetDateTime"]>;
  uuid: Scalars["UUID"];
};

export type JobProcessHistoryCriteriaInput = {
  dueDateModificationReasons?: InputMaybe<Array<JobProcessDueDateModificationReason>>;
  historyTypes?: InputMaybe<Array<JobProcessHistoryType>>;
  jobProcessUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type JobProcessHistoryPageResponse = {
  __typename?: "JobProcessHistoryPageResponse";
  content: Array<JobProcessHistory>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export enum JobProcessHistoryType {
  DueDate = "DUE_DATE",
  Init = "INIT",
  Progress = "PROGRESS",
}

export enum JobProcessProgress {
  FirstSetting = "FIRST_SETTING",
  NormalTask = "NORMAL_TASK",
  ProjectPoc = "PROJECT_POC",
  Setup = "SETUP",
}

export enum JobProcessTaskState {
  Complete = "COMPLETE",
  Drop = "DROP",
  Preparing = "PREPARING",
  Processing = "PROCESSING",
}

export enum JobState {
  Done = "DONE",
  Drop = "DROP",
  Processing = "PROCESSING",
  Registered = "REGISTERED",
  Requested = "REQUESTED",
  Requesting = "REQUESTING",
  Reviewable = "REVIEWABLE",
}

export enum JobTaskState {
  Complete = "COMPLETE",
  Drop = "DROP",
  Preparing = "PREPARING",
  Processing = "PROCESSING",
}

export type JobTypeCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "JobTypeCode";
    code: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
    practical: Scalars["Boolean"];
  };

export type KoreaDeposit = BaseAggregateRoot & {
  __typename?: "KoreaDeposit";
  accountHolder?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  bankCode?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
};

export type KoreaResponse = {
  __typename?: "KoreaResponse";
  accountHolder: Scalars["String"];
  accountNumber: Scalars["String"];
  bankCode: Scalars["String"];
  maskedTaxReturnNumber?: Maybe<Scalars["String"]>;
  taxReturnNumber: Scalars["String"];
};

export type LanguageCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "LanguageCode";
    code: Scalars["String"];
    countryCode: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    engName: Scalars["String"];
    index: Scalars["Int"];
    languageAlpha2: Scalars["String"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    modusign_template_supported: Scalars["Boolean"];
    name: Scalars["String"];
  };

export enum LanguageLevel {
  High = "HIGH",
  Low = "LOW",
  Middle = "MIDDLE",
  Native = "NATIVE",
}

export enum LanguageOption {
  None = "NONE",
  SourceOnly = "SOURCE_ONLY",
  SourceTarget = "SOURCE_TARGET",
  TargetOnly = "TARGET_ONLY",
}

export type LanguageQualification = BaseAggregateRoot & {
  __typename?: "LanguageQualification";
  acquiredDate: Scalars["OffsetDateTime"];
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  description: Scalars["String"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  uuid: Scalars["UUID"];
};

export enum Level {
  Basic = "BASIC",
  High = "HIGH",
  Middle = "MIDDLE",
}

export type Link = BaseAggregateRoot & {
  __typename?: "Link";
  bid: Scalars["String"];
  createdBy?: Maybe<Auth>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  creationType: LinkCreationType;
  hide: Scalars["Boolean"];
  job?: Maybe<Job>;
  jobUuid?: Maybe<Scalars["UUID"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name?: Maybe<Scalars["String"]>;
  original: Scalars["Boolean"];
  project?: Maybe<Project>;
  projectUuid?: Maybe<Scalars["UUID"]>;
  url: Scalars["String"];
  uuid: Scalars["UUID"];
};

export enum LinkCreationType {
  Direct = "DIRECT",
  Reference = "REFERENCE",
}

export type ManualWorkerMatchingCandidateCreateRequestInput = {
  worker: IdentifiableAssociationInput;
  workerMatchingProcess: AggregateAssociationInput;
};

export type ManualWorkerMatchingCandidateCreateResponse = CommonResponse & {
  __typename?: "ManualWorkerMatchingCandidateCreateResponse";
  succeed: Scalars["Boolean"];
  workerMatchingCandidate: WorkerMatchingCandidateGqlModel;
};

export type ManualWorkerMatchingProposalSendRequestInput = {
  workerMatchingProcess: AggregateAssociationInput;
};

export type ManualWorkerMatchingProposalSendResponse = CommonResponse & {
  __typename?: "ManualWorkerMatchingProposalSendResponse";
  succeed: Scalars["Boolean"];
  workerMatchingProcess: WorkerMatchingProcessGqlModel;
};

export enum MarketingType {
  Email = "EMAIL",
  Sms = "SMS",
}

export type MatchingAlgorithmWorkerCandidateSelectionFactor = {
  __typename?: "MatchingAlgorithmWorkerCandidateSelectionFactor";
  finalOrder: Scalars["Int"];
  recentCompletedTaskNumbers: Scalars["Int"];
  sameCustomerProjectNumbers: Scalars["Int"];
  sameGenreProjectNumbers: Scalars["Int"];
};

export type Messenger = {
  __typename?: "Messenger";
  id: Scalars["String"];
  type: MessengerType;
};

export enum MessengerType {
  Kakao = "KAKAO",
  Line = "LINE",
}

export type MonthlyTaskCount = {
  __typename?: "MonthlyTaskCount";
  taskCount: Array<Scalars["Int"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  completePrepaymentWaiting: CommonResponse;
  createDocumentQuotation: DocumentQuotationCreateResponse;
  createDocumentQuotationRequest: DocumentQuotationRequestCreateResponse;
  createFileTemporary: FileTemporaryCreateResponse;
  createManualWorkerMatchingCandidate: ManualWorkerMatchingCandidateCreateResponse;
  createProjectByProductQuotation: ProjectCreateByQuotationProductResponse;
  createQuotationPresetAutomaticWorkMatchingProcess: QuotationPresetAutomaticWorkMatchingProcessCreateResponse;
  createQuotationPresetManualWorkMatchingProcess: QuotationPresetManualWorkMatchingProcessCreateResponse;
  createToonQuotation: ToonQuotationCreateResponse;
  createToonQuotationProduct: ToonQuotationProductCreateResponse;
  createTrinoQuery: TrinoQueryCreateResponse;
  deleteToonQuotationProduct: ToonQuotationProductDeleteResponse;
  deleteWorkerMatchingCandidate: WorkerMatchingCandidateDeleteResponse;
  deselectWorkerMatchingCandidate: WorkerMatchingCandidateDeselectResponse;
  evaluateToonDifficulty: ToonQuotationProductDifficultyEvaluateResponse;
  initializeQuotationPresetWorkMatchingProcess: QuotationPresetWorkMatchingProcessInitializeResponse;
  rejectDocumentQuotation: DocumentQuotationRejectResponse;
  respondWorkerMatchingCandidate: WorkerMatchingCandidateRespondResponse;
  selectWorkerMatchingCandidate: WorkerMatchingCandidateSelectResponse;
  sendProposalManualWorkerMatching: ManualWorkerMatchingProposalSendResponse;
  updateFinalizedPrice: UpdateFinalizedPriceResponse;
  updateProposedPrice: UpdateProposedPriceResponse;
  updateQuotationPresetWorkPrice: QuotationPresetWorkPriceUpdateResponse;
  updateQuotationPresetWorkerNotAssignment: QuotationPresetWorkerNotAssignmentUpdateResponse;
  updateToonQuotation: ToonQuotationUpdateResponse;
  updateToonQuotationProduct: ToonQuotationProductUpdateResponse;
  updateWorkerMatchingProcessNextProcess: WorkerMatchingProcessNextProcessUpdateResponse;
};

export type MutationCompletePrepaymentWaitingArgs = {
  request: PrepaymentWaitingCompleteRequestInput;
};

export type MutationCreateDocumentQuotationArgs = {
  request: DocumentQuotationCreateRequestInput;
};

export type MutationCreateDocumentQuotationRequestArgs = {
  request: DocumentQuotationRequestCreateRequestInput;
};

export type MutationCreateFileTemporaryArgs = {
  request: FileTemporaryCreateRequestInput;
};

export type MutationCreateManualWorkerMatchingCandidateArgs = {
  request: ManualWorkerMatchingCandidateCreateRequestInput;
};

export type MutationCreateProjectByProductQuotationArgs = {
  request: ProjectCreateByQuotationProductRequestInput;
};

export type MutationCreateQuotationPresetAutomaticWorkMatchingProcessArgs = {
  request: QuotationPresetAutomaticWorkMatchingProcessCreateRequestInput;
};

export type MutationCreateQuotationPresetManualWorkMatchingProcessArgs = {
  request: QuotationPresetManualWorkMatchingProcessCreateRequestInput;
};

export type MutationCreateToonQuotationArgs = {
  request: ToonQuotationCreateRequestInput;
};

export type MutationCreateToonQuotationProductArgs = {
  request: ToonQuotationProductCreateRequestInput;
};

export type MutationCreateTrinoQueryArgs = {
  request: TrinoQueryCreateRequestInput;
};

export type MutationDeleteToonQuotationProductArgs = {
  request: ToonQuotationProductDeleteRequestInput;
};

export type MutationDeleteWorkerMatchingCandidateArgs = {
  request: WorkerMatchingCandidateDeleteRequestInput;
};

export type MutationDeselectWorkerMatchingCandidateArgs = {
  request: WorkerMatchingCandidateDeselectRequestInput;
};

export type MutationEvaluateToonDifficultyArgs = {
  request: ToonQuotationProductDifficultyEvaluateRequestInput;
};

export type MutationInitializeQuotationPresetWorkMatchingProcessArgs = {
  request: QuotationPresetWorkMatchingProcessInitializeRequestInput;
};

export type MutationRejectDocumentQuotationArgs = {
  request: DocumentQuotationRejectRequestInput;
};

export type MutationRespondWorkerMatchingCandidateArgs = {
  request: WorkerMatchingCandidateRespondRequestInput;
};

export type MutationSelectWorkerMatchingCandidateArgs = {
  request: WorkerMatchingCandidateSelectRequestInput;
};

export type MutationSendProposalManualWorkerMatchingArgs = {
  request: ManualWorkerMatchingProposalSendRequestInput;
};

export type MutationUpdateFinalizedPriceArgs = {
  request: UpdateFinalizedPriceRequestInput;
};

export type MutationUpdateProposedPriceArgs = {
  request: UpdateProposedPriceRequestInput;
};

export type MutationUpdateQuotationPresetWorkPriceArgs = {
  request: QuotationPresetWorkPriceUpdateRequestInput;
};

export type MutationUpdateQuotationPresetWorkerNotAssignmentArgs = {
  request: QuotationPresetWorkerNotAssignmentUpdateRequestInput;
};

export type MutationUpdateToonQuotationArgs = {
  request: ToonQuotationUpdateRequestInput;
};

export type MutationUpdateToonQuotationProductArgs = {
  request: ToonQuotationProductUpdateRequestInput;
};

export type MutationUpdateWorkerMatchingProcessNextProcessArgs = {
  request: WorkerMatchingProcessNextProcessUpdateRequestInput;
};

export type NotWorkableDay = BaseAggregateRoot & {
  __typename?: "NotWorkableDay";
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  dayAndNight: DayAndNight;
  endDate: Scalars["OffsetDateTime"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  reason?: Maybe<NotWorkableReason>;
  startDate: Scalars["OffsetDateTime"];
  uuid: Scalars["UUID"];
};

export type NotWorkableDayOfWeek = BaseAggregateRoot & {
  __typename?: "NotWorkableDayOfWeek";
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  dayAndNight: DayAndNight;
  daysOfWeek: Array<DayOfWeek>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  reason?: Maybe<NotWorkableReason>;
  uuid: Scalars["UUID"];
};

export type NotWorkableDayOfWeekPageResponse = {
  __typename?: "NotWorkableDayOfWeekPageResponse";
  content: Array<NotWorkableDayOfWeek>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type NotWorkableDayPageResponse = {
  __typename?: "NotWorkableDayPageResponse";
  content: Array<NotWorkableDay>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export enum NotWorkableReason {
  AnotherWork = "ANOTHER_WORK",
  Holiday = "HOLIDAY",
  Personal = "PERSONAL",
}

export type OAuth = {
  __typename?: "OAuth";
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  provider: Provider;
};

export type Operation = BaseAggregateRoot & {
  __typename?: "Operation";
  autoConfirm: Scalars["Boolean"];
  autoVersionUpdate: Scalars["Boolean"];
  bid: Scalars["String"];
  complete: Scalars["Boolean"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deliveries: Array<Delivery>;
  endDate?: Maybe<Scalars["OffsetDateTime"]>;
  inputOptionalResourceUuids: Array<Scalars["UUID"]>;
  inputOptionalResources: Array<Resource>;
  inputRequiredResourceUuids: Array<Scalars["UUID"]>;
  inputRequiredResources: Array<Resource>;
  job: Job;
  jobProcess?: Maybe<JobProcess>;
  jobProcessUuid?: Maybe<Scalars["UUID"]>;
  jobUuid: Scalars["UUID"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  operationTypeCode: Scalars["String"];
  outputResourceUuids: Array<Scalars["UUID"]>;
  outputResources: Array<Resource>;
  pocTasks: Array<Task>;
  previousOperationUuid?: Maybe<Scalars["UUID"]>;
  referenceUuids: Array<Scalars["UUID"]>;
  references: Array<Reference>;
  skip: Scalars["Boolean"];
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["OffsetDateTime"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  targetSettlements: Array<TargetSettlement>;
  taskPurpose: TaskPurpose;
  tasks: Array<Task>;
  uuid: Scalars["UUID"];
  works: Array<Work>;
};

export type OperationPocTasksArgs = {
  criteria?: InputMaybe<TaskCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type OperationCriteriaInput = {
  adult?: InputMaybe<Scalars["Boolean"]>;
  assignees?: InputMaybe<Array<Scalars["UUID"]>>;
  blglGenre?: InputMaybe<Scalars["Boolean"]>;
  contentTypeCode?: InputMaybe<Scalars["String"]>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  expertPocEvaluationRequested?: InputMaybe<Scalars["Boolean"]>;
  genreCode?: InputMaybe<Scalars["String"]>;
  jobDisabled?: InputMaybe<Scalars["Boolean"]>;
  jobProcessUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  jobRecommended?: InputMaybe<Scalars["Boolean"]>;
  jobUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  normalPocEvaluationRequested?: InputMaybe<Scalars["Boolean"]>;
  normalPocEvaluationStopped?: InputMaybe<Scalars["Boolean"]>;
  operationTypeCode?: InputMaybe<Scalars["String"]>;
  operationTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  projectUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  resourceManagement?: InputMaybe<Scalars["Boolean"]>;
  resourcesPrepared?: InputMaybe<Scalars["Boolean"]>;
  skillTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  skillUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  skip?: InputMaybe<Scalars["Boolean"]>;
  sourceLanguageCode?: InputMaybe<Scalars["String"]>;
  sourceLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  sourceLanguageCodesOrTargetLanguageCodes?: InputMaybe<Scalars["Boolean"]>;
  targetLanguageCode?: InputMaybe<Scalars["String"]>;
  targetLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  taskDeleted?: InputMaybe<Scalars["Boolean"]>;
  taskPurposes?: InputMaybe<Array<TaskPurpose>>;
  taskStates?: InputMaybe<Array<TaskState>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type OperationGroupedByPosition = {
  __typename?: "OperationGroupedByPosition";
  operationTypeCode: Scalars["String"];
  pocTasks: Array<Task>;
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  uuids: Array<Scalars["UUID"]>;
};

export type OperationGroupedByPositionPocTasksArgs = {
  criteria?: InputMaybe<TaskCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type OperationGroupedByPositionPageResponse = {
  __typename?: "OperationGroupedByPositionPageResponse";
  content: Array<OperationGroupedByPosition>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type OperationPageResponse = {
  __typename?: "OperationPageResponse";
  content: Array<Operation>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type OperationPreset = BaseAggregateRoot & {
  __typename?: "OperationPreset";
  autoConfirm: Scalars["Boolean"];
  autoVersionUpdate: Scalars["Boolean"];
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  currencyCode?: Maybe<Scalars["String"]>;
  jobProcessProgress?: Maybe<JobProcessProgress>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  operationTypeCode: Scalars["String"];
  pay?: Maybe<Pay>;
  payUuid?: Maybe<Scalars["UUID"]>;
  period?: Maybe<Scalars["Int"]>;
  previousOperationPresetUuid?: Maybe<Scalars["UUID"]>;
  projectOrderUuid?: Maybe<Scalars["UUID"]>;
  projectUuid: Scalars["UUID"];
  skip: Scalars["Boolean"];
  taskType?: Maybe<TaskType>;
  uuid: Scalars["UUID"];
  worker?: Maybe<Worker>;
  workerUuid?: Maybe<Scalars["UUID"]>;
};

export type OperationQueryRequest = {
  __typename?: "OperationQueryRequest";
  createdDateFrom?: Maybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: Maybe<Scalars["OffsetDateTime"]>;
  jobProcessUuids?: Maybe<Array<Scalars["UUID"]>>;
  jobUuids?: Maybe<Array<Scalars["UUID"]>>;
  operationTypeCode?: Maybe<Scalars["String"]>;
  operationTypeCodes?: Maybe<Array<Scalars["String"]>>;
  resourcesPrepared?: Maybe<Scalars["Boolean"]>;
  skip?: Maybe<Scalars["Boolean"]>;
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  sourceLanguageCodes?: Maybe<Array<Scalars["String"]>>;
  sourceLanguageCodesOrTargetLanguageCodes?: Maybe<Scalars["Boolean"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCodes?: Maybe<Array<Scalars["String"]>>;
  taskPurposes?: Maybe<Array<TaskPurpose>>;
  uuids?: Maybe<Array<Scalars["UUID"]>>;
};

export type OperationStepCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "OperationStepCode";
    code: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
  };

export type OperationTypeCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "OperationTypeCode";
    code: Scalars["String"];
    contentTypeCode: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    editorCommentWriteCodes: Array<Scalars["String"]>;
    evaluationTargetOperationTypeCode?: Maybe<Scalars["String"]>;
    index: Scalars["Int"];
    inputOptionalResourceTypeCodes: Array<Scalars["String"]>;
    inputRequiredResourceTypeCodes: Array<Scalars["String"]>;
    languageOption: LanguageOption;
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
    operationStepCode: Scalars["String"];
    outputResourceTypeCodes: Array<Scalars["String"]>;
    skillTypeCode?: Maybe<Scalars["String"]>;
    /** @deprecated move to taskPurposes */
    taskPurpose: TaskPurpose;
    taskPurposes: Array<TaskPurpose>;
  };

export enum OrderState {
  Done = "DONE",
  Drop = "DROP",
  Processing = "PROCESSING",
  Registered = "REGISTERED",
  Requested = "REQUESTED",
  Reviewable = "REVIEWABLE",
  ReviewApproved = "REVIEW_APPROVED",
  ReviewRejected = "REVIEW_REJECTED",
}

export enum Origin {
  Jamake = "JAMAKE",
  Panoplay = "PANOPLAY",
}

export type OtherAccountDeposit = BaseAggregateRoot & {
  __typename?: "OtherAccountDeposit";
  account?: Maybe<Scalars["String"]>;
  accountHolder?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  memo?: Maybe<Scalars["String"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
};

export type OtherAccountResponse = {
  __typename?: "OtherAccountResponse";
  account?: Maybe<Scalars["String"]>;
  accountHolder?: Maybe<Scalars["String"]>;
  memo?: Maybe<Scalars["String"]>;
};

export type PocTaskEvaluationTaskCount = {
  __typename?: "POCTaskEvaluationTaskCount";
  confirmedCount: Scalars["Int"];
  deliveredCount: Scalars["Int"];
  processingCount: Scalars["Int"];
  total: Scalars["Int"];
};

export enum PanoplayFileType {
  AttachedFile = "ATTACHED_FILE",
  ContentsFile = "CONTENTS_FILE",
}

export type PanoplayJob = BaseAggregateRoot & {
  __typename?: "PanoplayJob";
  bid: Scalars["String"];
  clientDeadline?: Maybe<Scalars["OffsetDateTime"]>;
  clientId: Scalars["Int"];
  clientName: Scalars["String"];
  contentTypeCode: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deadline?: Maybe<Scalars["OffsetDateTime"]>;
  manager: Auth;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name: Scalars["String"];
  originCreatedDate: Scalars["OffsetDateTime"];
  originId: Scalars["Int"];
  originModifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  panoplayJobFiles: Array<PanoplayJobFile>;
  panoplayJobLinks: Array<PanoplayJobLink>;
  panoplayManagerId?: Maybe<Scalars["Int"]>;
  panoplayManagerName?: Maybe<Scalars["String"]>;
  panoplayOrders: Array<PanoplayOrder>;
  panoplayProject: PanoplayProject;
  panoplayProjectUuid: Scalars["UUID"];
  state: JobState;
  transfer?: Maybe<Transfer>;
  uuid: Scalars["UUID"];
};

export type PanoplayJobFile = BaseAggregateRoot & {
  __typename?: "PanoplayJobFile";
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  fileType: PanoplayFileType;
  index: Scalars["Int"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name: Scalars["String"];
  originId: Scalars["Int"];
  panoplayJob: PanoplayJob;
  panoplayJobUuid: Scalars["UUID"];
  s3Path: Scalars["String"];
  uuid: Scalars["UUID"];
};

export type PanoplayJobLink = BaseAggregateRoot & {
  __typename?: "PanoplayJobLink";
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  originId: Scalars["Int"];
  panoplayJob: PanoplayJob;
  panoplayJobUuid: Scalars["UUID"];
  url: Scalars["String"];
  uuid: Scalars["UUID"];
};

export type PanoplayOrder = BaseAggregateRoot & {
  __typename?: "PanoplayOrder";
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deliveryType: Scalars["String"];
  difficulty?: Maybe<Scalars["String"]>;
  expectedQuality?: Maybe<Scalars["String"]>;
  jobResultId: Scalars["Int"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  originCreatedDate: Scalars["OffsetDateTime"];
  originModifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  panoplayJob: PanoplayJob;
  panoplayJobUuid: Scalars["UUID"];
  panoplayOrderOptions: Array<PanoplayOrderOption>;
  price?: Maybe<Scalars["Float"]>;
  productOrderId: Scalars["Int"];
  productSource?: Maybe<Scalars["String"]>;
  productType: Scalars["String"];
  quantity: Scalars["String"];
  relayPanoplayJobResult: RelayPanoplayJobResult;
  results: Array<Result>;
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  state: OrderState;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
  workSizeType: Scalars["String"];
};

export type PanoplayOrderOption = BaseAggregateRoot & {
  __typename?: "PanoplayOrderOption";
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  originId: Scalars["Int"];
  panoplayOrder: PanoplayOrder;
  panoplayOrderUuid: Scalars["UUID"];
  price?: Maybe<Scalars["Float"]>;
  type: Scalars["String"];
  uuid: Scalars["UUID"];
};

export type PanoplayProductOrder = {
  __typename?: "PanoplayProductOrder";
  deliveryType: DeliveryType;
  difficulty?: Maybe<Scalars["String"]>;
  expectedQuality?: Maybe<Scalars["String"]>;
  originCreatedDate: Scalars["OffsetDateTime"];
  originId: Scalars["Int"];
  originModifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  panoplayProjectUuid: Scalars["UUID"];
  price: Scalars["Float"];
  productSource?: Maybe<ProductSource>;
  productType: ProductType;
  quantity: Scalars["String"];
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
};

export type PanoplayProject = BaseAggregateRoot & {
  __typename?: "PanoplayProject";
  backlotProject: RelayBacklotProject;
  bid: Scalars["String"];
  companyId?: Maybe<Scalars["Int"]>;
  companyManagerId?: Maybe<Scalars["Int"]>;
  companyManagerName?: Maybe<Scalars["String"]>;
  companyName?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  manager: Auth;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name: Scalars["String"];
  originCreatedDate: Scalars["OffsetDateTime"];
  originId: Scalars["Int"];
  originModifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  panoplayJobs: Array<PanoplayJob>;
  panoplayManagerId?: Maybe<Scalars["Int"]>;
  panoplayManagerName?: Maybe<Scalars["String"]>;
  panoplayProjectFiles: Array<PanoplayProjectFile>;
  panoplayProjectLinks: Array<PanoplayProjectLink>;
  uuid: Scalars["UUID"];
};

export type PanoplayProjectFile = BaseAggregateRoot & {
  __typename?: "PanoplayProjectFile";
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  index: Scalars["Int"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name: Scalars["String"];
  originId: Scalars["Int"];
  panoplayProject: PanoplayProject;
  panoplayProjectUuid: Scalars["UUID"];
  s3Path: Scalars["String"];
  uuid: Scalars["UUID"];
};

export type PanoplayProjectLink = BaseAggregateRoot & {
  __typename?: "PanoplayProjectLink";
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  originId: Scalars["Int"];
  panoplayProject: PanoplayProject;
  panoplayProjectUuid: Scalars["UUID"];
  url: Scalars["String"];
  uuid: Scalars["UUID"];
};

export type Pay = {
  __typename?: "Pay";
  bid: Scalars["String"];
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  price: Price;
  priceUuid: Scalars["UUID"];
  uuid: Scalars["UUID"];
  values: Array<PayValue>;
  version?: Maybe<Scalars["Int"]>;
};

export type PayInfo = {
  __typename?: "PayInfo";
  currencyCode: Scalars["String"];
  pay: Pay;
  payUuid: Scalars["UUID"];
  payValue: Scalars["Float"];
};

export type PayValue = {
  __typename?: "PayValue";
  currencyCode: Scalars["String"];
  value: Scalars["Float"];
};

export type PaypalDeposit = BaseAggregateRoot & {
  __typename?: "PaypalDeposit";
  countryCode?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  email?: Maybe<Scalars["String"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name?: Maybe<Scalars["String"]>;
};

export type PaypalResponse = {
  __typename?: "PaypalResponse";
  countryCode: Scalars["String"];
  email: Scalars["String"];
  name: Scalars["String"];
};

export type Phone = {
  __typename?: "Phone";
  callingCode: CodeAssociation;
  number: Scalars["String"];
};

export type PhoneInput = {
  callingCode: CodeAssociationInput;
  number: Scalars["String"];
};

export type PocTaskCount = {
  __typename?: "PocTaskCount";
  expertEvaluationRequestedNotPassedCount: Scalars["Int"];
  expertEvaluationRequestedPassedCount: Scalars["Int"];
  expertEvaluationRequestedProcessingCount: Scalars["Int"];
  expertEvaluationRequestedTotalCount: Scalars["Int"];
  expertEvaluationRequestedWaitingCount: Scalars["Int"];
  normalEvaluationRequestedCompletedCount: Scalars["Int"];
  normalEvaluationRequestedProcessingCount: Scalars["Int"];
  normalEvaluationRequestedTotalCount: Scalars["Int"];
  normalEvaluationRequestedWaitingCount: Scalars["Int"];
};

export type Position = BaseAggregateRoot & {
  __typename?: "Position";
  applicationEvaluableByTestEvaluation: Scalars["Boolean"];
  bid: Scalars["String"];
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  documentProcess: PositionDocumentProcess;
  modifiedBy: Auth;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  open: Scalars["Boolean"];
  recommendCodePromotionType: RecommendCodePromotionType;
  skillTypeCode: Scalars["String"];
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  testProcesses: Array<PositionTestProcess>;
  urgent: Scalars["Boolean"];
  uuid: Scalars["UUID"];
};

export type PositionDocumentProcess = {
  __typename?: "PositionDocumentProcess";
  careerMonthStandard: Scalars["Int"];
  evaluator: Auth;
  skip: Scalars["Boolean"];
  step: Scalars["Int"];
};

export type PositionPageResponse = {
  __typename?: "PositionPageResponse";
  content: Array<Position>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type PositionTestProcess = BaseAggregateRoot & {
  __typename?: "PositionTestProcess";
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  currencyCode?: Maybe<Scalars["String"]>;
  evaluator?: Maybe<Auth>;
  examEvaluationLimitMinute?: Maybe<Scalars["Int"]>;
  examEvaluationPayInfo?: Maybe<ExamEvaluationPayInfo>;
  examEvaluationTasks: Array<Task>;
  examTask?: Maybe<Task>;
  limitMinute: Scalars["Int"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  operation?: Maybe<Operation>;
  operationUuid?: Maybe<Scalars["UUID"]>;
  payUuid?: Maybe<Scalars["UUID"]>;
  payValue?: Maybe<Scalars["Float"]>;
  positionUuid: Scalars["UUID"];
  step: Scalars["Int"];
  taskType?: Maybe<TaskType>;
  testGuideUuids: Array<Scalars["UUID"]>;
  testGuides: Array<TestGuide>;
  testSet?: Maybe<TestSet>;
  testSetUuid?: Maybe<Scalars["UUID"]>;
  uuid: Scalars["UUID"];
};

export type PositionTestProcessExamEvaluationTasksArgs = {
  assignees?: InputMaybe<Array<Scalars["UUID"]>>;
};

export enum PreProcessingState {
  Cancelled = "CANCELLED",
  Done = "DONE",
  Error = "ERROR",
  Init = "INIT",
  Processing = "PROCESSING",
}

export type PrepaymentWaitingCompleteRequestInput = {
  paymentKey: Scalars["String"];
  prepaymentWaiting: AggregateAssociationInput;
  provider: Scalars["String"];
};

export type PrepaymentWaitingCompleteResponse = CommonResponse & {
  __typename?: "PrepaymentWaitingCompleteResponse";
  prepaymentWaiting: PrepaymentWaitingGqlModel;
  succeed: Scalars["Boolean"];
};

export type PrepaymentWaitingGqlModel = {
  __typename?: "PrepaymentWaitingGqlModel";
  bid: Scalars["String"];
  completed: Scalars["Boolean"];
  createdBy: Auth;
  createdDate: Scalars["OffsetDateTime"];
  eventVersion: Scalars["Long"];
  expiredDate: Scalars["OffsetDateTime"];
  id: Scalars["UUID"];
  paymentAmount?: Maybe<Scalars["BigDecimal"]>;
  prefix: Scalars["String"];
  quotation: QuotationGqlModel;
  version: Scalars["Long"];
};

export type PrepaymentWaitingGqlModelBidArgs = {
  format?: InputMaybe<GqlBusinessIdFormat>;
};

export type Preprocessing = BaseAggregateRoot & {
  __typename?: "Preprocessing";
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  editorResource: EditorResource;
  editorResourceTypeCode: Scalars["String"];
  editorResourceUuid?: Maybe<Scalars["UUID"]>;
  filePaths: Array<Scalars["String"]>;
  fileUuids: Array<Scalars["UUID"]>;
  jobUuid: Scalars["UUID"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  resourceTypeCode: Scalars["String"];
  resourceUuid: Scalars["UUID"];
  state: PreProcessingState;
  uuid: Scalars["UUID"];
  version: Scalars["Int"];
};

export type Price = BaseAggregateRoot & {
  __typename?: "Price";
  bid: Scalars["String"];
  closed: Scalars["Boolean"];
  closedBy?: Maybe<Auth>;
  closedDate?: Maybe<Scalars["OffsetDateTime"]>;
  contentLevel?: Maybe<Level>;
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  customerDemandLevel?: Maybe<Level>;
  memo?: Maybe<Scalars["String"]>;
  modifiedBy?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name: Scalars["String"];
  operationTypeCode: Scalars["String"];
  pays: Array<Pay>;
  purpose: PricePurpose;
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  standard: Scalars["Boolean"];
  targetLanguageCode?: Maybe<Scalars["String"]>;
  unit: PriceUnit;
  uuid: Scalars["UUID"];
  workerLevel?: Maybe<Level>;
};

export type PricePageResponse = {
  __typename?: "PricePageResponse";
  content: Array<Price>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export enum PricePurpose {
  ExamEvaluationTask = "EXAM_EVALUATION_TASK",
  NormalTask = "NORMAL_TASK",
}

export type PriceQueryCriteriaInput = {
  closed?: InputMaybe<Scalars["Boolean"]>;
  contentTypeCode?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  operationTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  purposes?: InputMaybe<Array<PricePurpose>>;
  sourceLanguageCode?: InputMaybe<Scalars["String"]>;
  standard?: InputMaybe<Scalars["Boolean"]>;
  targetLanguageCode?: InputMaybe<Scalars["String"]>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export enum PriceUnit {
  Episode = "EPISODE",
  Letter = "LETTER",
  Minute = "MINUTE",
  Word = "WORD",
}

export type ProcessStateCount = {
  __typename?: "ProcessStateCount";
  evaluatedCount: Scalars["Int"];
  submittedCount: Scalars["Int"];
  total: Scalars["Int"];
  waitingCount: Scalars["Int"];
};

export enum ProcessStatus {
  Completed = "COMPLETED",
  InProgress = "IN_PROGRESS",
  Waiting = "WAITING",
}

export type ProcessTypeCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "ProcessTypeCode";
    code: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description: Scalars["String"];
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
  };

export type ProductPriceGqlModel = {
  __typename?: "ProductPriceGqlModel";
  amount: Scalars["BigDecimal"];
  bid: Scalars["String"];
  createdBy: Auth;
  createdDate: Scalars["OffsetDateTime"];
  currency: CodeAssociation;
  deleted: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  eventVersion: Scalars["Long"];
  id: Scalars["UUID"];
  name?: Maybe<Scalars["String"]>;
  prefix: Scalars["String"];
  unit?: Maybe<CodeAssociation>;
  version: Scalars["Long"];
};

export type ProductPriceGqlModelBidArgs = {
  format?: InputMaybe<GqlBusinessIdFormat>;
};

export type ProductPriceSpecInput = {
  amount: Scalars["BigDecimal"];
  currency: CodeAssociationInput;
  workUnit: CodeAssociationInput;
};

export enum ProductSource {
  Basic = "BASIC",
  ScreenOnly = "SCREEN_ONLY",
  VoiceAndScreen = "VOICE_AND_SCREEN",
  VoiceOnly = "VOICE_ONLY",
}

export enum ProductType {
  BurnIn = "BURN_IN",
  Caption = "CAPTION",
  SpottingOnly = "SPOTTING_ONLY",
  TimeCode = "TIME_CODE",
  Translation = "TRANSLATION",
  TypesetterOnly = "TYPESETTER_ONLY",
}

export type ProductTypeCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "ProductTypeCode";
    code: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
  };

export type Profile = BaseAggregateRoot & {
  __typename?: "Profile";
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  dateOfBirth?: Maybe<Scalars["LocalDate"]>;
  foreigner?: Maybe<Scalars["Boolean"]>;
  gender?: Maybe<Gender>;
  introduction?: Maybe<Scalars["String"]>;
  isNotAdult?: Maybe<Scalars["Boolean"]>;
  localeLanguage?: Maybe<Scalars["String"]>;
  maskedName?: Maybe<Scalars["String"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name?: Maybe<Scalars["String"]>;
  slackUserId?: Maybe<Scalars["String"]>;
  slackUserIdSet: Scalars["Boolean"];
  userType?: Maybe<UserType>;
  uuid: Scalars["UUID"];
  verifiable: Scalars["Boolean"];
  verified: Scalars["Boolean"];
};

export type ProfilePageResponse = {
  __typename?: "ProfilePageResponse";
  content: Array<Profile>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type Project = BaseAggregateRoot & {
  __typename?: "Project";
  adult?: Maybe<Scalars["Boolean"]>;
  bid: Scalars["String"];
  blglGenre?: Maybe<Scalars["Boolean"]>;
  client?: Maybe<Client>;
  clientUuid?: Maybe<Scalars["UUID"]>;
  contentTypeCode?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  endDate?: Maybe<Scalars["OffsetDateTime"]>;
  genreCode?: Maybe<Scalars["String"]>;
  hold?: Maybe<Scalars["Boolean"]>;
  jobs: Array<Job>;
  manager?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name?: Maybe<Scalars["String"]>;
  operationPreset: Array<OperationPreset>;
  progress?: Maybe<ProjectProgress>;
  projectOrder: Array<ProjectOrder>;
  projectTransfer?: Maybe<ProjectTransfer>;
  references: Array<Reference>;
  startDate?: Maybe<Scalars["OffsetDateTime"]>;
  taskPurpose: TaskPurpose;
  uuid: Scalars["UUID"];
};

export type ProjectJobsArgs = {
  sort?: InputMaybe<Array<SortInput>>;
};

export type ProjectCreateByQuotationProductRequestInput = {
  presetJobCount: Scalars["Int"];
  quotationProduct: AggregateAssociationInput;
  taskPresetType: TaskPresetType;
};

export type ProjectCreateByQuotationProductResponse = CommonResponse & {
  __typename?: "ProjectCreateByQuotationProductResponse";
  project: Project;
  succeed: Scalars["Boolean"];
};

export type ProjectOrder = BaseAggregateRoot & {
  __typename?: "ProjectOrder";
  bid: Scalars["String"];
  characterLimit?: Maybe<Scalars["Int"]>;
  characterLimitEnabled: Scalars["Boolean"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  currencyCode?: Maybe<CurrencyCode>;
  hasJobProcess: Scalars["Boolean"];
  jobProcesses: Array<JobProcess>;
  manager?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  operationPreset: Array<OperationPreset>;
  panoplayProductOrder?: Maybe<PanoplayProductOrder>;
  priceValue?: Maybe<Scalars["Float"]>;
  project: Project;
  projectUuid: Scalars["UUID"];
  ror?: Maybe<Scalars["Float"]>;
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
};

export type ProjectPageResponse = {
  __typename?: "ProjectPageResponse";
  content: Array<Project>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export enum ProjectProgress {
  Completed = "COMPLETED",
  Paused = "PAUSED",
  Processing = "PROCESSING",
}

export type ProjectQueryRequestInput = {
  adult?: InputMaybe<Scalars["Boolean"]>;
  blglGenre?: InputMaybe<Scalars["Boolean"]>;
  clientUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  contentTypeCode?: InputMaybe<Scalars["String"]>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  endDate?: InputMaybe<Scalars["OffsetDateTime"]>;
  genreCode?: InputMaybe<Scalars["String"]>;
  hold?: InputMaybe<Scalars["Boolean"]>;
  managers?: InputMaybe<Array<Scalars["UUID"]>>;
  name?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["OffsetDateTime"]>;
  taskPurposes?: InputMaybe<Array<TaskPurpose>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export enum ProjectStatus {
  Done = "DONE",
  Drop = "DROP",
  Processing = "PROCESSING",
  Registered = "REGISTERED",
  Requested = "REQUESTED",
  Requesting = "REQUESTING",
}

export type ProjectTransfer = BaseAggregateRoot & {
  __typename?: "ProjectTransfer";
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  origin: Origin;
  originPanoplayProject: PanoplayProject;
  originProjectUuid: Scalars["UUID"];
  project: Project;
  projectUuid: Scalars["UUID"];
  transferredBy: Auth;
  transferredDate: Scalars["OffsetDateTime"];
  uuid: Scalars["UUID"];
};

export type ProjectTypeCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "ProjectTypeCode";
    code: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
  };

export enum Provider {
  Apple = "APPLE",
  Google = "GOOGLE",
  Kakao = "KAKAO",
  Line = "LINE",
}

export type PsdPreview = {
  __typename?: "PsdPreview";
  height?: Maybe<Scalars["Int"]>;
  originalDownloadUrl: Scalars["String"];
  originalS3Path: Scalars["String"];
  preview500DownloadUrl?: Maybe<Scalars["String"]>;
  preview1000DownloadUrl?: Maybe<Scalars["String"]>;
  previewDownloadUrl?: Maybe<Scalars["String"]>;
  previewS3Path?: Maybe<Scalars["String"]>;
  previewS3Path500?: Maybe<Scalars["String"]>;
  previewS3Path1000?: Maybe<Scalars["String"]>;
  retryCount: Scalars["Int"];
  status: PsdPreviewStatus;
  uuid: Scalars["UUID"];
  width?: Maybe<Scalars["Int"]>;
};

export enum PsdPreviewStatus {
  Complete = "COMPLETE",
  Error = "ERROR",
  Processing = "PROCESSING",
}

export type QcLog = BaseAggregateRoot & {
  __typename?: "QcLog";
  createdBy: Scalars["UUID"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  editorUnitKey: Scalars["UUID"];
  editorUnitUuid: Scalars["UUID"];
  ignored: Scalars["Boolean"];
  ignoredBy?: Maybe<Scalars["UUID"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  originEditorUnitUuids: Array<Scalars["UUID"]>;
  taskUuid: Scalars["UUID"];
  type: Scalars["String"];
  uuid: Scalars["UUID"];
  value: Scalars["JSON"];
};

export type Query = {
  __typename?: "Query";
  TagGroupCode: Array<TagGroupCode>;
  applicablePosition: ApplicablePositionState;
  applicantFull: Array<Auth>;
  application: ApplicationPageResponse;
  applicationBySkillUuid?: Maybe<Application>;
  applicationByUuid: Application;
  applicationByUuids: Array<Application>;
  applicationEvaluation: ApplicationEvaluationPageResponse;
  applicationEvaluationByUuid: ApplicationEvaluation;
  applicationEvaluationByUuids: Array<ApplicationEvaluation>;
  /** @deprecated Don't use full query. Use application page query. */
  applicationFull: Array<Application>;
  applicationStateCount: ApplicationStateCount;
  auth: AuthPageResponse;
  authBySearchParam: Array<Auth>;
  authByUuid: Auth;
  authByUuids: Array<Auth>;
  authRestricted: AuthRestrictionHistoryPageResponse;
  authRestrictionHistoryByAuthUuid: AuthRestrictionHistoryPageResponse;
  bankCode: Array<BankCode>;
  bankCodeByCode: BankCode;
  callingCode: Array<CallingCode>;
  callingCodeByCode: CallingCode;
  capability: CapabilityPageResponse;
  capabilityByAuthUuid: Capability;
  capabilityByUuid: Capability;
  capabilityByUuids: Array<Capability>;
  careerAttachment: CareerAttachmentPageResponse;
  careerAttachmentByUuid: CareerAttachment;
  careerAttachmentByUuids: Array<CareerAttachment>;
  carrierCode: Array<CarrierCode>;
  carrierCodeByCode: CarrierCode;
  /** @deprecated Move to clientPage */
  client: ClientPageResponse;
  clientByUuid: Client;
  /** @deprecated Move to client() */
  clientByUuids: Array<Client>;
  clientPage: ClientPageResponse;
  comment: Array<Comment>;
  commentAttachmentByUuid: CommentAttachment;
  commentByUuid: Comment;
  commentMentionByUuid: CommentMention;
  commentPage: CommentPageResponse;
  contact: ContactPageResponse;
  contactByAuthUuid: Contact;
  contactByUuid: Contact;
  contactByUuids: Array<Contact>;
  content: ContentGqlModel;
  contentCount: Scalars["Long"];
  contentTypeCode: Array<ContentTypeCode>;
  contentTypeCodeByCode: ContentTypeCode;
  contents: Array<ContentGqlModel>;
  contract: ContractPageResponse;
  contractByUuid: Contract;
  contractByUuids: Array<Contract>;
  contractFull: Array<Contract>;
  countryCode: Array<CountryCode>;
  countryCodeByCode: CountryCode;
  currencyCode: Array<CurrencyCode>;
  currencyCodeByCode: CurrencyCode;
  customer: CustomerGqlModel;
  customerCount: Scalars["Long"];
  customers: Array<CustomerGqlModel>;
  delivery: Array<Delivery>;
  deliveryByTaskUuid: Array<Delivery>;
  deliveryByUuid: Delivery;
  deliveryByUuids: Array<Delivery>;
  deposit: DepositPageResponse;
  depositByAuthUuid: Deposit;
  depositByUuid: Deposit;
  depositByUuids: Array<Deposit>;
  /** @deprecated Move to operationPage() */
  deprecatedOperation: OperationPageResponse;
  directories: Array<DirectoryGqlModel>;
  directory: DirectoryGqlModel;
  directoryCount: Scalars["Long"];
  documentEvaluation: DocumentEvaluationPageResponse;
  documentEvaluationByUuid: DocumentEvaluation;
  documentEvaluationByUuids: Array<DocumentEvaluation>;
  downloadUrl: Array<FileDownloadUrlGenerationResponse>;
  editorByOperationExceptOutput: Editor;
  editorByTask: Editor;
  editorCommentReadCode: Array<EditorCommentReadCode>;
  editorCommentReadCodeByCode: EditorCommentReadCode;
  editorCommentWriteCode: Array<EditorCommentWriteCode>;
  editorCommentWriteCodeByCode: EditorCommentWriteCode;
  editorResourceByUuid: EditorResource;
  editorResourceByUuids: Array<EditorResource>;
  editorResourceTypeCode: Array<EditorResourceTypeCode>;
  editorResourceTypeCodeByCode: EditorResourceTypeCode;
  editorUnitByUuid: EditorUnit;
  /** @deprecated Move to unit */
  editorUnitHistory: Array<EditorUnit>;
  errorTest: Scalars["String"];
  extensionCode: Array<ExtensionCode>;
  extensionGroupCode: Array<ExtensionGroupCode>;
  extensionGroupCodeByCode: ExtensionGroupCode;
  extenstionCodeByCode: ExtensionCode;
  feedbackTagQuery: Array<Tag>;
  file: Array<File>;
  fileByUuid: File;
  fileByUuids: Array<File>;
  fileCountV2: Scalars["Long"];
  fileTypeCode: Array<FileTypeCode>;
  fileTypeCodeByCode: FileTypeCode;
  fileV2: FileGqlModel;
  filesV2: Array<FileGqlModel>;
  fixedSettlement: Array<FixedSettlement>;
  fixedSettlementPage: FixedSettlementPageResponse;
  genreCode: Array<GenreCode>;
  genreCodeByCode: GenreCode;
  /** @deprecated Move to jobPage() */
  job: JobPageResponse;
  /** @deprecated Move to job() */
  jobByLiveTranslation: JobPageResponse;
  jobByUuid: Job;
  jobByUuids: Array<Job>;
  jobExcludedByProjectOrder: Array<Job>;
  jobPage: JobPageResponse;
  jobProcess: Array<JobProcess>;
  jobProcessByUuid: JobProcess;
  jobProcessByUuids: Array<JobProcess>;
  jobProcessHistoryPage: JobProcessHistoryPageResponse;
  /** @deprecated Temporary */
  jobProcessRole: Array<Scalars["String"]>;
  jobTypeCode: Array<JobTypeCode>;
  jobTypeCodeByCode: JobTypeCode;
  languageCode: Array<LanguageCode>;
  languageCodeByCode: LanguageCode;
  link: Array<Link>;
  linkByUuid: Link;
  linkByUuids: Array<Link>;
  notWorkableDay: NotWorkableDayPageResponse;
  notWorkableDayFull: Array<NotWorkableDay>;
  notWorkableDayOfWeek: NotWorkableDayOfWeekPageResponse;
  notWorkableDayOfWeekFull: Array<NotWorkableDayOfWeek>;
  openPositions: Scalars["JSON"];
  operation: Array<Operation>;
  operationByUuid: Operation;
  operationByUuids: Array<Operation>;
  operationPage: OperationPageResponse;
  operationPageGroupByPosition: OperationGroupedByPositionPageResponse;
  operationStepCode: Array<OperationStepCode>;
  operationStepCodeByCode: OperationStepCode;
  operationTypeCode: Array<OperationTypeCode>;
  operationTypeCodeByCode: OperationTypeCode;
  panoplayJob: Array<PanoplayJob>;
  panoplayJobByUuid: PanoplayJob;
  panoplayJobByUuids: Array<PanoplayJob>;
  panoplayJobFileByPanoplayJobUuid: Array<PanoplayJobFile>;
  panoplayJobLinkByPanoplayJobUuid: Array<PanoplayJobLink>;
  panoplayOrder: Array<PanoplayOrder>;
  panoplayOrderByUuid: PanoplayOrder;
  panoplayOrderByUuids: Array<PanoplayOrder>;
  panoplayOrderOptionByPanoplayOrderUuid: Array<PanoplayOrderOption>;
  panoplayProject: Array<PanoplayProject>;
  panoplayProjectByUuid: PanoplayProject;
  panoplayProjectByUuids: Array<PanoplayProject>;
  panoplayProjectFileByPanoplayProjectUuid: Array<PanoplayProjectFile>;
  panoplayProjectLinkByPanoplayProjectUuid: Array<PanoplayProjectLink>;
  pay: Array<Pay>;
  payByPriceUuid: Array<Pay>;
  payByUuid: Pay;
  payByUuids: Array<Pay>;
  pocTaskCount: PocTaskCount;
  pocTaskEvaluationRecommendCount: TaskEvaluationRecommendCount;
  pocTaskEvaluationStateCount: TaskEvaluationTaskStateCount;
  position: PositionPageResponse;
  positionByUuid: Position;
  positionByUuids: Array<Position>;
  positionFull: Array<Position>;
  positionTestProcessByUuid: PositionTestProcess;
  prepaymentWaiting: PrepaymentWaitingGqlModel;
  prepaymentWaitingCount: Scalars["Long"];
  prepaymentWaitings: Array<PrepaymentWaitingGqlModel>;
  preprocessing: Array<Preprocessing>;
  preprocessingByUuid: Preprocessing;
  preprocessingByUuids: Array<Preprocessing>;
  price: Array<Price>;
  priceByOperationTypeCode: Array<Price>;
  priceByUuid: Price;
  priceByUuids: Array<Price>;
  pricePage: PricePageResponse;
  processTypeCode: Array<ProcessTypeCode>;
  processTypeCodeByCode: ProcessTypeCode;
  productPrice: ProductPriceGqlModel;
  productPriceCount: Scalars["Long"];
  productPrices: Array<ProductPriceGqlModel>;
  profile: ProfilePageResponse;
  profileByAuthUuid: Profile;
  profileByUuid: Profile;
  profileByUuids: Array<Profile>;
  /** @deprecated Move to projectPage() */
  project: ProjectPageResponse;
  projectByUuid: Project;
  /** @deprecated Merge to project */
  projectByUuids: Array<Project>;
  projectOrderByUuid: ProjectOrder;
  projectPage: ProjectPageResponse;
  projectTypeCode: Array<ProjectTypeCode>;
  projectTypeCodeByCode: ProjectTypeCode;
  question: Array<Question>;
  questionApplication: Array<QuestionApplication>;
  questionApplicationByUuid: QuestionApplication;
  questionApplicationPage: QuestionApplicationPageResponse;
  questionByUuid: Question;
  questionGroup: Array<QuestionGroup>;
  questionGroupByUuid: QuestionGroup;
  questionGroupPage: QuestionGroupPageResponse;
  questionOption: Array<QuestionOption>;
  questionOptionByUuid: QuestionOption;
  questionOptionPage: QuestionOptionPageResponse;
  questionOptionSnapshot: Array<QuestionOptionSnapshot>;
  questionOptionSnapshotByUuid: QuestionOptionSnapshot;
  questionPage: QuestionPageResponse;
  questionSnapshot: Array<QuestionSnapshot>;
  questionSnapshotByUuid: QuestionSnapshot;
  questionSnapshotPage: QuestionSnapshotPageResponse;
  quizGuideByUuid: QuizGuide;
  quotation: QuotationGqlModel;
  quotationCount: Scalars["Long"];
  quotationProduct: QuotationProductGqlModel;
  quotationProductCount: Scalars["Long"];
  quotationProducts: Array<QuotationProductGqlModel>;
  quotationRequest: QuotationRequestGqlModel;
  quotationRequestCount: Scalars["Long"];
  quotationRequests: Array<QuotationRequestGqlModel>;
  quotations: Array<QuotationGqlModel>;
  recentAppliedPOCGroupedByOperations: Array<Operation>;
  recommendCode: Array<RecommendCode>;
  recommendCodePage: RecommendCodePageResponse;
  recommendCodeUseHistory: Array<RecommendCodeUseHistory>;
  recommendCodeUseHistoryPage: RecommendCodeUseHistoryPageResponse;
  recruitmentEvaluationItem: Array<RecruitmentEvaluationItem>;
  recruitmentEvaluationItemByUuid: RecruitmentEvaluationItem;
  recruitmentEvaluationItemPage: RecruitmentEvaluationItemPageResponse;
  recruitmentEvaluationItemSnapshot: Array<RecruitmentEvaluationItemSnapshot>;
  recruitmentEvaluationItemSnapshotByUuid: RecruitmentEvaluationItemSnapshot;
  recruitmentEvaluationItemSnapshotPage: RecruitmentEvaluationItemSnapshotPageResponse;
  recruitmentEvaluationOption: Array<RecruitmentEvaluationOption>;
  recruitmentEvaluationOptionByUuid: RecruitmentEvaluationOption;
  recruitmentEvaluationOptionPage: RecruitmentEvaluationOptionPageResponse;
  recruitmentEvaluationOptionSnapshot: Array<RecruitmentEvaluationOptionSnapshot>;
  recruitmentEvaluationOptionSnapshotByUuid: RecruitmentEvaluationOptionSnapshot;
  recruitmentEvaluationOptionSnapshotPage: RecruitmentEvaluationOptionSnapshotPageResponse;
  recruitmentEvaluationResult: Array<RecruitmentEvaluationResult>;
  recruitmentEvaluationResultByUuid: RecruitmentEvaluationResult;
  recruitmentEvaluationResultGroupedPage?: Maybe<RecruitmentEvaluationResultGroupedPageResponse>;
  recruitmentEvaluationResultPage: RecruitmentEvaluationResultPageResponse;
  recruitmentEvaluationStandard: Array<RecruitmentEvaluationStandard>;
  recruitmentEvaluationStandardByUuid: RecruitmentEvaluationStandard;
  recruitmentEvaluationStandardPage: RecruitmentEvaluationStandardPageResponse;
  reference: Array<Reference>;
  referenceByUuid: Reference;
  referenceByUuids: Array<Reference>;
  referenceHistory: Array<ReferenceHistory>;
  referenceHistoryPage: ReferenceHistoryPageResponse;
  referenceTypeCode: Array<ReferenceTypeCode>;
  referenceTypeCodeByCode: ReferenceTypeCode;
  relayBacklotProject: RelayBacklotProject;
  relayBacklotProjects: RelayBacklotProjectPageResponse;
  relayPanoplayJobResult: RelayPanoplayJobResult;
  resource: Array<Resource>;
  resourceByUuid: Resource;
  resourceByUuids: Array<Resource>;
  resourceTypeCode: Array<ResourceTypeCode>;
  resourceTypeCodeByCode: ResourceTypeCode;
  result: Array<Result>;
  resultByUuid: Result;
  resultByUuids: Array<Result>;
  skill: SkillPageResponse;
  skillByUuid: Skill;
  skillByUuids: Array<Skill>;
  skillHistoryPage: SkillHistoryPageResponse;
  skillTypeCode: Array<SkillTypeCode>;
  skillTypeCodeByCode: SkillTypeCode;
  tagByUuid: Tag;
  tagGroupCodeByCode: TagGroupCode;
  tagPage: TagPageResponse;
  tagQuery: Array<Tag>;
  targetSettlement: Array<TargetSettlement>;
  targetSettlementGrouped: Array<TargetSettlementGrouped>;
  targetSettlementGroupedPage?: Maybe<TargetSettlementGroupedPageResponse>;
  targetSettlementPage: TargetSettlementPageResponse;
  /** @deprecated Deprecated */
  task: TaskPageResponse;
  taskByGroupUuid: Array<Task>;
  taskByUuid: Task;
  taskByUuids: Array<Task>;
  taskEvaluation: Array<TaskEvaluation>;
  taskEvaluationByUuid: TaskEvaluation;
  taskEvaluationByUuids: Array<TaskEvaluation>;
  taskEvaluationPage: TaskEvaluationPageResponse;
  /** @deprecated Deprecated */
  taskFull: Array<Task>;
  taskGroup: TaskGroupPageResponse;
  taskGroupByUuid: TaskGroup;
  taskGroupByUuids: Array<TaskGroup>;
  taskGroupByWorkerUuid: Array<TaskGroup>;
  taskPage: TaskPageResponse;
  taskReport: Array<TaskReport>;
  taskReportByUuid: TaskReport;
  taskReportPage: TaskReportPageResponse;
  taskStateCount: TaskStateCount;
  termAgreement: TermAgreementPageResponse;
  termAgreementByAuthUuid: TermAgreement;
  termAgreementByUuid: TermAgreement;
  termAgreementByUuids: Array<TermAgreement>;
  test: TestPageResponse;
  testByUuid: Test;
  testByUuids: Array<Test>;
  testEvaluation: TestEvaluationPageResponse;
  testEvaluationByUuid: TestEvaluation;
  testEvaluationByUuids: Array<TestEvaluation>;
  testGuide: TestGuidePageResponse;
  testGuideByUuid: TestGuide;
  testGuideByUuids: Array<TestGuide>;
  testGuidesByPositionAndSubmissionType: Array<TestGuide>;
  testProcess: TestProcessPageResponse;
  testProcessByUuid: ApplicationTestProcess;
  testProcessStateCount: ProcessStateCount;
  testSet: TestSetPageResponse;
  testSetByUuid: TestSet;
  testSetByUuids: Array<TestSet>;
  testSetFileByUuid: TestSetFile;
  testSetFileByUuids: Array<TestSetFile>;
  testSetFull: Array<TestSet>;
  testSetLinkByUuid: TestSetLink;
  testSetLinkByUuids: Array<TestSetLink>;
  timezoneCode: Array<TimezoneCode>;
  timezoneCodeByCode: TimezoneCode;
  toRequest: OperationQueryRequest;
  transfer: Array<Transfer>;
  transferByUuid: Transfer;
  transferByUuids: Array<Transfer>;
  unitPointerByUuid: UnitPointer;
  unitPointerGroupByUuid: UnitPointerGroup;
  userDaySchedule: DaySchedule;
  userRangeSchedule: RangeSchedule;
  work: Array<Work>;
  workByUuid: Work;
  workByUuids: Array<Work>;
  workPrice: WorkPriceGqlModel;
  workPriceCount: Scalars["Long"];
  workPrices: Array<WorkPriceGqlModel>;
  workUnitCode: Array<WorkUnitCode>;
  workUnitCodeByCode: WorkUnitCode;
  workableDay: WorkableDayPageResponse;
  workableDayFull: Array<WorkableDay>;
  /** @deprecated Move to workerPage() */
  workableWorker: WorkerPageResponse;
  worker: Array<Worker>;
  workerByAuthUuid: Worker;
  workerByUuid: Worker;
  /** @deprecated Move to worker */
  workerByUuids: Array<Worker>;
  workerGroup: Array<WorkerGroup>;
  workerGroupByUuid: WorkerGroup;
  /** @deprecated Move to workerGroup() */
  workerGroupByUuids: Array<WorkerGroup>;
  workerGroupPage: WorkerGroupPageResponse;
  workerMatchingCandidate: WorkerMatchingCandidateGqlModel;
  workerMatchingCandidateCount: Scalars["Long"];
  workerMatchingCandidates: Array<WorkerMatchingCandidateGqlModel>;
  workerPage: WorkerPageResponse;
  workerQualification: Array<WorkerQualification>;
  workerQualificationBySkill: Array<WorkerQualification>;
  workerQualificationByUuid: WorkerQualification;
  workerQualificationByWorkerUuid: WorkerQualification;
  workerWorkable: WorkerPageResponse;
};

export type QueryApplicablePositionArgs = {
  authUuid: Scalars["UUID"];
  positionUuid: Scalars["UUID"];
};

export type QueryApplicantFullArgs = {
  positionUuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryApplicationArgs = {
  applicationMethods?: InputMaybe<Array<ApplicationMethod>>;
  authUuid?: InputMaybe<Scalars["UUID"]>;
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  canceled?: InputMaybe<Scalars["Boolean"]>;
  contentTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  disablePass?: InputMaybe<Scalars["Boolean"]>;
  evaluatorUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  page?: InputMaybe<Scalars["Int"]>;
  pass?: InputMaybe<Scalars["Boolean"]>;
  positionUuid?: InputMaybe<Scalars["UUID"]>;
  positionUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  recommendCodePromotionType?: InputMaybe<RecommendCodePromotionType>;
  recommendCodePromotionTypes?: InputMaybe<Array<RecommendCodePromotionType>>;
  size?: InputMaybe<Scalars["Int"]>;
  skillTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  sourceLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  state?: InputMaybe<CurrentProcessState>;
  states?: InputMaybe<Array<CurrentProcessState>>;
  targetLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryApplicationBySkillUuidArgs = {
  skillUuid: Scalars["UUID"];
};

export type QueryApplicationByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QueryApplicationByUuidsArgs = {
  uuids: Array<Scalars["UUID"]>;
};

export type QueryApplicationEvaluationArgs = {
  applicationUuid?: InputMaybe<Scalars["UUID"]>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryApplicationEvaluationByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QueryApplicationEvaluationByUuidsArgs = {
  uuids: Array<Scalars["UUID"]>;
};

export type QueryApplicationFullArgs = {
  authUuid?: InputMaybe<Scalars["UUID"]>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  recommendCodePromotionType?: InputMaybe<RecommendCodePromotionType>;
  recommendCodePromotionTypes?: InputMaybe<Array<RecommendCodePromotionType>>;
};

export type QueryApplicationStateCountArgs = {
  applicationMethods?: InputMaybe<Array<ApplicationMethod>>;
  authUuid?: InputMaybe<Scalars["UUID"]>;
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  canceled?: InputMaybe<Scalars["Boolean"]>;
  contentTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  evaluatorUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  positionUuid?: InputMaybe<Scalars["UUID"]>;
  positionUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  skillTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  sourceLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  targetLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryAuthArgs = {
  banned?: InputMaybe<Scalars["Boolean"]>;
  blocked?: InputMaybe<Scalars["Boolean"]>;
  complementRoles?: InputMaybe<Array<Role>>;
  emailPostfixVoithru?: InputMaybe<Scalars["Boolean"]>;
  leaved?: InputMaybe<Scalars["Boolean"]>;
  page?: InputMaybe<Scalars["Int"]>;
  role?: InputMaybe<Role>;
  roles?: InputMaybe<Array<Role>>;
  searchParam?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Int"]>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
  watched?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryAuthBySearchParamArgs = {
  searchParam: Scalars["String"];
};

export type QueryAuthByUuidArgs = {
  authUuid: Scalars["UUID"];
};

export type QueryAuthByUuidsArgs = {
  authUuids: Array<Scalars["UUID"]>;
};

export type QueryAuthRestrictedArgs = {
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  type: AuthRestrictionType;
};

export type QueryAuthRestrictionHistoryByAuthUuidArgs = {
  authUuid: Scalars["UUID"];
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<AuthRestrictionType>;
  value?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryBankCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryCallingCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryCapabilityArgs = {
  authUuid?: InputMaybe<Scalars["UUID"]>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryCapabilityByAuthUuidArgs = {
  authUuid: Scalars["UUID"];
};

export type QueryCapabilityByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QueryCapabilityByUuidsArgs = {
  uuids: Array<Scalars["UUID"]>;
};

export type QueryCareerAttachmentArgs = {
  authUuid?: InputMaybe<Scalars["UUID"]>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryCareerAttachmentByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QueryCareerAttachmentByUuidsArgs = {
  uuids: Array<Scalars["UUID"]>;
};

export type QueryCarrierCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryClientArgs = {
  criteria?: InputMaybe<ClientQueryRequestInput>;
  name?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryClientByUuidArgs = {
  clientUuid: Scalars["UUID"];
};

export type QueryClientByUuidsArgs = {
  clientUuids: Array<Scalars["UUID"]>;
};

export type QueryClientPageArgs = {
  criteria?: InputMaybe<ClientQueryRequestInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryCommentArgs = {
  criteria?: InputMaybe<CommentCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryCommentAttachmentByUuidArgs = {
  CommentAttachmentUuid: Scalars["UUID"];
};

export type QueryCommentByUuidArgs = {
  commentUuid: Scalars["UUID"];
};

export type QueryCommentMentionByUuidArgs = {
  commentMentionUuid: Scalars["UUID"];
};

export type QueryCommentPageArgs = {
  criteria?: InputMaybe<CommentCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryContactArgs = {
  authUuid?: InputMaybe<Scalars["UUID"]>;
  page?: InputMaybe<Scalars["Int"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryContactByAuthUuidArgs = {
  authUuid: Scalars["UUID"];
};

export type QueryContactByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QueryContactByUuidsArgs = {
  uuids: Array<Scalars["UUID"]>;
};

export type QueryContentArgs = {
  id: Scalars["UUID"];
};

export type QueryContentCountArgs = {
  criteria?: InputMaybe<ContentQuerySpecInput>;
};

export type QueryContentTypeCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryContentsArgs = {
  criteria?: InputMaybe<ContentQuerySpecInput>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryContractArgs = {
  authUuid?: InputMaybe<Scalars["UUID"]>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryContractByUuidArgs = {
  contractUuid: Scalars["UUID"];
};

export type QueryContractByUuidsArgs = {
  contractUuids: Array<Scalars["UUID"]>;
};

export type QueryContractFullArgs = {
  authUuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryCountryCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryCurrencyCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryCustomerArgs = {
  id: Scalars["UUID"];
};

export type QueryCustomerCountArgs = {
  criteria?: InputMaybe<CustomerQuerySpecInput>;
};

export type QueryCustomersArgs = {
  criteria?: InputMaybe<CustomerQuerySpecInput>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryDeliveryByTaskUuidArgs = {
  taskUuid: Scalars["UUID"];
};

export type QueryDeliveryByUuidArgs = {
  deliveryUuid: Scalars["UUID"];
};

export type QueryDeliveryByUuidsArgs = {
  deliveryUuids: Array<Scalars["UUID"]>;
};

export type QueryDepositArgs = {
  authUuid?: InputMaybe<Scalars["UUID"]>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryDepositByAuthUuidArgs = {
  authUuid: Scalars["UUID"];
};

export type QueryDepositByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QueryDepositByUuidsArgs = {
  uuids: Array<Scalars["UUID"]>;
};

export type QueryDeprecatedOperationArgs = {
  jobUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  operationTypeCode?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sourceLanguageCode?: InputMaybe<Scalars["String"]>;
  targetLanguageCode?: InputMaybe<Scalars["String"]>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QueryDirectoriesArgs = {
  criteria?: InputMaybe<DirectoryQuerySpecInput>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryDirectoryArgs = {
  id: Scalars["UUID"];
};

export type QueryDirectoryCountArgs = {
  criteria?: InputMaybe<DirectoryQuerySpecInput>;
};

export type QueryDocumentEvaluationArgs = {
  documentProcessUuid?: InputMaybe<Scalars["UUID"]>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryDocumentEvaluationByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QueryDocumentEvaluationByUuidsArgs = {
  uuids: Array<Scalars["UUID"]>;
};

export type QueryDownloadUrlArgs = {
  s3Paths: Array<FileDownloadUrlGenerationRequestInput>;
};

export type QueryEditorByOperationExceptOutputArgs = {
  operationUuid: Scalars["UUID"];
};

export type QueryEditorByTaskArgs = {
  taskUuid: Scalars["UUID"];
};

export type QueryEditorCommentReadCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryEditorCommentWriteCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryEditorResourceByUuidArgs = {
  includeDeleted?: InputMaybe<Scalars["Boolean"]>;
  lastUpdate?: InputMaybe<Scalars["OffsetDateTime"]>;
  uuid: Scalars["UUID"];
};

export type QueryEditorResourceByUuidsArgs = {
  uuids: Array<Scalars["UUID"]>;
};

export type QueryEditorResourceTypeCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryEditorUnitByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QueryEditorUnitHistoryArgs = {
  editorUnitUuid: Scalars["UUID"];
  languageCode: Scalars["String"];
  taskUuid: Scalars["UUID"];
};

export type QueryExtensionGroupCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryExtenstionCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryFeedbackTagQueryArgs = {
  criteria?: InputMaybe<FeedbackTagCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryFileByUuidArgs = {
  fileUuid: Scalars["UUID"];
};

export type QueryFileByUuidsArgs = {
  fileUuids: Array<Scalars["UUID"]>;
};

export type QueryFileCountV2Args = {
  criteria?: InputMaybe<FileQuerySpecInput>;
};

export type QueryFileTypeCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryFileV2Args = {
  id: Scalars["UUID"];
};

export type QueryFilesV2Args = {
  criteria?: InputMaybe<FileQuerySpecInput>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryFixedSettlementArgs = {
  criteria?: InputMaybe<FixedSettlementQueryRequestInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryFixedSettlementPageArgs = {
  criteria?: InputMaybe<FixedSettlementQueryRequestInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryGenreCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryJobArgs = {
  clientUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  criteria?: InputMaybe<JobCriteriaInput>;
  deadlineFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  deadlineTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  disableDeadline?: InputMaybe<Scalars["Boolean"]>;
  managerUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  name?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  projectUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
  taskState?: InputMaybe<JobTaskState>;
};

export type QueryJobByLiveTranslationArgs = {
  liveTranslation: Scalars["Boolean"];
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryJobByUuidArgs = {
  jobUuid: Scalars["UUID"];
};

export type QueryJobByUuidsArgs = {
  jobUuids: Array<Scalars["UUID"]>;
};

export type QueryJobExcludedByProjectOrderArgs = {
  page?: InputMaybe<Scalars["Int"]>;
  projectOrderUuid: Scalars["UUID"];
  projectUuid: Scalars["UUID"];
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryJobPageArgs = {
  criteria?: InputMaybe<JobCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryJobProcessArgs = {
  jobUuid?: InputMaybe<Scalars["UUID"]>;
  taskState?: InputMaybe<JobProcessTaskState>;
};

export type QueryJobProcessByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QueryJobProcessByUuidsArgs = {
  uuids: Array<Scalars["UUID"]>;
};

export type QueryJobProcessHistoryPageArgs = {
  criteria?: InputMaybe<JobProcessHistoryCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryJobProcessRoleArgs = {
  authUuid: Scalars["UUID"];
  jobProcessUuid: Scalars["UUID"];
};

export type QueryJobTypeCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryLanguageCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryLinkByUuidArgs = {
  linkUuid: Scalars["UUID"];
};

export type QueryLinkByUuidsArgs = {
  linkUuids: Array<Scalars["UUID"]>;
};

export type QueryNotWorkableDayArgs = {
  authUuid?: InputMaybe<Scalars["UUID"]>;
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  day?: InputMaybe<Scalars["OffsetDateTime"]>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QueryNotWorkableDayFullArgs = {
  authUuid?: InputMaybe<Scalars["UUID"]>;
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  day?: InputMaybe<Scalars["OffsetDateTime"]>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QueryNotWorkableDayOfWeekArgs = {
  authUuid?: InputMaybe<Scalars["UUID"]>;
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  day?: InputMaybe<Scalars["OffsetDateTime"]>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QueryNotWorkableDayOfWeekFullArgs = {
  authUuid?: InputMaybe<Scalars["UUID"]>;
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  day?: InputMaybe<Scalars["OffsetDateTime"]>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QueryOpenPositionsArgs = {
  recommendCodePromotionType?: InputMaybe<RecommendCodePromotionType>;
  urgent?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryOperationArgs = {
  criteria?: InputMaybe<OperationCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryOperationByUuidArgs = {
  operationUuid: Scalars["UUID"];
};

export type QueryOperationByUuidsArgs = {
  operationUuids: Array<Scalars["UUID"]>;
};

export type QueryOperationPageArgs = {
  criteria?: InputMaybe<OperationCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryOperationPageGroupByPositionArgs = {
  criteria?: InputMaybe<OperationCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryOperationStepCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryOperationTypeCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryPanoplayJobByUuidArgs = {
  panoplayJobUuid: Scalars["UUID"];
};

export type QueryPanoplayJobByUuidsArgs = {
  panoplayJobUuids: Array<Scalars["UUID"]>;
};

export type QueryPanoplayJobFileByPanoplayJobUuidArgs = {
  panoplayJobUuid: Scalars["UUID"];
};

export type QueryPanoplayJobLinkByPanoplayJobUuidArgs = {
  panoplayJobUuid: Scalars["UUID"];
};

export type QueryPanoplayOrderByUuidArgs = {
  panoplayOrderUuid: Scalars["UUID"];
};

export type QueryPanoplayOrderByUuidsArgs = {
  panoplayOrderUuids: Array<Scalars["UUID"]>;
};

export type QueryPanoplayOrderOptionByPanoplayOrderUuidArgs = {
  panoplayOrderUuid: Scalars["UUID"];
};

export type QueryPanoplayProjectByUuidArgs = {
  panoplayProjectUuid: Scalars["UUID"];
};

export type QueryPanoplayProjectByUuidsArgs = {
  panoplayProjectUuids: Array<Scalars["UUID"]>;
};

export type QueryPanoplayProjectFileByPanoplayProjectUuidArgs = {
  panoplayProjectUuid: Scalars["UUID"];
};

export type QueryPanoplayProjectLinkByPanoplayProjectUuidArgs = {
  panoplayProjectUuid: Scalars["UUID"];
};

export type QueryPayByPriceUuidArgs = {
  priceUuid: Scalars["UUID"];
};

export type QueryPayByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QueryPayByUuidsArgs = {
  payUuids: Array<Scalars["UUID"]>;
};

export type QueryPocTaskCountArgs = {
  criteria?: InputMaybe<TaskCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryPocTaskEvaluationRecommendCountArgs = {
  criteria?: InputMaybe<TaskEvaluationCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryPocTaskEvaluationStateCountArgs = {
  criteria?: InputMaybe<TaskEvaluationCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryPositionArgs = {
  open?: InputMaybe<Scalars["Boolean"]>;
  page?: InputMaybe<Scalars["Int"]>;
  recommendCodePromotionType?: InputMaybe<RecommendCodePromotionType>;
  size?: InputMaybe<Scalars["Int"]>;
  skillTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  sourceLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  targetLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  urgent?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryPositionByUuidArgs = {
  positionUuid: Scalars["UUID"];
};

export type QueryPositionByUuidsArgs = {
  positionUuids: Array<Scalars["UUID"]>;
};

export type QueryPositionFullArgs = {
  open?: InputMaybe<Scalars["Boolean"]>;
  recommendCodePromotionType?: InputMaybe<RecommendCodePromotionType>;
  skillTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  sourceLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  targetLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  urgent?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryPositionTestProcessByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QueryPrepaymentWaitingArgs = {
  id: Scalars["UUID"];
};

export type QueryPrepaymentWaitingCountArgs = {
  criteria?: InputMaybe<QuerySpecInput>;
};

export type QueryPrepaymentWaitingsArgs = {
  criteria?: InputMaybe<QuerySpecInput>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryPreprocessingByUuidArgs = {
  preprocessingUuid: Scalars["UUID"];
};

export type QueryPreprocessingByUuidsArgs = {
  preprocessingUuids: Array<Scalars["UUID"]>;
};

export type QueryPriceArgs = {
  closed?: InputMaybe<Scalars["Boolean"]>;
  criteria?: InputMaybe<PriceQueryCriteriaInput>;
  name?: InputMaybe<Scalars["String"]>;
  operationTypeCode?: InputMaybe<Scalars["String"]>;
  purposes?: InputMaybe<Array<PricePurpose>>;
  sort?: InputMaybe<Array<SortInput>>;
  sourceLanguageCode?: InputMaybe<Scalars["String"]>;
  standard?: InputMaybe<Scalars["Boolean"]>;
  targetLanguageCode?: InputMaybe<Scalars["String"]>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QueryPriceByOperationTypeCodeArgs = {
  operationTypeCode: Scalars["String"];
};

export type QueryPriceByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QueryPriceByUuidsArgs = {
  priceUuids: Array<Scalars["UUID"]>;
};

export type QueryPricePageArgs = {
  closed?: InputMaybe<Scalars["Boolean"]>;
  criteria?: InputMaybe<PriceQueryCriteriaInput>;
  name?: InputMaybe<Scalars["String"]>;
  operationTypeCode?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  purposes?: InputMaybe<Array<PricePurpose>>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
  sourceLanguageCode?: InputMaybe<Scalars["String"]>;
  standard?: InputMaybe<Scalars["Boolean"]>;
  targetLanguageCode?: InputMaybe<Scalars["String"]>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QueryProcessTypeCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryProductPriceArgs = {
  id: Scalars["UUID"];
};

export type QueryProductPriceCountArgs = {
  criteria?: InputMaybe<QuerySpecInput>;
};

export type QueryProductPricesArgs = {
  criteria?: InputMaybe<QuerySpecInput>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryProfileArgs = {
  authUuid?: InputMaybe<Scalars["UUID"]>;
  name?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryProfileByAuthUuidArgs = {
  authUuid: Scalars["UUID"];
};

export type QueryProfileByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QueryProfileByUuidsArgs = {
  uuids: Array<Scalars["UUID"]>;
};

export type QueryProjectArgs = {
  clientUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  managers?: InputMaybe<Array<Scalars["UUID"]>>;
  name?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QueryProjectByUuidArgs = {
  projectUuid: Scalars["UUID"];
};

export type QueryProjectByUuidsArgs = {
  projectUuids: Array<Scalars["UUID"]>;
};

export type QueryProjectOrderByUuidArgs = {
  projectOrderUuid: Scalars["UUID"];
};

export type QueryProjectPageArgs = {
  criteria?: InputMaybe<ProjectQueryRequestInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryProjectTypeCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryQuestionArgs = {
  criteria?: InputMaybe<QuestionCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryQuestionApplicationArgs = {
  criteria?: InputMaybe<QuestionApplicationCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryQuestionApplicationByUuidArgs = {
  questionApplicationUuid: Scalars["UUID"];
};

export type QueryQuestionApplicationPageArgs = {
  criteria?: InputMaybe<QuestionApplicationCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryQuestionByUuidArgs = {
  questionUuid: Scalars["UUID"];
};

export type QueryQuestionGroupArgs = {
  criteria?: InputMaybe<QuestionGroupCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryQuestionGroupByUuidArgs = {
  questionGroupUuid: Scalars["UUID"];
};

export type QueryQuestionGroupPageArgs = {
  criteria?: InputMaybe<QuestionGroupCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryQuestionOptionArgs = {
  criteria?: InputMaybe<QuestionOptionCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryQuestionOptionByUuidArgs = {
  questionOptionUuid: Scalars["UUID"];
};

export type QueryQuestionOptionPageArgs = {
  criteria?: InputMaybe<QuestionOptionCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryQuestionOptionSnapshotArgs = {
  criteria?: InputMaybe<QuestionOptionSnapshotCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryQuestionOptionSnapshotByUuidArgs = {
  questionOptionSnapshotUuid: Scalars["UUID"];
};

export type QueryQuestionPageArgs = {
  criteria?: InputMaybe<QuestionCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryQuestionSnapshotArgs = {
  criteria?: InputMaybe<QuestionSnapshotCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryQuestionSnapshotByUuidArgs = {
  questionSnapshotUuid: Scalars["UUID"];
};

export type QueryQuestionSnapshotPageArgs = {
  criteria?: InputMaybe<QuestionSnapshotCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryQuizGuideByUuidArgs = {
  quizGuideUuid: Scalars["UUID"];
};

export type QueryQuotationArgs = {
  id: Scalars["UUID"];
};

export type QueryQuotationCountArgs = {
  criteria?: InputMaybe<QuotationQuerySpecInput>;
};

export type QueryQuotationProductArgs = {
  id: Scalars["UUID"];
};

export type QueryQuotationProductCountArgs = {
  criteria?: InputMaybe<QuotationProductGqlQuerySpecInput>;
};

export type QueryQuotationProductsArgs = {
  criteria?: InputMaybe<QuotationProductGqlQuerySpecInput>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryQuotationRequestArgs = {
  id: Scalars["UUID"];
};

export type QueryQuotationRequestCountArgs = {
  criteria?: InputMaybe<QuotationRequestGqlQuerySpecInput>;
};

export type QueryQuotationRequestsArgs = {
  criteria?: InputMaybe<QuotationRequestGqlQuerySpecInput>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryQuotationsArgs = {
  criteria?: InputMaybe<QuotationQuerySpecInput>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryRecentAppliedPocGroupedByOperationsArgs = {
  criteria?: InputMaybe<OperationCriteriaInput>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryRecommendCodeArgs = {
  criteria?: InputMaybe<RecommendCodeQueryRequestInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryRecommendCodePageArgs = {
  criteria?: InputMaybe<RecommendCodeQueryRequestInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryRecommendCodeUseHistoryArgs = {
  criteria?: InputMaybe<RecommendCodeUseHistoryQueryRequestInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryRecommendCodeUseHistoryPageArgs = {
  criteria?: InputMaybe<RecommendCodeUseHistoryQueryRequestInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryRecruitmentEvaluationItemArgs = {
  criteria?: InputMaybe<RecruitmentEvaluationItemCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryRecruitmentEvaluationItemByUuidArgs = {
  recruitmentEvaluationItemUuid: Scalars["UUID"];
};

export type QueryRecruitmentEvaluationItemPageArgs = {
  criteria?: InputMaybe<RecruitmentEvaluationItemCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryRecruitmentEvaluationItemSnapshotArgs = {
  criteria?: InputMaybe<RecruitmentEvaluationItemSnapshotCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryRecruitmentEvaluationItemSnapshotByUuidArgs = {
  recruitmentEvaluationItemSnapshotUuid: Scalars["UUID"];
};

export type QueryRecruitmentEvaluationItemSnapshotPageArgs = {
  criteria?: InputMaybe<RecruitmentEvaluationItemSnapshotCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryRecruitmentEvaluationOptionArgs = {
  criteria?: InputMaybe<RecruitmentEvaluationOptionCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryRecruitmentEvaluationOptionByUuidArgs = {
  recruitmentEvaluationOptionUuid: Scalars["UUID"];
};

export type QueryRecruitmentEvaluationOptionPageArgs = {
  criteria?: InputMaybe<RecruitmentEvaluationOptionCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryRecruitmentEvaluationOptionSnapshotArgs = {
  criteria?: InputMaybe<RecruitmentEvaluationOptionSnapshotCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryRecruitmentEvaluationOptionSnapshotByUuidArgs = {
  recruitmentEvaluationOptionSnapshotUuid: Scalars["UUID"];
};

export type QueryRecruitmentEvaluationOptionSnapshotPageArgs = {
  criteria?: InputMaybe<RecruitmentEvaluationOptionSnapshotCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryRecruitmentEvaluationResultArgs = {
  criteria?: InputMaybe<RecruitmentEvaluationResultCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryRecruitmentEvaluationResultByUuidArgs = {
  recruitmentEvaluationResultUuid: Scalars["UUID"];
};

export type QueryRecruitmentEvaluationResultGroupedPageArgs = {
  criteria?: InputMaybe<RecruitmentEvaluationResultGroupQueryRequestInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryRecruitmentEvaluationResultPageArgs = {
  criteria?: InputMaybe<RecruitmentEvaluationResultCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryRecruitmentEvaluationStandardArgs = {
  criteria?: InputMaybe<RecruitmentEvaluationStandardCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryRecruitmentEvaluationStandardByUuidArgs = {
  recruitmentEvaluationStandardUuid: Scalars["UUID"];
};

export type QueryRecruitmentEvaluationStandardPageArgs = {
  criteria?: InputMaybe<RecruitmentEvaluationStandardCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryReferenceByUuidArgs = {
  referenceUuid: Scalars["UUID"];
};

export type QueryReferenceByUuidsArgs = {
  referenceUuids: Array<Scalars["UUID"]>;
};

export type QueryReferenceHistoryArgs = {
  criteria?: InputMaybe<ReferenceHistoryCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryReferenceHistoryPageArgs = {
  criteria?: InputMaybe<ReferenceHistoryCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryReferenceTypeCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryRelayBacklotProjectArgs = {
  id: Scalars["Int"];
};

export type QueryRelayBacklotProjectsArgs = {
  category?: InputMaybe<RequestCategory>;
  companyIds?: InputMaybe<Array<Scalars["Int"]>>;
  managerIds?: InputMaybe<Array<Scalars["Int"]>>;
  page: Scalars["Int"];
  size: Scalars["Int"];
  status?: InputMaybe<Array<ProjectStatus>>;
};

export type QueryRelayPanoplayJobResultArgs = {
  id: Scalars["Int"];
};

export type QueryResourceByUuidArgs = {
  resourceUuid: Scalars["UUID"];
};

export type QueryResourceByUuidsArgs = {
  resourceUuids: Array<Scalars["UUID"]>;
};

export type QueryResourceTypeCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryResultByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QueryResultByUuidsArgs = {
  uuids: Array<Scalars["UUID"]>;
};

export type QuerySkillArgs = {
  authUuid?: InputMaybe<Scalars["UUID"]>;
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  contentTypeCode?: InputMaybe<Scalars["String"]>;
  disabled?: InputMaybe<Scalars["Boolean"]>;
  grade?: InputMaybe<SkillGrade>;
  onBoardingCompleted?: InputMaybe<Scalars["Boolean"]>;
  onBoardingConfirmed?: InputMaybe<Scalars["Boolean"]>;
  page?: InputMaybe<Scalars["Int"]>;
  pocEvaluationExpert?: InputMaybe<Scalars["Boolean"]>;
  size?: InputMaybe<Scalars["Int"]>;
  skillTypeCode?: InputMaybe<Scalars["String"]>;
  sourceLanguageCode?: InputMaybe<Scalars["String"]>;
  states?: InputMaybe<Array<SkillState>>;
  targetLanguageCode?: InputMaybe<Scalars["String"]>;
  temporary?: InputMaybe<Scalars["Boolean"]>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QuerySkillByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QuerySkillByUuidsArgs = {
  uuids: Array<Scalars["UUID"]>;
};

export type QuerySkillHistoryPageArgs = {
  criteria?: InputMaybe<SkillHistoryCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QuerySkillTypeCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryTagByUuidArgs = {
  tagUuid: Scalars["UUID"];
};

export type QueryTagGroupCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryTagPageArgs = {
  criteria?: InputMaybe<TagCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryTagQueryArgs = {
  criteria?: InputMaybe<TagCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryTargetSettlementArgs = {
  criteria?: InputMaybe<TargetSettlementQueryCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryTargetSettlementGroupedArgs = {
  criteria?: InputMaybe<TargetSettlementGroupQueryRequestInput>;
};

export type QueryTargetSettlementGroupedPageArgs = {
  criteria?: InputMaybe<TargetSettlementGroupQueryRequestInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryTargetSettlementPageArgs = {
  criteria?: InputMaybe<TargetSettlementQueryCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryTaskArgs = {
  clientUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  criteria?: InputMaybe<TaskCriteriaInput>;
  disableEndDate?: InputMaybe<Scalars["Boolean"]>;
  endDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  endDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  jobUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  managerUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  notAssignedOnly?: InputMaybe<Scalars["Boolean"]>;
  operationTypeCode?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  projectUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
  sourceLanguageCode?: InputMaybe<Scalars["String"]>;
  startDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  startDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  states?: InputMaybe<Array<TaskState>>;
  targetLanguageCode?: InputMaybe<Scalars["String"]>;
  workerUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  workingPeriodEndDate?: InputMaybe<Scalars["OffsetDateTime"]>;
  workingPeriodStartDate?: InputMaybe<Scalars["OffsetDateTime"]>;
};

export type QueryTaskByGroupUuidArgs = {
  taskGroupUuid: Scalars["UUID"];
};

export type QueryTaskByUuidArgs = {
  taskUuid: Scalars["UUID"];
};

export type QueryTaskByUuidsArgs = {
  taskUuids: Array<Scalars["UUID"]>;
};

export type QueryTaskEvaluationArgs = {
  criteria?: InputMaybe<TaskEvaluationCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryTaskEvaluationByUuidArgs = {
  taskEvaluationUuid: Scalars["UUID"];
};

export type QueryTaskEvaluationByUuidsArgs = {
  taskEvaluationUuids: Array<Scalars["UUID"]>;
};

export type QueryTaskEvaluationPageArgs = {
  criteria?: InputMaybe<TaskEvaluationCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryTaskFullArgs = {
  clientUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  criteria?: InputMaybe<TaskCriteriaInput>;
  disableEndDate?: InputMaybe<Scalars["Boolean"]>;
  dueDateIsNull?: InputMaybe<Scalars["Boolean"]>;
  endDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  endDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  jobUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  managerUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  notAssignedOnly?: InputMaybe<Scalars["Boolean"]>;
  operationTypeCode?: InputMaybe<Scalars["String"]>;
  payIsNull?: InputMaybe<Scalars["Boolean"]>;
  projectUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  sort?: InputMaybe<Array<SortInput>>;
  sourceLanguageCode?: InputMaybe<Scalars["String"]>;
  startDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  startDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  states?: InputMaybe<Array<TaskState>>;
  targetLanguageCode?: InputMaybe<Scalars["String"]>;
  workerUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  workingPeriodEndDate?: InputMaybe<Scalars["OffsetDateTime"]>;
  workingPeriodStartDate?: InputMaybe<Scalars["OffsetDateTime"]>;
};

export type QueryTaskGroupArgs = {
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  workerUuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QueryTaskGroupByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QueryTaskGroupByUuidsArgs = {
  taskGroupUuids: Array<Scalars["UUID"]>;
};

export type QueryTaskGroupByWorkerUuidArgs = {
  workerUuid: Scalars["UUID"];
};

export type QueryTaskPageArgs = {
  criteria?: InputMaybe<TaskCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryTaskReportArgs = {
  criteria?: InputMaybe<TaskReportCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryTaskReportByUuidArgs = {
  taskReportUuid: Scalars["UUID"];
};

export type QueryTaskReportPageArgs = {
  criteria?: InputMaybe<TaskReportCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryTaskStateCountArgs = {
  criteria?: InputMaybe<TaskCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryTermAgreementArgs = {
  authUuid?: InputMaybe<Scalars["UUID"]>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryTermAgreementByAuthUuidArgs = {
  authUuid: Scalars["UUID"];
};

export type QueryTermAgreementByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QueryTermAgreementByUuidsArgs = {
  uuids: Array<Scalars["UUID"]>;
};

export type QueryTestArgs = {
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryTestByUuidArgs = {
  testUuid: Scalars["UUID"];
};

export type QueryTestByUuidsArgs = {
  testUuids: Array<Scalars["UUID"]>;
};

export type QueryTestEvaluationArgs = {
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  testProcessUuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryTestEvaluationByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QueryTestEvaluationByUuidsArgs = {
  uuids: Array<Scalars["UUID"]>;
};

export type QueryTestGuideArgs = {
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryTestGuideByUuidArgs = {
  testGuideUuid: Scalars["UUID"];
};

export type QueryTestGuideByUuidsArgs = {
  testGuideUuids: Array<Scalars["UUID"]>;
};

export type QueryTestGuidesByPositionAndSubmissionTypeArgs = {
  skillTypeCode: Scalars["String"];
  sourceLanguageCode?: InputMaybe<Scalars["String"]>;
  submissionType?: InputMaybe<SubmissionType>;
  targetLanguageCode?: InputMaybe<Scalars["String"]>;
};

export type QueryTestProcessArgs = {
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  evaluatorUuid?: InputMaybe<Scalars["UUID"]>;
  page?: InputMaybe<Scalars["Int"]>;
  positionUuid?: InputMaybe<Scalars["UUID"]>;
  positionUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  size?: InputMaybe<Scalars["Int"]>;
  startedDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  startedDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  states?: InputMaybe<Array<TestProcessState>>;
};

export type QueryTestProcessByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QueryTestProcessStateCountArgs = {
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  evaluatorUuid?: InputMaybe<Scalars["UUID"]>;
  positionUuid?: InputMaybe<Scalars["UUID"]>;
  positionUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  startedDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  startedDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
};

export type QueryTestSetArgs = {
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryTestSetByUuidArgs = {
  testSetUuid: Scalars["UUID"];
};

export type QueryTestSetByUuidsArgs = {
  testSetUuids: Array<Scalars["UUID"]>;
};

export type QueryTestSetFileByUuidArgs = {
  testSetFileUuid: Scalars["UUID"];
};

export type QueryTestSetFileByUuidsArgs = {
  testSetFileUuids: Array<Scalars["UUID"]>;
};

export type QueryTestSetLinkByUuidArgs = {
  testSetLinkUuid: Scalars["UUID"];
};

export type QueryTestSetLinkByUuidsArgs = {
  testSetLinkUuids: Array<Scalars["UUID"]>;
};

export type QueryTimezoneCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryToRequestArgs = {
  criteria: OperationCriteriaInput;
};

export type QueryTransferByUuidArgs = {
  uuid: Scalars["UUID"];
};

export type QueryTransferByUuidsArgs = {
  uuids: Array<Scalars["UUID"]>;
};

export type QueryUnitPointerByUuidArgs = {
  unitPointerUuid: Scalars["UUID"];
};

export type QueryUnitPointerGroupByUuidArgs = {
  unitPointerGroupUuid: Scalars["UUID"];
};

export type QueryUserDayScheduleArgs = {
  authUuid: Scalars["UUID"];
  day: Scalars["OffsetDateTime"];
};

export type QueryUserRangeScheduleArgs = {
  authUuid: Scalars["UUID"];
  endDay: Scalars["OffsetDateTime"];
  startDay: Scalars["OffsetDateTime"];
};

export type QueryWorkByUuidArgs = {
  workUuid: Scalars["UUID"];
};

export type QueryWorkByUuidsArgs = {
  workUuids: Array<Scalars["UUID"]>;
};

export type QueryWorkPriceArgs = {
  id: Scalars["UUID"];
};

export type QueryWorkPriceCountArgs = {
  criteria?: InputMaybe<QuerySpecInput>;
};

export type QueryWorkPricesArgs = {
  criteria?: InputMaybe<QuerySpecInput>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryWorkUnitCodeByCodeArgs = {
  code: Scalars["String"];
};

export type QueryWorkableDayArgs = {
  authUuid?: InputMaybe<Scalars["UUID"]>;
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  dayFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  dayTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QueryWorkableDayFullArgs = {
  authUuid?: InputMaybe<Scalars["UUID"]>;
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  dayFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  dayTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QueryWorkableWorkerArgs = {
  contentTypeCode?: InputMaybe<Scalars["String"]>;
  grade?: InputMaybe<SkillGrade>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  skillTypeCode?: InputMaybe<Scalars["String"]>;
  sourceLanguageCode?: InputMaybe<Scalars["String"]>;
  targetLanguageCode?: InputMaybe<Scalars["String"]>;
};

export type QueryWorkerArgs = {
  contentTypeCode?: InputMaybe<Scalars["String"]>;
  grade?: InputMaybe<SkillGrade>;
  searchParam?: InputMaybe<Scalars["String"]>;
  skillTypeCode?: InputMaybe<Scalars["String"]>;
  sourceLanguageCode?: InputMaybe<Scalars["String"]>;
  targetLanguageCode?: InputMaybe<Scalars["String"]>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
  workable?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryWorkerByAuthUuidArgs = {
  authUuid: Scalars["UUID"];
};

export type QueryWorkerByUuidArgs = {
  workerUuid: Scalars["UUID"];
};

export type QueryWorkerByUuidsArgs = {
  workerUuids: Array<Scalars["UUID"]>;
};

export type QueryWorkerGroupArgs = {
  contentTypeCode?: InputMaybe<Scalars["String"]>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  skillTypeCode?: InputMaybe<Scalars["String"]>;
  sourceLanguageCode?: InputMaybe<Scalars["String"]>;
  targetLanguageCode?: InputMaybe<Scalars["String"]>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QueryWorkerGroupByUuidArgs = {
  workerGroupUuid: Scalars["UUID"];
};

export type QueryWorkerGroupByUuidsArgs = {
  workerGroupUuids: Array<Scalars["UUID"]>;
};

export type QueryWorkerGroupPageArgs = {
  contentTypeCode?: InputMaybe<Scalars["String"]>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  skillTypeCode?: InputMaybe<Scalars["String"]>;
  sourceLanguageCode?: InputMaybe<Scalars["String"]>;
  targetLanguageCode?: InputMaybe<Scalars["String"]>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QueryWorkerMatchingCandidateArgs = {
  id: Scalars["UUID"];
};

export type QueryWorkerMatchingCandidateCountArgs = {
  criteria?: InputMaybe<WorkerMatchingCandidateQuerySpecInput>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryWorkerMatchingCandidatesArgs = {
  criteria?: InputMaybe<WorkerMatchingCandidateQuerySpecInput>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type QueryWorkerPageArgs = {
  contentTypeCode?: InputMaybe<Scalars["String"]>;
  grade?: InputMaybe<SkillGrade>;
  page?: InputMaybe<Scalars["Int"]>;
  searchParam?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Int"]>;
  skillTypeCode?: InputMaybe<Scalars["String"]>;
  sourceLanguageCode?: InputMaybe<Scalars["String"]>;
  targetLanguageCode?: InputMaybe<Scalars["String"]>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
  workable?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryWorkerQualificationBySkillArgs = {
  skillTypeCode: Scalars["String"];
  sourceLanguageCode?: InputMaybe<Scalars["String"]>;
  targetLanguageCode?: InputMaybe<Scalars["String"]>;
};

export type QueryWorkerQualificationByUuidArgs = {
  workerQualificationUuid: Scalars["UUID"];
};

export type QueryWorkerQualificationByWorkerUuidArgs = {
  workerUuid: Scalars["UUID"];
};

export type QueryWorkerWorkableArgs = {
  criteria?: InputMaybe<WorkerWorkableCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QuerySpecInput = {
  ids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type Question = BaseAggregateRoot & {
  __typename?: "Question";
  bid: Scalars["String"];
  contentTypeCode?: Maybe<Scalars["String"]>;
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deleted: Scalars["Boolean"];
  deletedBy?: Maybe<Auth>;
  deletedDate?: Maybe<Scalars["OffsetDateTime"]>;
  hintCommentTranslations: Array<ContentTranslation>;
  hintCommentUuid?: Maybe<Scalars["UUID"]>;
  hintUrl?: Maybe<Scalars["String"]>;
  index?: Maybe<Scalars["Int"]>;
  manager?: Maybe<Auth>;
  modifiedBy?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  questionGroup?: Maybe<QuestionGroup>;
  questionGroupUuid?: Maybe<Scalars["UUID"]>;
  questionOptions: Array<QuestionOption>;
  skillTypeCode?: Maybe<Scalars["String"]>;
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  titleS3Path?: Maybe<Scalars["String"]>;
  titleTranslations: Array<ContentTranslation>;
  titleUuid?: Maybe<Scalars["UUID"]>;
  uuid: Scalars["UUID"];
};

export type QuestionQuestionOptionsArgs = {
  correct?: InputMaybe<Scalars["Boolean"]>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
};

export type QuestionApplication = BaseAggregateRoot & {
  __typename?: "QuestionApplication";
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  contentTypeCode?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  cutOffScore?: Maybe<Scalars["Int"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  passed: Scalars["Boolean"];
  questionGroup: QuestionGroup;
  questionGroupUuid: Scalars["UUID"];
  questionSnapshots: Array<QuestionSnapshot>;
  skillTypeCode?: Maybe<Scalars["String"]>;
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
};

export type QuestionApplicationCriteriaInput = {
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  contentTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  passed?: InputMaybe<Scalars["Boolean"]>;
  questionGroupUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  skillTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  sourceLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  targetLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QuestionApplicationPageResponse = {
  __typename?: "QuestionApplicationPageResponse";
  content: Array<QuestionApplication>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  sort: QuestionApplicationPageResponse;
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type QuestionApplicationPageResponseSortArgs = {
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QuestionCriteriaInput = {
  contentTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  createdBy?: InputMaybe<Array<Scalars["UUID"]>>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  deletedBy?: InputMaybe<Array<Scalars["UUID"]>>;
  managers?: InputMaybe<Array<Scalars["UUID"]>>;
  modifiedBy?: InputMaybe<Array<Scalars["UUID"]>>;
  questionGroupUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  skillTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  sourceLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  targetLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QuestionGroup = BaseAggregateRoot & {
  __typename?: "QuestionGroup";
  bid: Scalars["String"];
  contentTypeCode?: Maybe<Scalars["String"]>;
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  cutOffScore?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  disabled: Scalars["Boolean"];
  disabledBy?: Maybe<Auth>;
  disabledDate?: Maybe<Scalars["OffsetDateTime"]>;
  manager?: Maybe<Auth>;
  modifiedBy?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  questions: Array<Question>;
  quizGuide: QuizGuide;
  quizGuideUuid: Scalars["UUID"];
  skillTypeCode?: Maybe<Scalars["String"]>;
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
  visibleQuestionCount: Scalars["Int"];
};

export type QuestionGroupQuestionsArgs = {
  deleted?: InputMaybe<Scalars["Boolean"]>;
};

export type QuestionGroupCriteriaInput = {
  contentTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  createdBy?: InputMaybe<Array<Scalars["UUID"]>>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  disabled?: InputMaybe<Scalars["Boolean"]>;
  disabledBy?: InputMaybe<Array<Scalars["UUID"]>>;
  managers?: InputMaybe<Array<Scalars["UUID"]>>;
  modifiedBy?: InputMaybe<Array<Scalars["UUID"]>>;
  quizGuideUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  skillTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  sourceLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  targetLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QuestionGroupPageResponse = {
  __typename?: "QuestionGroupPageResponse";
  content: Array<QuestionGroup>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  sort: QuestionGroupPageResponse;
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type QuestionGroupPageResponseSortArgs = {
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QuestionOption = BaseAggregateRoot & {
  __typename?: "QuestionOption";
  bid: Scalars["String"];
  contentTypeCode?: Maybe<Scalars["String"]>;
  correct: Scalars["Boolean"];
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deleted: Scalars["Boolean"];
  deletedBy?: Maybe<Auth>;
  deletedDate?: Maybe<Scalars["OffsetDateTime"]>;
  descriptionComment?: Maybe<Scalars["String"]>;
  descriptionS3Path?: Maybe<Scalars["String"]>;
  modifiedBy?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  question: Question;
  questionUuid: Scalars["UUID"];
  skillTypeCode?: Maybe<Scalars["String"]>;
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
};

export type QuestionOptionCriteriaInput = {
  contentTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  correct?: InputMaybe<Scalars["Boolean"]>;
  createdBy?: InputMaybe<Array<Scalars["UUID"]>>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  deletedBy?: InputMaybe<Array<Scalars["UUID"]>>;
  questionUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  skillTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  sourceLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  targetLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QuestionOptionPageResponse = {
  __typename?: "QuestionOptionPageResponse";
  content: Array<QuestionOption>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  sort: QuestionOptionPageResponse;
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type QuestionOptionPageResponseSortArgs = {
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QuestionOptionSnapshot = BaseAggregateRoot & {
  __typename?: "QuestionOptionSnapshot";
  bid: Scalars["String"];
  contentTypeCode?: Maybe<Scalars["String"]>;
  correct: Scalars["Boolean"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  descriptionComment?: Maybe<Scalars["String"]>;
  descriptionS3Path?: Maybe<Scalars["String"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  questionSnapshot: QuestionSnapshot;
  questionSnapshotUuid: Scalars["UUID"];
  selected: Scalars["Boolean"];
  skillTypeCode?: Maybe<Scalars["String"]>;
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
};

export type QuestionOptionSnapshotCriteriaInput = {
  contentTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  correct?: InputMaybe<Scalars["Boolean"]>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  questionSnapshotUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  selected?: InputMaybe<Scalars["Boolean"]>;
  skillTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  sourceLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  targetLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QuestionPageResponse = {
  __typename?: "QuestionPageResponse";
  content: Array<Question>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  sort: QuestionPageResponse;
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type QuestionPageResponseSortArgs = {
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QuestionSnapshot = BaseAggregateRoot & {
  __typename?: "QuestionSnapshot";
  bid: Scalars["String"];
  contentTypeCode?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  hintComment?: Maybe<Scalars["String"]>;
  hintUrl?: Maybe<Scalars["String"]>;
  index?: Maybe<Scalars["Int"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  questionApplication: QuestionApplication;
  questionApplicationUuid: Scalars["UUID"];
  questionOptionSnapshots: Array<QuestionOptionSnapshot>;
  skillTypeCode?: Maybe<Scalars["String"]>;
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  titleS3Path?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
};

export type QuestionSnapshotQuestionOptionSnapshotsArgs = {
  correct?: InputMaybe<Scalars["Boolean"]>;
};

export type QuestionSnapshotCriteriaInput = {
  contentTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  questionApplicationUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  skillTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  sourceLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  targetLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QuestionSnapshotPageResponse = {
  __typename?: "QuestionSnapshotPageResponse";
  content: Array<QuestionSnapshot>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  sort: QuestionSnapshotPageResponse;
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type QuestionSnapshotPageResponseSortArgs = {
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QuizGuide = BaseAggregateRoot & {
  __typename?: "QuizGuide";
  bid: Scalars["String"];
  contentTypeCode?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deleted: Scalars["Boolean"];
  deletedBy?: Maybe<Auth>;
  deletedDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  skillTypeCode?: Maybe<Scalars["String"]>;
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
  uuid: Scalars["UUID"];
};

export type QuotationDelivery = DocumentQuotationDelivery | ToonQuotationDelivery;

export type QuotationDifficultyEvaluation = ToonDifficultyEvaluation;

export type QuotationGqlModel = {
  __typename?: "QuotationGqlModel";
  bid: Scalars["String"];
  content?: Maybe<ContentGqlModel>;
  createdBy: Auth;
  createdDate: Scalars["OffsetDateTime"];
  customer: CustomerGqlModel;
  eventVersion: Scalars["Long"];
  id: Scalars["UUID"];
  manager: Auth;
  notes?: Maybe<Scalars["String"]>;
  prefix: Scalars["String"];
  prepaymentWaiting?: Maybe<PrepaymentWaitingGqlModel>;
  quotationProducts: Array<QuotationProductGqlModel>;
  quotationRequest?: Maybe<QuotationRequestGqlModel>;
  sourceDirectory?: Maybe<DirectoryGqlModel>;
  sourceUrl?: Maybe<Scalars["String"]>;
  version: Scalars["Long"];
};

export type QuotationGqlModelBidArgs = {
  format?: InputMaybe<GqlBusinessIdFormat>;
};

export type QuotationPresetAutomaticWorkMatchingProcessCreateRequestInput = {
  forceInitialize?: InputMaybe<Scalars["Boolean"]>;
  groupSize?: InputMaybe<Scalars["Int"]>;
  operationType: CodeAssociationInput;
  periodMinutes?: InputMaybe<Scalars["Int"]>;
  quotationPreset: AggregateAssociationInput;
  targetAcceptanceCount?: InputMaybe<Scalars["Int"]>;
};

export type QuotationPresetAutomaticWorkMatchingProcessCreateResponse = CommonResponse & {
  __typename?: "QuotationPresetAutomaticWorkMatchingProcessCreateResponse";
  quotationPreset: QuotationPresetGqlModel;
  succeed: Scalars["Boolean"];
};

export type QuotationPresetGqlModel = {
  __typename?: "QuotationPresetGqlModel";
  bid: Scalars["String"];
  createdBy: Auth;
  createdDate: Scalars["OffsetDateTime"];
  eventVersion: Scalars["Long"];
  id: Scalars["UUID"];
  prefix: Scalars["String"];
  quotationProduct: QuotationProductGqlModel;
  version: Scalars["Long"];
  workPrice: Array<QuotationPricePresetGqlModel>;
  worker: Array<QuotationWorkerPresetGqlModel>;
};

export type QuotationPresetGqlModelBidArgs = {
  format?: InputMaybe<GqlBusinessIdFormat>;
};

export type QuotationPresetManualWorkMatchingProcessCreateRequestInput = {
  operationType: CodeAssociationInput;
  quotationPreset: AggregateAssociationInput;
};

export type QuotationPresetManualWorkMatchingProcessCreateResponse = CommonResponse & {
  __typename?: "QuotationPresetManualWorkMatchingProcessCreateResponse";
  quotationPreset: QuotationPresetGqlModel;
  succeed: Scalars["Boolean"];
};

export type QuotationPresetWorkMatchingProcessInitializeRequestInput = {
  operationType: CodeAssociationInput;
  quotationPreset: AggregateAssociationInput;
};

export type QuotationPresetWorkMatchingProcessInitializeResponse = CommonResponse & {
  __typename?: "QuotationPresetWorkMatchingProcessInitializeResponse";
  quotationPreset: QuotationPresetGqlModel;
  succeed: Scalars["Boolean"];
};

export type QuotationPresetWorkPriceUpdateRequestInput = {
  compatibilityPay?: InputMaybe<CompatibilityPaySpecInput>;
  operationType: CodeAssociationInput;
  quotationPreset: AggregateAssociationInput;
  workPrice?: InputMaybe<WorkPriceSpecInput>;
};

export type QuotationPresetWorkPriceUpdateResponse = CommonResponse & {
  __typename?: "QuotationPresetWorkPriceUpdateResponse";
  quotationPreset: QuotationPresetGqlModel;
  succeed: Scalars["Boolean"];
};

export type QuotationPresetWorkerNotAssignmentUpdateRequestInput = {
  notAssigned: Scalars["Boolean"];
  operationType: CodeAssociationInput;
  quotationPreset: AggregateAssociationInput;
};

export type QuotationPresetWorkerNotAssignmentUpdateResponse = CommonResponse & {
  __typename?: "QuotationPresetWorkerNotAssignmentUpdateResponse";
  quotationPreset: QuotationPresetGqlModel;
  succeed: Scalars["Boolean"];
};

export type QuotationPriceGqlModel = {
  __typename?: "QuotationPriceGqlModel";
  finalizedPrice?: Maybe<ProductPriceGqlModel>;
  finalizedPriceMemo?: Maybe<Scalars["String"]>;
  proposedPrice?: Maybe<ProductPriceGqlModel>;
  proposedPriceMemo?: Maybe<Scalars["String"]>;
};

export type QuotationPricePresetGqlModel = {
  __typename?: "QuotationPricePresetGqlModel";
  operationType: CodeAssociation;
  price?: Maybe<WorkPriceGqlModel>;
};

export type QuotationProductGqlModel = {
  __typename?: "QuotationProductGqlModel";
  bid: Scalars["String"];
  createdBy: Auth;
  createdDate: Scalars["OffsetDateTime"];
  customerAcceptance?: Maybe<AcceptanceStatus>;
  delivery?: Maybe<QuotationDelivery>;
  difficultyEvaluation?: Maybe<QuotationDifficultyEvaluation>;
  dropped: Scalars["Boolean"];
  eventVersion: Scalars["Long"];
  id: Scalars["UUID"];
  manager: Auth;
  prefix: Scalars["String"];
  productPrice?: Maybe<QuotationPriceGqlModel>;
  productType: QuotationProductType;
  quotation: QuotationGqlModel;
  quotationPreset?: Maybe<QuotationPresetGqlModel>;
  referencesDirectory?: Maybe<DirectoryGqlModel>;
  schedule?: Maybe<QuotationSchedule>;
  status: QuotationStatus;
  totusTmsStatus: QuotationProductGqlStatus;
  version: Scalars["Long"];
  work: QuotationWork;
  workTypes: Array<QuotationWorkType>;
};

export type QuotationProductGqlModelBidArgs = {
  format?: InputMaybe<GqlBusinessIdFormat>;
};

export type QuotationProductGqlQuerySpecInput = {
  assignedMe?: InputMaybe<Scalars["Boolean"]>;
  assignedMeQuotationManager?: InputMaybe<Scalars["Boolean"]>;
  compatibilityClients?: InputMaybe<Array<Scalars["UUID"]>>;
  contents?: InputMaybe<Array<Scalars["UUID"]>>;
  customerAcceptance?: InputMaybe<AcceptanceStatus>;
  customers?: InputMaybe<Array<Scalars["UUID"]>>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  dropped?: InputMaybe<Scalars["Boolean"]>;
  ids?: InputMaybe<Array<Scalars["UUID"]>>;
  managers?: InputMaybe<Array<Scalars["UUID"]>>;
  productTypes?: InputMaybe<Array<QuotationProductType>>;
  quotationManagers?: InputMaybe<Array<Scalars["UUID"]>>;
  quotations?: InputMaybe<Array<Scalars["UUID"]>>;
  status?: InputMaybe<QuotationStatusInput>;
  totusTmsStatus?: InputMaybe<QuotationProductTotusAdmin>;
};

export enum QuotationProductGqlStatus {
  Completed = "COMPLETED",
  Confirming = "CONFIRMING",
  Delivered = "DELIVERED",
  Evaluating = "EVALUATING",
  Ready = "READY",
}

export type QuotationProductSpecInput = {
  delivery?: InputMaybe<ToonQuotationDeliveryInput>;
  manager: IdentifiableAssociationInput;
  references?: InputMaybe<FileSystemRestructureSpecInput>;
  schedule?: InputMaybe<ToonQuotationScheduleInput>;
  work: ToonQuotationWorkInput;
  workTypes: Array<QuotationWorkType>;
};

export enum QuotationProductTotusAdmin {
  Completed = "COMPLETED",
  Confirming = "CONFIRMING",
  Delivered = "DELIVERED",
  Evaluating = "EVALUATING",
  Ready = "READY",
}

export enum QuotationProductType {
  DocumentTranslation = "DOCUMENT_TRANSLATION",
  ToonTranslation = "TOON_TRANSLATION",
}

export type QuotationQuerySpecInput = {
  contents?: InputMaybe<Array<Scalars["UUID"]>>;
  customers?: InputMaybe<Array<Scalars["UUID"]>>;
  ids?: InputMaybe<Array<Scalars["UUID"]>>;
  managers?: InputMaybe<Array<Scalars["UUID"]>>;
  quotationRequests?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QuotationRequestContactRequestInput = {
  email: EmailInput;
  name: Scalars["String"];
  phone: PhoneInput;
};

export type QuotationRequestGqlModel = {
  __typename?: "QuotationRequestGqlModel";
  bid: Scalars["String"];
  completed: Scalars["Boolean"];
  createdBy: Auth;
  createdDate: Scalars["OffsetDateTime"];
  customer: CustomerGqlModel;
  estimatedProductPrice?: Maybe<ProductPriceGqlModel>;
  eventVersion: Scalars["Long"];
  id: Scalars["UUID"];
  prefix: Scalars["String"];
  productType: QuotationProductType;
  quotation?: Maybe<QuotationGqlModel>;
  requirement: QuotationRequestRequirement;
  source: QuotationRequestSourceGqlModel;
  version: Scalars["Long"];
  work: QuotationRequestWork;
  workload: Workload;
};

export type QuotationRequestGqlModelBidArgs = {
  format?: InputMaybe<GqlBusinessIdFormat>;
};

export type QuotationRequestGqlQuerySpecInput = {
  completed?: InputMaybe<Scalars["Boolean"]>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  customerSearchKeyword?: InputMaybe<Scalars["String"]>;
  customers?: InputMaybe<Array<Scalars["UUID"]>>;
  ids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type QuotationRequestRequirement = {
  __typename?: "QuotationRequestRequirement";
  expectedDeliveryDate?: Maybe<Scalars["OffsetDateTime"]>;
  notes?: Maybe<Scalars["String"]>;
};

export type QuotationRequestRequirementInput = {
  expectedDeliveryDate?: InputMaybe<Scalars["OffsetDateTime"]>;
  notes?: InputMaybe<Scalars["String"]>;
};

export type QuotationRequestSourceGqlModel =
  | DocumentQuotationRequestSourceGqlModel
  | ToonQuotationRequestSourceGqlModel;

export type QuotationRequestWork = DocumentQuotationRequestWork | ToonQuotationRequestWork;

export type QuotationSchedule = DocumentQuotationSchedule | ToonQuotationSchedule;

export type QuotationStatus = {
  __typename?: "QuotationStatus";
  difficultyEvaluation?: Maybe<ProcessStatus>;
  managerConfirmation?: Maybe<ProcessStatus>;
  quotationDelivery?: Maybe<ProcessStatus>;
  workerPreMatching?: Maybe<ProcessStatus>;
};

export type QuotationStatusInput = {
  difficultyEvaluation?: InputMaybe<ProcessStatus>;
  managerConfirmation?: InputMaybe<ProcessStatus>;
  quotationDelivery?: InputMaybe<ProcessStatus>;
  workerPreMatching?: InputMaybe<ProcessStatus>;
};

export type QuotationWork = DocumentQuotationWork | ToonQuotationWork;

export enum QuotationWorkType {
  DocumentTranslation = "DOCUMENT_TRANSLATION",
  ToonTranslation = "TOON_TRANSLATION",
  ToonTypesetting = "TOON_TYPESETTING",
}

export type QuotationWorkerPresetGqlModel = {
  __typename?: "QuotationWorkerPresetGqlModel";
  matchingProcess?: Maybe<WorkerMatchingProcessGqlModel>;
  notAssigned: Scalars["Boolean"];
  operationType: CodeAssociation;
};

export type QuotationWorkerProposalGqlModel = {
  __typename?: "QuotationWorkerProposalGqlModel";
  quotationProduct: QuotationProductGqlModel;
  workPrice: QuotationPricePresetGqlModel;
  worker: QuotationWorkerPresetGqlModel;
};

export type RangeSchedule = {
  __typename?: "RangeSchedule";
  daySchedules: Array<DaySchedule>;
};

export type RecommendCode = BaseAggregateRoot & {
  __typename?: "RecommendCode";
  bid: Scalars["String"];
  code: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  expired: Scalars["Boolean"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  recommendCodeUseHistories?: Maybe<Array<RecommendCodeUseHistory>>;
  recommender: Auth;
  uuid: Scalars["UUID"];
};

export type RecommendCodePageResponse = {
  __typename?: "RecommendCodePageResponse";
  content: Array<RecommendCode>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export enum RecommendCodePromotionType {
  Basic = "BASIC",
  None = "NONE",
  Vital = "VITAL",
}

export type RecommendCodeQueryRequestInput = {
  code?: InputMaybe<Scalars["String"]>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  expired?: InputMaybe<Scalars["Boolean"]>;
  recommenders?: InputMaybe<Array<Scalars["UUID"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type RecommendCodeUseHistory = BaseAggregateRoot & {
  __typename?: "RecommendCodeUseHistory";
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  recommendCode: RecommendCode;
  recommendCodeUuid: Scalars["UUID"];
  recommendee: Auth;
  uuid: Scalars["UUID"];
};

export type RecommendCodeUseHistoryPageResponse = {
  __typename?: "RecommendCodeUseHistoryPageResponse";
  content: Array<RecommendCodeUseHistory>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type RecommendCodeUseHistoryQueryRequestInput = {
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  recommendCodeUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  recommendees?: InputMaybe<Array<Scalars["UUID"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type RecruitmentEvaluationItem = BaseAggregateRoot &
  TranslatableAggregate & {
    __typename?: "RecruitmentEvaluationItem";
    added: Scalars["Boolean"];
    bid: Scalars["String"];
    contentTypeCode: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    deleted: Scalars["Boolean"];
    description?: Maybe<Scalars["String"]>;
    descriptionComment?: Maybe<Scalars["String"]>;
    index?: Maybe<Scalars["Int"]>;
    itemComment: Scalars["String"];
    itemPurpose: RecruitmentEvaluationItemPurpose;
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
    recruitmentEvaluationOptions: Array<RecruitmentEvaluationOption>;
    skillTypeCode: Scalars["String"];
    sourceLanguageCode?: Maybe<Scalars["String"]>;
    tag: Scalars["String"];
    targetLanguageCode?: Maybe<Scalars["String"]>;
    uuid: Scalars["UUID"];
    weighted: Scalars["Boolean"];
  };

export type RecruitmentEvaluationItemCriteriaInput = {
  added?: InputMaybe<Scalars["Boolean"]>;
  contentTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  itemPurposes?: InputMaybe<Array<RecruitmentEvaluationItemPurpose>>;
  skillTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  sourceLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
  targetLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
  weighted?: InputMaybe<Scalars["Boolean"]>;
};

export type RecruitmentEvaluationItemPageResponse = {
  __typename?: "RecruitmentEvaluationItemPageResponse";
  content: Array<RecruitmentEvaluationItem>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  sort: RecruitmentEvaluationItemPageResponse;
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type RecruitmentEvaluationItemPageResponseSortArgs = {
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export enum RecruitmentEvaluationItemPurpose {
  Application = "APPLICATION",
  ApplicationTestProcess = "APPLICATION_TEST_PROCESS",
  NormalTask = "NORMAL_TASK",
  PocEvaluationExpert = "POC_EVALUATION_EXPERT",
  PocEvaluationNormal = "POC_EVALUATION_NORMAL",
}

export type RecruitmentEvaluationItemSnapshot = BaseAggregateRoot & {
  __typename?: "RecruitmentEvaluationItemSnapshot";
  added: Scalars["Boolean"];
  bid: Scalars["String"];
  contentTypeCode: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  descriptionComment?: Maybe<Scalars["String"]>;
  itemComment: Scalars["String"];
  itemPurpose: RecruitmentEvaluationItemPurpose;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  recruitmentEvaluationOptionSnapshots: Array<RecruitmentEvaluationOptionSnapshot>;
  recruitmentEvaluationResult: RecruitmentEvaluationResult;
  recruitmentEvaluationResultUuid: Scalars["UUID"];
  skillTypeCode: Scalars["String"];
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  tag: Scalars["String"];
  targetLanguageCode?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
  weighted: Scalars["Boolean"];
};

export type RecruitmentEvaluationItemSnapshotCriteriaInput = {
  added?: InputMaybe<Scalars["Boolean"]>;
  contentTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  itemPurposes?: InputMaybe<Array<RecruitmentEvaluationItemPurpose>>;
  recruitmentEvaluationResultUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  skillTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  sourceLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
  targetLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
  weighted?: InputMaybe<Scalars["Boolean"]>;
};

export type RecruitmentEvaluationItemSnapshotPageResponse = {
  __typename?: "RecruitmentEvaluationItemSnapshotPageResponse";
  content: Array<RecruitmentEvaluationItemSnapshot>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  sort: RecruitmentEvaluationItemSnapshotPageResponse;
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type RecruitmentEvaluationItemSnapshotPageResponseSortArgs = {
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type RecruitmentEvaluationOption = BaseAggregateRoot &
  TranslatableAggregate & {
    __typename?: "RecruitmentEvaluationOption";
    bid: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    descriptionComment: Scalars["String"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
    normalScore: Scalars["Int"];
    recruitmentEvaluationItem: RecruitmentEvaluationItem;
    recruitmentEvaluationItemUuid: Scalars["UUID"];
    uuid: Scalars["UUID"];
    version: Scalars["Int"];
    weightedScore: Scalars["Int"];
  };

export type RecruitmentEvaluationOptionCriteriaInput = {
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  descriptionComments?: InputMaybe<Array<Scalars["String"]>>;
  recruitmentEvaluationItemUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
  versions?: InputMaybe<Array<Scalars["Int"]>>;
};

export type RecruitmentEvaluationOptionPageResponse = {
  __typename?: "RecruitmentEvaluationOptionPageResponse";
  content: Array<RecruitmentEvaluationOption>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  sort: RecruitmentEvaluationOptionPageResponse;
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type RecruitmentEvaluationOptionPageResponseSortArgs = {
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type RecruitmentEvaluationOptionSnapshot = BaseAggregateRoot & {
  __typename?: "RecruitmentEvaluationOptionSnapshot";
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  descriptionComment: Scalars["String"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  normalScore: Scalars["Int"];
  recruitmentEvaluationItemSnapshot: RecruitmentEvaluationItemSnapshot;
  recruitmentEvaluationItemSnapshotUuid: Scalars["UUID"];
  selected: Scalars["Boolean"];
  uuid: Scalars["UUID"];
  version: Scalars["Int"];
  weightedScore: Scalars["Int"];
};

export type RecruitmentEvaluationOptionSnapshotCriteriaInput = {
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  descriptionComments?: InputMaybe<Array<Scalars["String"]>>;
  recruitmentEvaluationItemSnapshotUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  selected?: InputMaybe<Scalars["Boolean"]>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
  versions?: InputMaybe<Array<Scalars["Int"]>>;
};

export type RecruitmentEvaluationOptionSnapshotPageResponse = {
  __typename?: "RecruitmentEvaluationOptionSnapshotPageResponse";
  content: Array<RecruitmentEvaluationOptionSnapshot>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  sort: RecruitmentEvaluationOptionSnapshotPageResponse;
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type RecruitmentEvaluationOptionSnapshotPageResponseSortArgs = {
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type RecruitmentEvaluationResult = BaseAggregateRoot & {
  __typename?: "RecruitmentEvaluationResult";
  applicationTestProcess?: Maybe<ApplicationTestProcess>;
  applicationTestProcessUuid?: Maybe<Scalars["UUID"]>;
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  comment?: Maybe<Scalars["String"]>;
  contentTypeCode: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  evaluatedBy: Auth;
  evaluationTargetTask?: Maybe<Task>;
  evaluationTargetTaskUuid?: Maybe<Scalars["UUID"]>;
  grade: EvaluationGrade;
  itemPurpose: RecruitmentEvaluationItemPurpose;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  pass: Scalars["Boolean"];
  recruitmentEvaluationItemSnapshots: Array<RecruitmentEvaluationItemSnapshot>;
  recruitmentEvaluationStandardVersion?: Maybe<Scalars["Int"]>;
  recruitmentEvaluationStandards: Array<RecruitmentEvaluationStandard>;
  score?: Maybe<Scalars["Int"]>;
  skillTypeCode: Scalars["String"];
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  task?: Maybe<Task>;
  taskUuid?: Maybe<Scalars["UUID"]>;
  testEvaluation?: Maybe<TestEvaluation>;
  testEvaluationUuid?: Maybe<Scalars["UUID"]>;
  uuid: Scalars["UUID"];
};

export type RecruitmentEvaluationResultCriteriaInput = {
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  contentTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  evaluatedBy?: InputMaybe<Array<Scalars["UUID"]>>;
  evaluationTargetTaskUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  itemPurposes?: InputMaybe<Array<RecruitmentEvaluationItemPurpose>>;
  pass?: InputMaybe<Scalars["Boolean"]>;
  skillTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  sourceLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  targetLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  taskUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  testEvaluationUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type RecruitmentEvaluationResultGroupQueryRequestInput = {
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  contentTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  itemPurposes?: InputMaybe<Array<RecruitmentEvaluationItemPurpose>>;
  skillTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  sourceLanguageCodeOrTargetLanguageCode?: InputMaybe<Scalars["String"]>;
};

export type RecruitmentEvaluationResultGrouped = {
  __typename?: "RecruitmentEvaluationResultGrouped";
  contentTypeCode?: Maybe<Scalars["String"]>;
  count: Scalars["Long"];
  recruitmentEvaluationResults: Array<RecruitmentEvaluationResult>;
  skillTypeCode?: Maybe<Scalars["String"]>;
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  uuids: Array<Scalars["UUID"]>;
};

export type RecruitmentEvaluationResultGroupedRecruitmentEvaluationResultsArgs = {
  sort?: InputMaybe<Array<SortInput>>;
};

export type RecruitmentEvaluationResultGroupedPageResponse = {
  __typename?: "RecruitmentEvaluationResultGroupedPageResponse";
  content: Array<RecruitmentEvaluationResultGrouped>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type RecruitmentEvaluationResultPageResponse = {
  __typename?: "RecruitmentEvaluationResultPageResponse";
  content: Array<RecruitmentEvaluationResult>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  sort: RecruitmentEvaluationResultPageResponse;
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type RecruitmentEvaluationResultPageResponseSortArgs = {
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type RecruitmentEvaluationStandard = BaseAggregateRoot & {
  __typename?: "RecruitmentEvaluationStandard";
  bid: Scalars["String"];
  contentTypeCode: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  descriptionComment: Scalars["String"];
  highestScore: Scalars["Int"];
  itemPurpose: RecruitmentEvaluationItemPurpose;
  lowestScore: Scalars["Int"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  passed: Scalars["Boolean"];
  skillTypeCode: Scalars["String"];
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
  version: Scalars["Int"];
};

export type RecruitmentEvaluationStandardCriteriaInput = {
  contentTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  itemPurposes?: InputMaybe<Array<RecruitmentEvaluationItemPurpose>>;
  passed?: InputMaybe<Scalars["Boolean"]>;
  skillTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  sourceLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  targetLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
  versions?: InputMaybe<Array<Scalars["Int"]>>;
};

export type RecruitmentEvaluationStandardPageResponse = {
  __typename?: "RecruitmentEvaluationStandardPageResponse";
  content: Array<RecruitmentEvaluationStandard>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  sort: RecruitmentEvaluationStandardPageResponse;
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type RecruitmentEvaluationStandardPageResponseSortArgs = {
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type Reference = BaseAggregateRoot & {
  __typename?: "Reference";
  bid: Scalars["String"];
  createdBy?: Maybe<Auth>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deleted: Scalars["Boolean"];
  deletedBy?: Maybe<Auth>;
  job?: Maybe<Job>;
  jobProcess?: Maybe<JobProcess>;
  jobProcessUuid?: Maybe<Scalars["UUID"]>;
  jobUuid?: Maybe<Scalars["UUID"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name?: Maybe<Scalars["String"]>;
  operations: Array<Operation>;
  project?: Maybe<Project>;
  projectUuid?: Maybe<Scalars["UUID"]>;
  referenceTypeCode?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
  versions: Array<ReferenceVersion>;
};

export type ReferenceHistory = BaseAggregateRoot & {
  __typename?: "ReferenceHistory";
  action: ReferenceHistoryAction;
  auth?: Maybe<Auth>;
  authUuid?: Maybe<Scalars["UUID"]>;
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  files: Array<File>;
  links: Array<Link>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  reference: Reference;
  referenceName?: Maybe<Scalars["String"]>;
  referenceUuid: Scalars["UUID"];
  uuid: Scalars["UUID"];
  version: Scalars["Int"];
};

export enum ReferenceHistoryAction {
  Creation = "CREATION",
  Deletion = "DELETION",
  Modification = "MODIFICATION",
}

export type ReferenceHistoryCriteriaInput = {
  actions?: InputMaybe<Array<ReferenceHistoryAction>>;
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  projectUuid?: InputMaybe<Scalars["UUID"]>;
  referenceName?: InputMaybe<Scalars["String"]>;
  referenceUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type ReferenceHistoryPageResponse = {
  __typename?: "ReferenceHistoryPageResponse";
  content: Array<ReferenceHistory>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type ReferenceTypeCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "ReferenceTypeCode";
    code: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
  };

export type ReferenceVersion = {
  __typename?: "ReferenceVersion";
  createdBy?: Maybe<Auth>;
  createdDate: Scalars["OffsetDateTime"];
  fileUuids: Array<Scalars["UUID"]>;
  files: Array<File>;
  linkUuids: Array<Scalars["UUID"]>;
  links: Array<Link>;
  referenceUuid: Scalars["UUID"];
  version: Scalars["Int"];
};

export type RelayBacklotAccount = BaseAggregateRoot & {
  __typename?: "RelayBacklotAccount";
  company?: Maybe<RelayBacklotCompany>;
  companyId?: Maybe<Scalars["Int"]>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  email: Scalars["String"];
  id: Scalars["Int"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name: Scalars["String"];
};

export type RelayBacklotCompany = {
  __typename?: "RelayBacklotCompany";
  id: Scalars["Int"];
  manager?: Maybe<RelayBacklotAccount>;
  managerAccountId?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
};

export type RelayBacklotProject = {
  __typename?: "RelayBacklotProject";
  account?: Maybe<RelayBacklotAccount>;
  accountId?: Maybe<Scalars["Int"]>;
  category?: Maybe<RequestCategory>;
  createDateTime?: Maybe<Scalars["OffsetDateTime"]>;
  description?: Maybe<Scalars["String"]>;
  doneDateTime?: Maybe<Scalars["OffsetDateTime"]>;
  id: Scalars["Int"];
  manager?: Maybe<RelayBacklotAccount>;
  managerId?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  projectTransfer?: Maybe<ProjectTransfer>;
  registeredDateTime?: Maybe<Scalars["OffsetDateTime"]>;
  requestedDeadlineDateTime?: Maybe<Scalars["OffsetDateTime"]>;
  requestedStartDateTime?: Maybe<Scalars["OffsetDateTime"]>;
  scheduledDeadlineDateTime?: Maybe<Scalars["OffsetDateTime"]>;
  scheduledStartDateTime?: Maybe<Scalars["OffsetDateTime"]>;
  status: ProjectStatus;
  updateDateTime?: Maybe<Scalars["OffsetDateTime"]>;
};

export type RelayBacklotProjectPageResponse = {
  __typename?: "RelayBacklotProjectPageResponse";
  content: Array<RelayBacklotProject>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export enum RelayJobResultFileType {
  Image = "IMAGE",
  Other = "OTHER",
  Psd = "PSD",
  Srt = "SRT",
  Text = "TEXT",
  Video = "VIDEO",
}

export type RelayPanoplayJobResult = {
  __typename?: "RelayPanoplayJobResult";
  createDateTime: Scalars["OffsetDateTime"];
  id: Scalars["Int"];
  panoplayOrder: PanoplayOrder;
  relayJobResultFiles?: Maybe<Array<RelayPanoplayJobResultFile>>;
  requestedReviewDateTime?: Maybe<Scalars["OffsetDateTime"]>;
  requestedReviewDeadlineDateTime?: Maybe<Scalars["OffsetDateTime"]>;
  reviewed: Scalars["Boolean"];
  reviewedDateTime?: Maybe<Scalars["OffsetDateTime"]>;
  updateDateTime?: Maybe<Scalars["OffsetDateTime"]>;
};

export type RelayPanoplayJobResultFile = {
  __typename?: "RelayPanoplayJobResultFile";
  fileId: Scalars["String"];
  fileName?: Maybe<Scalars["String"]>;
  fileS3Path?: Maybe<Scalars["String"]>;
  fileUploadEndDateTime?: Maybe<Scalars["OffsetDateTime"]>;
  id: Scalars["Int"];
  index?: Maybe<Scalars["Int"]>;
  jobResultId: Scalars["Int"];
  previewS3Path?: Maybe<Scalars["String"]>;
  type?: Maybe<RelayJobResultFileType>;
};

export enum RequestCategory {
  Document = "DOCUMENT",
  Video = "VIDEO",
  Webnovel = "WEBNOVEL",
  Webtoon = "WEBTOON",
}

export type RequiredSubmissionState = {
  __typename?: "RequiredSubmissionState";
  depositSubmitted: Scalars["Boolean"];
  skillObtained: Scalars["Boolean"];
};

export type Resource = BaseAggregateRoot & {
  __typename?: "Resource";
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  job: Job;
  jobProcessUuid?: Maybe<Scalars["UUID"]>;
  jobUuid: Scalars["UUID"];
  languageCode?: Maybe<Scalars["String"]>;
  latestVersion?: Maybe<ResourceVersion>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  outputBy: Array<Operation>;
  preprocessings: Array<Preprocessing>;
  resourceConversions: Array<ResourceConversion>;
  resourceTypeCode: Scalars["String"];
  uuid: Scalars["UUID"];
  versions: Array<ResourceVersion>;
};

export type ResourceConversion = BaseAggregateRoot & {
  __typename?: "ResourceConversion";
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  editorResourceUuid?: Maybe<Scalars["UUID"]>;
  filePaths?: Maybe<Array<Scalars["String"]>>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  resource: Resource;
  resourceTypeCode: Scalars["String"];
  resourceUuid: Scalars["UUID"];
  state: ResourceConversionState;
  type: ResourceConversionType;
  uuid: Scalars["UUID"];
  version: Scalars["Int"];
};

export enum ResourceConversionState {
  Complete = "COMPLETE",
  ConversionDone = "CONVERSION_DONE",
  PostProcessError = "POST_PROCESS_ERROR",
  Processing = "PROCESSING",
  Registered = "REGISTERED",
  RequestError = "REQUEST_ERROR",
}

export enum ResourceConversionType {
  EditorResourceFileExtraction = "EDITOR_RESOURCE_FILE_EXTRACTION",
  Ocr = "OCR",
  PsdPreview = "PSD_PREVIEW",
  ShotChange = "SHOT_CHANGE",
  Srt = "SRT",
  VideoEngine = "VIDEO_ENGINE",
}

export enum ResourceType {
  Document = "DOCUMENT",
  Toon = "TOON",
  Video = "VIDEO",
}

export type ResourceTypeCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "ResourceTypeCode";
    code: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    editorResourceTypeCode?: Maybe<Scalars["String"]>;
    extensionGroupCode: Scalars["String"];
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
    original: Scalars["Boolean"];
  };

export type ResourceVersion = {
  __typename?: "ResourceVersion";
  createdBy?: Maybe<Auth>;
  createdDate: Scalars["OffsetDateTime"];
  editorResource?: Maybe<EditorResource>;
  editorResourceUuid?: Maybe<Scalars["UUID"]>;
  fileUuids: Array<Scalars["UUID"]>;
  files: Array<File>;
  resourceUuid: Scalars["UUID"];
  task?: Maybe<Task>;
  taskUuid?: Maybe<Scalars["UUID"]>;
  version: Scalars["Int"];
};

export type Result = BaseAggregateRoot & {
  __typename?: "Result";
  autoDelivery: Scalars["Boolean"];
  bid: Scalars["String"];
  createdBy: Scalars["UUID"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  origin: Origin;
  resource: Resource;
  resourceUuid: Scalars["UUID"];
  targetPanoplayOrder: PanoplayOrder;
  targetUuid: Scalars["UUID"];
  uuid: Scalars["UUID"];
  versions: Array<ResultVersion>;
};

export type ResultVersion = {
  __typename?: "ResultVersion";
  deliveredBy?: Maybe<Auth>;
  deliveredDate: Scalars["OffsetDateTime"];
  resultUuid: Scalars["UUID"];
  version: Scalars["Int"];
};

export enum Role {
  Evaluator = "EVALUATOR",
  Finance = "FINANCE",
  Lg = "LG",
  Master = "MASTER",
  PanoplayAdmin = "PANOPLAY_ADMIN",
  PanoplayClient = "PANOPLAY_CLIENT",
  Pm = "PM",
  Qa = "QA",
  Qm = "QM",
  SystemAdmin = "SYSTEM_ADMIN",
  Temporary = "TEMPORARY",
  TotusAdmin = "TOTUS_ADMIN",
  TotusWorker = "TOTUS_WORKER",
  User = "USER",
  Verified = "VERIFIED",
}

export type S3File = {
  __typename?: "S3File";
  name: Scalars["String"];
  s3Path: Scalars["String"];
  volume: Scalars["Int"];
};

export type S3ObjectGqlModel = {
  __typename?: "S3ObjectGqlModel";
  bucket: Scalars["String"];
  downloadUrl: Scalars["String"];
  key: Scalars["String"];
  s3ObjectUrl: Scalars["String"];
};

export type SequentialGroupProposalAlgorithmOption = {
  __typename?: "SequentialGroupProposalAlgorithmOption";
  groupSize: Scalars["Int"];
  periodMinutes: Scalars["Int"];
  targetAcceptanceCount: Scalars["Int"];
};

export enum SettlementType {
  ExamEvaluation = "EXAM_EVALUATION",
  Other = "OTHER",
  Task = "TASK",
}

export type Skill = BaseAggregateRoot & {
  __typename?: "Skill";
  actualWorking: Scalars["Boolean"];
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  comment?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<Auth>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  disabled: Scalars["Boolean"];
  firstHistory?: Maybe<SkillHistory>;
  grade?: Maybe<SkillGrade>;
  modifiedBy?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  onBoardingCompleted: Scalars["Boolean"];
  onBoardingCompletedBy?: Maybe<Auth>;
  onBoardingCompletedDate?: Maybe<Scalars["OffsetDateTime"]>;
  onBoardingConfirmed: Scalars["Boolean"];
  onBoardingConfirmedBy?: Maybe<Auth>;
  onBoardingConfirmedDate?: Maybe<Scalars["OffsetDateTime"]>;
  pocEvaluationExpert: Scalars["Boolean"];
  /** @deprecated As the POC concept expansion, TaskEvaluation is no longer required. */
  pocTaskEvaluations: Array<TaskEvaluation>;
  questionApplications: Array<QuestionApplication>;
  questionGroupsByPosition: Array<QuestionGroup>;
  skillHistories: Array<SkillHistory>;
  skillTypeCode: Scalars["String"];
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  state: SkillState;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  tasks: Array<Task>;
  uuid: Scalars["UUID"];
};

export type SkillTasksArgs = {
  deleted?: InputMaybe<Scalars["Boolean"]>;
  expertPocEvaluationRequested?: InputMaybe<Scalars["Boolean"]>;
  normalPocEvaluationRequested?: InputMaybe<Scalars["Boolean"]>;
  normalPocEvaluationStopped?: InputMaybe<Scalars["Boolean"]>;
  purposes?: InputMaybe<Array<TaskPurpose>>;
  resourceManagement?: InputMaybe<Scalars["Boolean"]>;
  states?: InputMaybe<Array<TaskState>>;
};

export type SkillCareer = BaseAggregateRoot & {
  __typename?: "SkillCareer";
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  contentTypeCode: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  endDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  skillCareerProjects: Array<SkillCareerProject>;
  skillTypeCode: Scalars["String"];
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  startDate: Scalars["OffsetDateTime"];
  targetLanguageCode?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
};

export type SkillCareerProject = BaseAggregateRoot & {
  __typename?: "SkillCareerProject";
  adult: Scalars["Boolean"];
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  blglGenre: Scalars["Boolean"];
  companyName: Scalars["String"];
  contentName: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  projectDescription: Scalars["String"];
  skillCareer: SkillCareer;
  skillCareerUuid: Scalars["UUID"];
  uuid: Scalars["UUID"];
};

export enum SkillGrade {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
}

export type SkillHistory = BaseAggregateRoot & {
  __typename?: "SkillHistory";
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  comment?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  grade?: Maybe<SkillGrade>;
  modifiedBy?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  onBoardingCompleted: Scalars["Boolean"];
  onBoardingConfirmed: Scalars["Boolean"];
  skill: Skill;
  skillTypeCode: Scalars["String"];
  skillUuid: Scalars["UUID"];
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  state: SkillState;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
};

export type SkillHistoryCriteriaInput = {
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  comment?: InputMaybe<Scalars["String"]>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  grades?: InputMaybe<Array<SkillGrade>>;
  modifiedBy?: InputMaybe<Array<Scalars["UUID"]>>;
  onBoardingCompleted?: InputMaybe<Scalars["Boolean"]>;
  onBoardingConfirmed?: InputMaybe<Scalars["Boolean"]>;
  skillTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  skillUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  sourceLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  states?: InputMaybe<Array<SkillState>>;
  targetLanguageCodes?: InputMaybe<Array<Scalars["String"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type SkillHistoryPageResponse = {
  __typename?: "SkillHistoryPageResponse";
  content: Array<SkillHistory>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type SkillPageResponse = {
  __typename?: "SkillPageResponse";
  content: Array<Skill>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export enum SkillState {
  Beginner = "BEGINNER",
  OnBoardingCompleted = "ON_BOARDING_COMPLETED",
  OnBoardingConfirmed = "ON_BOARDING_CONFIRMED",
  Working = "WORKING",
}

export type SkillTypeCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "SkillTypeCode";
    code: Scalars["String"];
    contentTypeCode: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    evaluationTargetSkillTypeCode?: Maybe<Scalars["String"]>;
    index: Scalars["Int"];
    languageOption: LanguageOption;
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
    operationTypeCodes: Array<Scalars["String"]>;
  };

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type SortInput = {
  direction?: InputMaybe<SortDirection>;
  property: Scalars["String"];
};

export type SourceGqlModelInput = {
  files: Array<AggregateAssociationInput>;
  type: DocumentType;
};

export type SubmissionFile = BaseAggregateRoot & {
  __typename?: "SubmissionFile";
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name: Scalars["String"];
  s3Path: Scalars["String"];
  volume?: Maybe<Scalars["Int"]>;
};

export type SubmissionLink = BaseAggregateRoot & {
  __typename?: "SubmissionLink";
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name: Scalars["String"];
  path: Scalars["String"];
};

export enum SubmissionType {
  Editor = "EDITOR",
  File = "FILE",
}

export type SuccessResponse = CommonResponse & {
  __typename?: "SuccessResponse";
  succeed: Scalars["Boolean"];
};

export type Tag = BaseAggregateRoot &
  TranslatableAggregate & {
    __typename?: "Tag";
    bid: Scalars["String"];
    contentTypeCode: Scalars["String"];
    createdBy?: Maybe<Scalars["UUID"]>;
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    deleted: Scalars["Boolean"];
    description?: Maybe<Scalars["String"]>;
    feedbackAttributes: Array<FeedbackTagAttribute>;
    index?: Maybe<Scalars["Int"]>;
    modifiedBy?: Maybe<Scalars["UUID"]>;
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
    parentUuid?: Maybe<Scalars["UUID"]>;
    uuid: Scalars["UUID"];
  };

export type TagCriteriaInput = {
  contentTypeCodes?: InputMaybe<Array<Scalars["String"]>>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  parentUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type TagGroupCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "TagGroupCode";
    code: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
  };

export type TagPageResponse = {
  __typename?: "TagPageResponse";
  content: Array<Tag>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type TargetSettlement = BaseAggregateRoot & {
  __typename?: "TargetSettlement";
  additional: Scalars["Boolean"];
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  canceledDate?: Maybe<Scalars["OffsetDateTime"]>;
  contentTypeCode?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<Auth>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  currencyCode: Scalars["String"];
  fixedDate?: Maybe<Scalars["OffsetDateTime"]>;
  fixedSettlement?: Maybe<FixedSettlement>;
  holdDate?: Maybe<Scalars["OffsetDateTime"]>;
  manual: Scalars["Boolean"];
  modifiedBy?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  payValue: Scalars["Float"];
  paymentReason?: Maybe<Scalars["String"]>;
  requestedDate?: Maybe<Scalars["OffsetDateTime"]>;
  state: TargetSettlementState;
  task: Task;
  taskUuid?: Maybe<Scalars["UUID"]>;
  type: SettlementType;
  uuid: Scalars["UUID"];
};

export type TargetSettlementGroupQueryRequestInput = {
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  requestedDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  requestedDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  types?: InputMaybe<Array<SettlementType>>;
};

export type TargetSettlementGrouped = {
  __typename?: "TargetSettlementGrouped";
  auth: Auth;
  authUuid: Scalars["UUID"];
  canceledCount: Scalars["Int"];
  canceledPayValue: Scalars["Float"];
  count: Scalars["Int"];
  currencyCode: Scalars["String"];
  holdCount: Scalars["Int"];
  holdPayValue: Scalars["Float"];
  requestedCount: Scalars["Int"];
  requestedPayValue: Scalars["Float"];
  targetSettlements: Array<TargetSettlement>;
  totalPayValue: Scalars["Float"];
  uuids: Array<Scalars["UUID"]>;
};

export type TargetSettlementGroupedTargetSettlementsArgs = {
  sort?: InputMaybe<Array<SortInput>>;
};

export type TargetSettlementGroupedPageResponse = {
  __typename?: "TargetSettlementGroupedPageResponse";
  content: Array<TargetSettlementGrouped>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type TargetSettlementPageResponse = {
  __typename?: "TargetSettlementPageResponse";
  content: Array<TargetSettlement>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type TargetSettlementQueryCriteriaInput = {
  additional?: InputMaybe<Scalars["Boolean"]>;
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  contentTypeCode?: InputMaybe<Scalars["String"]>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
  greaterThanPayValue?: InputMaybe<Scalars["Float"]>;
  jobTypeCode?: InputMaybe<Scalars["String"]>;
  jobUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  managerUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  manual?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  operationTypeCode?: InputMaybe<Scalars["String"]>;
  operationUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  projectUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  requestedDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  requestedDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  sourceLanguageCode?: InputMaybe<Scalars["String"]>;
  states?: InputMaybe<Array<TargetSettlementState>>;
  targetLanguageCode?: InputMaybe<Scalars["String"]>;
  taskGroupUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  taskUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  types?: InputMaybe<Array<SettlementType>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
  workerUuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export enum TargetSettlementState {
  Canceled = "CANCELED",
  Fixed = "FIXED",
  Hold = "HOLD",
  Requested = "REQUESTED",
}

export type Task = BaseAggregateRoot & {
  __typename?: "Task";
  applicationTestProcess?: Maybe<ApplicationTestProcess>;
  applicationTestProcessUuid?: Maybe<Scalars["UUID"]>;
  assignee?: Maybe<Auth>;
  bid: Scalars["String"];
  comments: Array<TaskComment>;
  confirmedDate?: Maybe<Scalars["OffsetDateTime"]>;
  createdBy?: Maybe<Auth>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  creationReason?: Maybe<TaskCreationReason>;
  deleted: Scalars["Boolean"];
  deliveredDate?: Maybe<Scalars["OffsetDateTime"]>;
  deliveries: Array<Delivery>;
  endDate?: Maybe<Scalars["OffsetDateTime"]>;
  evaluationTargetNormalTaskByCheckNormalTask?: Maybe<Task>;
  evaluationTargetTask?: Maybe<Task>;
  evaluationTargetTaskUuid?: Maybe<Scalars["UUID"]>;
  expertPocEvaluationRequested: Scalars["Boolean"];
  job?: Maybe<Job>;
  jobUuid?: Maybe<Scalars["UUID"]>;
  modifiedBy?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  nextState?: Maybe<TaskState>;
  normalPocEvaluationRequested: Scalars["Boolean"];
  normalPocEvaluationStopped: Scalars["Boolean"];
  normalTaskRecruitmentEvaluationItems: Array<RecruitmentEvaluationItem>;
  operation: Operation;
  operationTypeCode?: Maybe<Scalars["String"]>;
  operationUuid?: Maybe<Scalars["UUID"]>;
  payInfo?: Maybe<PayInfo>;
  pocEvaluationTasks: Array<Task>;
  positionTestProcess?: Maybe<PositionTestProcess>;
  positionTestProcessUuid?: Maybe<Scalars["UUID"]>;
  previousTaskUuids: Array<Scalars["UUID"]>;
  previousTasks?: Maybe<Array<Task>>;
  projectUuid?: Maybe<Scalars["UUID"]>;
  purpose: TaskPurpose;
  qcLogs: Array<QcLog>;
  recruitmentEvaluationItems: Array<RecruitmentEvaluationItem>;
  recruitmentEvaluationResult: Array<RecruitmentEvaluationResult>;
  relatedJobProcessComments: Array<Comment>;
  relatedJobProcessPointers: Array<UnitPointer>;
  relatedProjectFeedbacks: Array<Comment>;
  resourceManagement: Scalars["Boolean"];
  retaken: Scalars["Boolean"];
  skip: Scalars["Boolean"];
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  sourceTask?: Maybe<Task>;
  sourceTaskUuid?: Maybe<Scalars["UUID"]>;
  startDate?: Maybe<Scalars["OffsetDateTime"]>;
  state: TaskState;
  svgUnitPointers: Array<UnitPointer>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  targetSettlements: Array<TargetSettlement>;
  taskEvaluation?: Maybe<TaskEvaluation>;
  taskGroup?: Maybe<TaskGroup>;
  taskGroupUuid?: Maybe<Scalars["UUID"]>;
  taskReports: Array<TaskReport>;
  type: TaskType;
  /** @deprecated move to svg pointer */
  unitPointers: Array<UnitPointer>;
  uuid: Scalars["UUID"];
  work: Work;
  workUuid?: Maybe<Scalars["UUID"]>;
  workerAccepted?: Maybe<Scalars["Boolean"]>;
};

export type TaskNormalTaskRecruitmentEvaluationItemsArgs = {
  deleted?: InputMaybe<Scalars["Boolean"]>;
};

export type TaskPocEvaluationTasksArgs = {
  assignees?: InputMaybe<Array<Scalars["UUID"]>>;
  purposes?: InputMaybe<Array<TaskPurpose>>;
  states?: InputMaybe<Array<TaskState>>;
};

export type TaskRecruitmentEvaluationItemsArgs = {
  deleted?: InputMaybe<Scalars["Boolean"]>;
};

export type TaskComment = {
  __typename?: "TaskComment";
  attachments: Array<CommentAttachment>;
  checked: Scalars["Boolean"];
  clientChecked?: Maybe<Scalars["Boolean"]>;
  content?: Maybe<Scalars["String"]>;
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deleted: Scalars["Boolean"];
  deletedBy: Auth;
  important: Scalars["Boolean"];
  jobProcessUuid?: Maybe<Scalars["UUID"]>;
  jobUuid?: Maybe<Scalars["UUID"]>;
  modifiedBy: Auth;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  operationTypeCode?: Maybe<Scalars["String"]>;
  operationUuid?: Maybe<Scalars["UUID"]>;
  projectTypeCode?: Maybe<Scalars["String"]>;
  projectUuid?: Maybe<Scalars["UUID"]>;
  resolved: Scalars["Boolean"];
  scope: CommentScope;
  tagUuids: Array<Scalars["UUID"]>;
  tags: Array<Tag>;
  task: Task;
  taskUuid?: Maybe<Scalars["UUID"]>;
  todos: Array<CommentTodo>;
  type: CommentType;
  unitPointerGroups: Array<TaskUnitPointerGroup>;
  uuid: Scalars["UUID"];
};

export type TaskCount = {
  __typename?: "TaskCount";
  canceledCount: Scalars["Int"];
  canceledDropCompletedCount: Scalars["Int"];
  completedCount: Scalars["Int"];
  confirmedCount: Scalars["Int"];
  deliveredCount: Scalars["Int"];
  dropCount: Scalars["Int"];
  preparingCount: Scalars["Int"];
  preparingReadyCount: Scalars["Int"];
  processingCount: Scalars["Int"];
  readyCount: Scalars["Int"];
  total: Scalars["Int"];
};

export enum TaskCreationReason {
  Etc = "ETC",
  Inspection = "INSPECTION",
  Retake = "RETAKE",
  Settlement = "SETTLEMENT",
  WorkerRequest = "WORKER_REQUEST",
}

export type TaskCriteriaInput = {
  adult?: InputMaybe<Scalars["Boolean"]>;
  applicationTestProcessUuidIsNull?: InputMaybe<Scalars["Boolean"]>;
  applicationTestProcessUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  assignees?: InputMaybe<Array<Scalars["UUID"]>>;
  blglGenre?: InputMaybe<Scalars["Boolean"]>;
  clientUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  complementAssignees?: InputMaybe<Array<Scalars["UUID"]>>;
  complementUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  complementUuidsOrComplementAssignees?: InputMaybe<Scalars["Boolean"]>;
  contentTypeCode?: InputMaybe<Scalars["String"]>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  disableEndDate?: InputMaybe<Scalars["Boolean"]>;
  dueDateIsNull?: InputMaybe<Scalars["Boolean"]>;
  endDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  endDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  evaluationTargetTaskUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  expertPocEvaluationRequested?: InputMaybe<Scalars["Boolean"]>;
  expertPocEvaluationResultExistedPOCTask?: InputMaybe<Scalars["Boolean"]>;
  expertPocEvaluationResultPassed?: InputMaybe<Scalars["Boolean"]>;
  genreCode?: InputMaybe<Scalars["String"]>;
  jobDisabled?: InputMaybe<Scalars["Boolean"]>;
  jobRecommended?: InputMaybe<Scalars["Boolean"]>;
  jobTypeCode?: InputMaybe<Scalars["String"]>;
  jobUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  managerUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  normalPocEvaluationRequested?: InputMaybe<Scalars["Boolean"]>;
  normalPocEvaluationResultExistedPOCTask?: InputMaybe<Scalars["Boolean"]>;
  normalPocEvaluationStopped?: InputMaybe<Scalars["Boolean"]>;
  notAssignedOnly?: InputMaybe<Scalars["Boolean"]>;
  operationTypeCode?: InputMaybe<Scalars["String"]>;
  operationUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  payIsNull?: InputMaybe<Scalars["Boolean"]>;
  positionTestProcessUuidIsNull?: InputMaybe<Scalars["Boolean"]>;
  positionTestProcessUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  projectUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  purposes?: InputMaybe<Array<TaskPurpose>>;
  resourceManagement?: InputMaybe<Scalars["Boolean"]>;
  retaken?: InputMaybe<Scalars["Boolean"]>;
  skillTypeCode?: InputMaybe<Scalars["String"]>;
  skillUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  skip?: InputMaybe<Scalars["Boolean"]>;
  sourceLanguageCode?: InputMaybe<Scalars["String"]>;
  sourceLanguageCodesOrTargetLanguageCodes?: InputMaybe<Scalars["Boolean"]>;
  startDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  startDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  states?: InputMaybe<Array<TaskState>>;
  targetLanguageCode?: InputMaybe<Scalars["String"]>;
  taskGroupUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
  workerUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  workingPeriodEndDate?: InputMaybe<Scalars["OffsetDateTime"]>;
  workingPeriodStartDate?: InputMaybe<Scalars["OffsetDateTime"]>;
};

export type TaskEvaluation = BaseAggregateRoot & {
  __typename?: "TaskEvaluation";
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  comment?: Maybe<Scalars["String"]>;
  contentTypeCode?: Maybe<Scalars["String"]>;
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  evaluatedBy: Auth;
  evaluatedDate?: Maybe<Scalars["OffsetDateTime"]>;
  genreCode?: Maybe<Scalars["String"]>;
  modifiedBy?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  operationTypeCode: Scalars["String"];
  recommended?: Maybe<Scalars["Boolean"]>;
  skillTypeCode?: Maybe<Scalars["String"]>;
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  task: Task;
  taskPurpose: TaskPurpose;
  taskUuid: Scalars["UUID"];
  uuid: Scalars["UUID"];
  worker?: Maybe<Worker>;
  workerUuid?: Maybe<Scalars["UUID"]>;
};

export type TaskEvaluationCriteriaInput = {
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  comment?: InputMaybe<Scalars["String"]>;
  contentTypeCode?: InputMaybe<Scalars["String"]>;
  createdBy?: InputMaybe<Array<Scalars["UUID"]>>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  disableRecommended?: InputMaybe<Scalars["Boolean"]>;
  evaluatedBy?: InputMaybe<Array<Scalars["UUID"]>>;
  evaluatedDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  evaluatedDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  genreCode?: InputMaybe<Scalars["String"]>;
  modifiedBy?: InputMaybe<Array<Scalars["UUID"]>>;
  operationTypeCode?: InputMaybe<Scalars["String"]>;
  recommended?: InputMaybe<Scalars["Boolean"]>;
  skillTypeCode?: InputMaybe<Scalars["String"]>;
  sourceLanguageCode?: InputMaybe<Scalars["String"]>;
  targetLanguageCode?: InputMaybe<Scalars["String"]>;
  taskGroupUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  taskPurposes?: InputMaybe<Array<TaskPurpose>>;
  taskStates?: InputMaybe<Array<TaskState>>;
  taskUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
  workerUuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type TaskEvaluationPageResponse = {
  __typename?: "TaskEvaluationPageResponse";
  content: Array<TaskEvaluation>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type TaskEvaluationRecommendCount = {
  __typename?: "TaskEvaluationRecommendCount";
  beforeEvaluationCount: Scalars["Int"];
  notRecommendedCount: Scalars["Int"];
  recommendedCount: Scalars["Int"];
  total: Scalars["Int"];
};

export type TaskEvaluationTaskStateCount = {
  __typename?: "TaskEvaluationTaskStateCount";
  pocTaskEvaluationTaskCount: PocTaskEvaluationTaskCount;
};

export type TaskGroup = BaseAggregateRoot & {
  __typename?: "TaskGroup";
  bid: Scalars["String"];
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name?: Maybe<Scalars["String"]>;
  operationTypeCode?: Maybe<Scalars["String"]>;
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  targetLastRepliedDate?: Maybe<Scalars["OffsetDateTime"]>;
  taskGroupType?: Maybe<TaskGroupType>;
  uuid: Scalars["UUID"];
  worker: Worker;
  workerUuid: Scalars["UUID"];
};

export type TaskGroupPageResponse = {
  __typename?: "TaskGroupPageResponse";
  content: Array<TaskGroup>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export enum TaskGroupType {
  DirectAssign = "DIRECT_ASSIGN",
  Normal = "NORMAL",
}

export type TaskPageResponse = {
  __typename?: "TaskPageResponse";
  content: Array<Task>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export enum TaskPresetType {
  ToonNormalWorkerInitialTaskPreset = "TOON_NORMAL_WORKER_INITIAL_TASK_PRESET",
  ToonVendorInitialTaskPreset = "TOON_VENDOR_INITIAL_TASK_PRESET",
}

export enum TaskPurpose {
  Exam = "EXAM",
  ExamEvaluation = "EXAM_EVALUATION",
  HermesMigration = "HERMES_MIGRATION",
  LiveTranslation = "LIVE_TRANSLATION",
  Normal = "NORMAL",
  Poc = "POC",
  PocEvaluationExpert = "POC_EVALUATION_EXPERT",
  PocEvaluationNormal = "POC_EVALUATION_NORMAL",
  PocEvaluationPreset = "POC_EVALUATION_PRESET",
}

export type TaskReport = BaseAggregateRoot & {
  __typename?: "TaskReport";
  bid: Scalars["String"];
  comment?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  reasons: Array<TaskReportReason>;
  reportedBy?: Maybe<Auth>;
  task: Task;
  taskPurpose: TaskPurpose;
  taskUuid: Scalars["UUID"];
  uuid: Scalars["UUID"];
};

export type TaskReportCriteriaInput = {
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  reportedBy?: InputMaybe<Array<Scalars["UUID"]>>;
  taskPurposes?: InputMaybe<Array<TaskPurpose>>;
  taskUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type TaskReportPageResponse = {
  __typename?: "TaskReportPageResponse";
  content: Array<TaskReport>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  sort: TaskReportPageResponse;
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type TaskReportPageResponseSortArgs = {
  uuids?: InputMaybe<Array<Scalars["UUID"]>>;
};

export enum TaskReportReason {
  CommercialPurpose = "COMMERCIAL_PURPOSE",
  IrrelevantDelivery = "IRRELEVANT_DELIVERY",
  NotMatchedLanguageSet = "NOT_MATCHED_LANGUAGE_SET",
  Other = "OTHER",
  PersonalInformationDisclosure = "PERSONAL_INFORMATION_DISCLOSURE",
  SuspectionUsingTranslator = "SUSPECTION_USING_TRANSLATOR",
  Vulgarism = "VULGARISM",
}

export enum TaskState {
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  Confirmed = "CONFIRMED",
  Delivered = "DELIVERED",
  Drop = "DROP",
  Preparing = "PREPARING",
  Processing = "PROCESSING",
  Ready = "READY",
}

export type TaskStateCount = {
  __typename?: "TaskStateCount";
  taskCount: TaskCount;
};

export enum TaskType {
  InternalWorker = "INTERNAL_WORKER",
  Normal = "NORMAL",
  NoPay = "NO_PAY",
}

export type TaskUnitPointerGroup = {
  __typename?: "TaskUnitPointerGroup";
  checked: Scalars["Boolean"];
  comment?: Maybe<Comment>;
  createdBy: Scalars["UUID"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedBy: Scalars["UUID"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  unitPointers: Array<UnitPointer>;
  uuid: Scalars["UUID"];
};

export type TermAgreement = BaseAggregateRoot & {
  __typename?: "TermAgreement";
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  marketing: Array<MarketingType>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  uuid: Scalars["UUID"];
};

export type TermAgreementPageResponse = {
  __typename?: "TermAgreementPageResponse";
  content: Array<TermAgreement>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type Test = BaseAggregateRoot & {
  __typename?: "Test";
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deadlineDate?: Maybe<Scalars["OffsetDateTime"]>;
  files: Array<SubmissionFile>;
  links: Array<SubmissionLink>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  startedDate?: Maybe<Scalars["OffsetDateTime"]>;
  state: TestState;
  submissionType: SubmissionType;
  testFiles: Array<TestFile>;
  testProcess: ApplicationTestProcess;
  testProcessUuid: Scalars["UUID"];
  testSet: TestSet;
  testSetUuid: Scalars["UUID"];
  uuid: Scalars["UUID"];
};

export type TestEvaluation = BaseAggregateRoot & {
  __typename?: "TestEvaluation";
  applicationUuid: Scalars["UUID"];
  bid: Scalars["String"];
  comment?: Maybe<Scalars["String"]>;
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  grade: EvaluationGrade;
  modifiedBy?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  pass: Scalars["Boolean"];
  test: Application;
  testProcessUuid: Scalars["UUID"];
  uuid: Scalars["UUID"];
};

export type TestEvaluationPageResponse = {
  __typename?: "TestEvaluationPageResponse";
  content: Array<TestEvaluation>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type TestFile = {
  __typename?: "TestFile";
  name?: Maybe<Scalars["String"]>;
  path?: Maybe<Scalars["String"]>;
  s3Path?: Maybe<Scalars["String"]>;
};

export type TestGuide = BaseAggregateRoot & {
  __typename?: "TestGuide";
  attachment: TestGuideAttachment;
  bid: Scalars["String"];
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deleted: Scalars["Boolean"];
  deletedBy?: Maybe<Auth>;
  deletedDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  skillTypeCode?: Maybe<Scalars["String"]>;
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  submissionType?: Maybe<SubmissionType>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
};

export type TestGuideAttachment = {
  __typename?: "TestGuideAttachment";
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deleted: Scalars["Boolean"];
  deletedBy?: Maybe<Auth>;
  deletedDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name: Scalars["String"];
  path: Scalars["String"];
  type: TestGuideAttachmentType;
  volume?: Maybe<Scalars["Int"]>;
};

export enum TestGuideAttachmentType {
  File = "FILE",
  Link = "LINK",
}

export type TestGuidePageResponse = {
  __typename?: "TestGuidePageResponse";
  content: Array<TestGuide>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type TestPageResponse = {
  __typename?: "TestPageResponse";
  content: Array<Test>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type TestProcessPageResponse = {
  __typename?: "TestProcessPageResponse";
  content: Array<ApplicationTestProcess>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export enum TestProcessState {
  Evaluated = "EVALUATED",
  Skipped = "SKIPPED",
  Start = "START",
  Submitted = "SUBMITTED",
  Waiting = "WAITING",
}

export type TestSet = BaseAggregateRoot & {
  __typename?: "TestSet";
  bid: Scalars["String"];
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  files: Array<TestSetFile>;
  links: Array<TestSetLink>;
  modifiedBy?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name: Scalars["String"];
  noticeComment: Scalars["String"];
  noticeCommentEn?: Maybe<Scalars["String"]>;
  noticeCommentJp?: Maybe<Scalars["String"]>;
  skillTypeCode?: Maybe<Scalars["String"]>;
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  submissionType: SubmissionType;
  uuid: Scalars["UUID"];
};

export type TestSetFile = BaseAggregateRoot & {
  __typename?: "TestSetFile";
  bid: Scalars["String"];
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deleted: Scalars["Boolean"];
  deletedBy: Auth;
  deletedDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name: Scalars["String"];
  s3Path: Scalars["String"];
  targetLanguageCode?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
};

export type TestSetLink = BaseAggregateRoot & {
  __typename?: "TestSetLink";
  bid: Scalars["String"];
  createdBy: Auth;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deleted: Scalars["Boolean"];
  deletedBy: Auth;
  deletedDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name: Scalars["String"];
  path: Scalars["String"];
  targetLanguageCode?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
};

export type TestSetPageResponse = {
  __typename?: "TestSetPageResponse";
  content: Array<TestSet>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export enum TestState {
  Closed = "CLOSED",
  Start = "START",
  Submit = "SUBMIT",
  Waiting = "WAITING",
}

export type TextBox = {
  __typename?: "TextBox";
  height: Scalars["Long"];
  width: Scalars["Long"];
  x: Scalars["Long"];
  y: Scalars["Long"];
};

export type TextWorkRequestInput = {
  sourceLanguage: CodeAssociationInput;
  targetLanguage: CodeAssociationInput;
};

export type TimezoneCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "TimezoneCode";
    code: Scalars["String"];
    countryCode: Scalars["String"];
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    index: Scalars["Int"];
    kstOffset: Scalars["String"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
    utfOffset: Scalars["String"];
  };

export enum ToolType {
  Clipstudio = "CLIPSTUDIO",
  Photoshop = "PHOTOSHOP",
}

export type ToonContentMetadata = {
  __typename?: "ToonContentMetadata";
  adult: Scalars["Boolean"];
  completed: Scalars["Boolean"];
  genre?: Maybe<CodeAssociation>;
  quantity?: Maybe<Workload>;
  queer: Scalars["Boolean"];
};

export type ToonContentSpecInput = {
  adult: Scalars["Boolean"];
  completed: Scalars["Boolean"];
  creator?: InputMaybe<Scalars["String"]>;
  genre?: InputMaybe<CodeAssociationInput>;
  name: Scalars["String"];
  numberOfEpisodes?: InputMaybe<Scalars["Int"]>;
  quantity?: InputMaybe<WorkloadInput>;
  queer: Scalars["Boolean"];
};

export type ToonDeliveryFileFormat = {
  __typename?: "ToonDeliveryFileFormat";
  directoryNamingFormat?: Maybe<Scalars["String"]>;
  fileNamingFormat?: Maybe<Scalars["String"]>;
  height: ToonDeliveryFileSize;
  memo?: Maybe<Scalars["String"]>;
  resolution: ToonDeliveryFileSize;
  type: ToonDeliveryFileFormatType;
  width: ToonDeliveryFileSize;
};

export type ToonDeliveryFileFormatInput = {
  directoryNamingFormat?: InputMaybe<Scalars["String"]>;
  fileNamingFormat?: InputMaybe<Scalars["String"]>;
  height: ToonDeliveryFileSizeInput;
  memo?: InputMaybe<Scalars["String"]>;
  resolution: ToonDeliveryFileSizeInput;
  type: ToonDeliveryFileFormatType;
  width: ToonDeliveryFileSizeInput;
};

export enum ToonDeliveryFileFormatType {
  Indd = "INDD",
  Jpg = "JPG",
  Psd = "PSD",
  SlicedJpg = "SLICED_JPG",
}

export type ToonDeliveryFileSize = {
  __typename?: "ToonDeliveryFileSize";
  type: ToonDeliveryFileSizeType;
  unit: ToonDeliveryFileSizeUnit;
  value?: Maybe<Scalars["Int"]>;
};

export type ToonDeliveryFileSizeInput = {
  type: ToonDeliveryFileSizeType;
  unit: ToonDeliveryFileSizeUnit;
  value?: InputMaybe<Scalars["Int"]>;
};

export enum ToonDeliveryFileSizeType {
  Manuanl = "MANUANL",
  None = "NONE",
  Origninal = "ORIGNINAL",
}

export enum ToonDeliveryFileSizeUnit {
  Dpi = "DPI",
  Pixel = "PIXEL",
}

export type ToonDifficultyEvaluation = {
  __typename?: "ToonDifficultyEvaluation";
  translation?: Maybe<ToonTranslationDifficulty>;
  typesetting?: Maybe<ToonTypesettingDifficulty>;
};

export type ToonDifficultyEvaluationInput = {
  translation?: InputMaybe<ToonTranslationDifficultyInput>;
  typesetting?: InputMaybe<ToonTypesettingDifficultyInput>;
};

export type ToonPreliminarySchedule = {
  __typename?: "ToonPreliminarySchedule";
  deliveryDeadline?: Maybe<Scalars["OffsetDateTime"]>;
  workload?: Maybe<Workload>;
  workloadPerWeek?: Maybe<WorkloadPerWeek>;
};

export type ToonPreliminaryScheduleInput = {
  deliveryDeadline?: InputMaybe<Scalars["OffsetDateTime"]>;
  workload?: InputMaybe<WorkloadInput>;
  workloadPerWeek?: InputMaybe<WorkloadPerWeekInput>;
};

export type ToonQuotationCreateRequestInput = {
  quotation: ToonQuotationSpecInput;
  quotationProducts: Array<QuotationProductSpecInput>;
};

export type ToonQuotationCreateResponse = CommonResponse & {
  __typename?: "ToonQuotationCreateResponse";
  quotation: QuotationGqlModel;
  succeed: Scalars["Boolean"];
};

export type ToonQuotationDelivery = {
  __typename?: "ToonQuotationDelivery";
  fileFormats: Array<ToonDeliveryFileFormat>;
};

export type ToonQuotationDeliveryInput = {
  fileFormats: Array<ToonDeliveryFileFormatInput>;
};

export type ToonQuotationProductCreateRequestInput = {
  quotation: AggregateAssociationInput;
  quotationProduct: QuotationProductSpecInput;
};

export type ToonQuotationProductCreateResponse = CommonResponse & {
  __typename?: "ToonQuotationProductCreateResponse";
  quotationProduct: QuotationProductGqlModel;
  succeed: Scalars["Boolean"];
};

export type ToonQuotationProductDeleteRequestInput = {
  quotationProduct: AggregateAssociationInput;
};

export type ToonQuotationProductDeleteResponse = CommonResponse & {
  __typename?: "ToonQuotationProductDeleteResponse";
  quotationProduct: QuotationProductGqlModel;
  succeed: Scalars["Boolean"];
};

export type ToonQuotationProductDifficultyEvaluateRequestInput = {
  applyInBulk?: InputMaybe<Scalars["Boolean"]>;
  difficultyEvaluation: ToonDifficultyEvaluationInput;
  quotationProduct: AggregateAssociationInput;
};

export type ToonQuotationProductDifficultyEvaluateResponse = CommonResponse & {
  __typename?: "ToonQuotationProductDifficultyEvaluateResponse";
  quotationProduct: QuotationProductGqlModel;
  succeed: Scalars["Boolean"];
};

export type ToonQuotationProductUpdateRequestInput = {
  delivery?: InputMaybe<ToonQuotationDeliveryInput>;
  manager?: InputMaybe<IdentifiableAssociationInput>;
  quotationProduct: AggregateAssociationInput;
  references?: InputMaybe<FileSystemRestructureSpecInput>;
  schedule?: InputMaybe<ToonQuotationScheduleInput>;
  work?: InputMaybe<ToonQuotationWorkInput>;
};

export type ToonQuotationProductUpdateResponse = CommonResponse & {
  __typename?: "ToonQuotationProductUpdateResponse";
  quotationProduct: QuotationProductGqlModel;
  succeed: Scalars["Boolean"];
};

export type ToonQuotationRequestSource = EmptyModel & {
  __typename?: "ToonQuotationRequestSource";
  empty: Scalars["Boolean"];
};

export type ToonQuotationRequestSourceGqlModel = EmptyModel & {
  __typename?: "ToonQuotationRequestSourceGqlModel";
  empty: Scalars["Boolean"];
};

export type ToonQuotationRequestWork = EmptyModel & {
  __typename?: "ToonQuotationRequestWork";
  empty: Scalars["Boolean"];
};

export type ToonQuotationSchedule = {
  __typename?: "ToonQuotationSchedule";
  memo?: Maybe<Scalars["String"]>;
  preliminary?: Maybe<ToonPreliminarySchedule>;
  regular?: Maybe<ToonRegularSchedule>;
  setting: ToonSettingSchedule;
  targetResponseDate?: Maybe<Scalars["OffsetDateTime"]>;
  translationWorkStartDate?: Maybe<Scalars["OffsetDateTime"]>;
  typesettingWorkStartDate?: Maybe<Scalars["OffsetDateTime"]>;
};

export type ToonQuotationScheduleInput = {
  memo?: InputMaybe<Scalars["String"]>;
  preliminary?: InputMaybe<ToonPreliminaryScheduleInput>;
  regular?: InputMaybe<ToonRegularScheduleInput>;
  setting: ToonSettingScheduleInput;
  targetResponseDate?: InputMaybe<Scalars["OffsetDateTime"]>;
  translationWorkStartDate?: InputMaybe<Scalars["OffsetDateTime"]>;
  typesettingWorkStartDate?: InputMaybe<Scalars["OffsetDateTime"]>;
};

export type ToonQuotationSpecInput = {
  compatibilityClient?: InputMaybe<AggregateAssociationInput>;
  content: ToonContentSpecInput;
  customer?: InputMaybe<AggregateAssociationInput>;
  manager: IdentifiableAssociationInput;
  notes?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<FileSystemRestructureSpecInput>;
  sourceUrl?: InputMaybe<Scalars["String"]>;
};

export type ToonQuotationUpdateRequestInput = {
  compatibilityClient?: InputMaybe<AggregateAssociationInput>;
  content?: InputMaybe<ToonContentSpecInput>;
  customer?: InputMaybe<AggregateAssociationInput>;
  manager?: InputMaybe<IdentifiableAssociationInput>;
  notes?: InputMaybe<Scalars["String"]>;
  quotation: AggregateAssociationInput;
  source?: InputMaybe<FileSystemRestructureSpecInput>;
  sourceUrl?: InputMaybe<Scalars["String"]>;
};

export type ToonQuotationUpdateResponse = CommonResponse & {
  __typename?: "ToonQuotationUpdateResponse";
  quotation: QuotationGqlModel;
  succeed: Scalars["Boolean"];
};

export type ToonQuotationWork = {
  __typename?: "ToonQuotationWork";
  censored: Scalars["Boolean"];
  memo: Scalars["String"];
  sourceType: ToonSourceType;
  targetType: ToonSourceType;
};

export type ToonQuotationWorkInput = {
  censored: Scalars["Boolean"];
  memo: Scalars["String"];
  sourceType: ToonSourceTypeInput;
  targetType: ToonSourceTypeInput;
};

export type ToonRegularSchedule = {
  __typename?: "ToonRegularSchedule";
  workloadPerWeek: WorkloadPerWeek;
};

export type ToonRegularScheduleInput = {
  workloadPerWeek: WorkloadPerWeekInput;
};

export type ToonResource = {
  __typename?: "ToonResource";
  height: Scalars["Long"];
  previewS3Path: Scalars["String"];
  textBox: TextBox;
  trpS3Path: Scalars["String"];
  width: Scalars["Long"];
};

export type ToonSettingSchedule = {
  __typename?: "ToonSettingSchedule";
  deliveryDeadline?: Maybe<Scalars["OffsetDateTime"]>;
};

export type ToonSettingScheduleInput = {
  deliveryDeadline?: InputMaybe<Scalars["OffsetDateTime"]>;
};

export type ToonSourceType = {
  __typename?: "ToonSourceType";
  language: CodeAssociation;
  viewType: ToonViewType;
};

export type ToonSourceTypeInput = {
  language: CodeAssociationInput;
  viewType: ToonViewType;
};

export type ToonTranslationDifficulty = {
  __typename?: "ToonTranslationDifficulty";
  characterComplexity: DifficultyLevel;
  dialogueTonePropernounComplexity: DifficultyLevel;
  externalEvaluationChartUrl?: Maybe<Scalars["String"]>;
  onomatopoeiaIdeophones: DifficultyLevel;
  overallDifficulty: DifficultyLevel;
  quantityDifficulty: DifficultyLevel;
  specialFeatures?: Maybe<Scalars["String"]>;
};

export type ToonTranslationDifficultyInput = {
  characterComplexity: DifficultyLevel;
  dialogueTonePropernounComplexity: DifficultyLevel;
  externalEvaluationChartUrl?: InputMaybe<Scalars["String"]>;
  onomatopoeiaIdeophones: DifficultyLevel;
  overallDifficulty: DifficultyLevel;
  quantityDifficulty: DifficultyLevel;
  specialFeatures?: InputMaybe<Scalars["String"]>;
};

export type ToonTypesettingDifficulty = {
  __typename?: "ToonTypesettingDifficulty";
  basicGuide: DifficultyLevel;
  drawingRetouching: DifficultyLevel;
  externalEvaluationChartUrl?: Maybe<Scalars["String"]>;
  overallDifficulty: DifficultyLevel;
  quantityDifficulty: DifficultyLevel;
  soundEffectFx: DifficultyLevel;
  soundEffectRetouching: DifficultyLevel;
  specialFeatures?: Maybe<Scalars["String"]>;
};

export type ToonTypesettingDifficultyInput = {
  basicGuide: DifficultyLevel;
  drawingRetouching: DifficultyLevel;
  externalEvaluationChartUrl?: InputMaybe<Scalars["String"]>;
  overallDifficulty: DifficultyLevel;
  quantityDifficulty: DifficultyLevel;
  soundEffectFx: DifficultyLevel;
  soundEffectRetouching: DifficultyLevel;
  specialFeatures?: InputMaybe<Scalars["String"]>;
};

export enum ToonViewType {
  Page = "PAGE",
  Scroll = "SCROLL",
}

export type Transfer = BaseAggregateRoot & {
  __typename?: "Transfer";
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  job: Job;
  jobUuid: Scalars["UUID"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  origin: Origin;
  originJobUuid: Scalars["UUID"];
  originPanoplayJob: PanoplayJob;
  originPanoplayProject: PanoplayProject;
  originProjectUuid: Scalars["UUID"];
  project: Project;
  projectUuid: Scalars["UUID"];
  transferredBy: Auth;
  transferredDate: Scalars["OffsetDateTime"];
  uuid: Scalars["UUID"];
};

export type TranslatableAggregate = {
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  description?: Maybe<Scalars["String"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name: Scalars["String"];
};

export enum TranslationStyle {
  OriginalTextLiteral = "ORIGINAL_TEXT_LITERAL",
  Transcreation = "TRANSCREATION",
}

export type TrinoQueryCreateRequestInput = {
  query: Scalars["String"];
  trinoQueryType: TrinoQueryType;
};

export type TrinoQueryCreateResponse = CommonResponse & {
  __typename?: "TrinoQueryCreateResponse";
  succeed: Scalars["Boolean"];
};

export enum TrinoQueryType {
  WorkerMatchingTranslation = "WORKER_MATCHING_TRANSLATION",
  WorkerMatchingTranslationReview = "WORKER_MATCHING_TRANSLATION_REVIEW",
  WorkerMatchingTypesetting = "WORKER_MATCHING_TYPESETTING",
  WorkerMatchingTypesettingReview = "WORKER_MATCHING_TYPESETTING_REVIEW",
}

export type UnitPointer = BaseAggregateRoot & {
  __typename?: "UnitPointer";
  comment?: Maybe<UnitPointerGroup>;
  createdBy: Scalars["UUID"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  deleted: Scalars["Boolean"];
  deletedBy?: Maybe<Scalars["UUID"]>;
  deletedDate?: Maybe<Scalars["OffsetDateTime"]>;
  description?: Maybe<Scalars["String"]>;
  editorResource: EditorResource;
  editorResourceUuid: Scalars["UUID"];
  editorUnit: EditorUnit;
  editorUnitUuid: Scalars["UUID"];
  jobProcessUuid?: Maybe<Scalars["UUID"]>;
  jobUuid?: Maybe<Scalars["UUID"]>;
  modifiedBy: Scalars["UUID"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  operationTypeCode?: Maybe<Scalars["String"]>;
  operationUuid?: Maybe<Scalars["UUID"]>;
  originEditorUnitUuids: Array<Scalars["UUID"]>;
  position: Scalars["JSON"];
  positionType: UnitPointerPositionType;
  projectTypeCode?: Maybe<Scalars["String"]>;
  projectUuid?: Maybe<Scalars["UUID"]>;
  task: Task;
  taskUuid?: Maybe<Scalars["UUID"]>;
  type: UnitPointerType;
  uuid: Scalars["UUID"];
  version: Scalars["Int"];
};

export type UnitPointerGroup = BaseAggregateRoot & {
  __typename?: "UnitPointerGroup";
  checked: Scalars["Boolean"];
  comment?: Maybe<Comment>;
  createdBy: Scalars["UUID"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  modifiedBy: Scalars["UUID"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  unitPointerUuids: Array<Scalars["UUID"]>;
  unitPointers: Array<UnitPointer>;
  uuid: Scalars["UUID"];
};

export enum UnitPointerPositionType {
  ImageMarker = "IMAGE_MARKER",
  ImageRange = "IMAGE_RANGE",
  ImageSvg = "IMAGE_SVG",
  Simple = "SIMPLE",
}

export enum UnitPointerType {
  Issue = "ISSUE",
  Origin = "ORIGIN",
  Reference = "REFERENCE",
  Revision = "REVISION",
}

export type UpdateFinalizedPriceRequestInput = {
  accepted: Scalars["Boolean"];
  memo?: InputMaybe<Scalars["String"]>;
  price: ProductPriceSpecInput;
  quotationProduct: AggregateAssociationInput;
};

export type UpdateFinalizedPriceResponse = CommonResponse & {
  __typename?: "UpdateFinalizedPriceResponse";
  quotationProduct: QuotationProductGqlModel;
  succeed: Scalars["Boolean"];
};

export type UpdateProposedPriceRequestInput = {
  accepted: Scalars["Boolean"];
  memo?: InputMaybe<Scalars["String"]>;
  price: ProductPriceSpecInput;
  quotationProduct: AggregateAssociationInput;
};

export type UpdateProposedPriceResponse = CommonResponse & {
  __typename?: "UpdateProposedPriceResponse";
  quotationProduct: QuotationProductGqlModel;
  succeed: Scalars["Boolean"];
};

export type UseLanguage = {
  __typename?: "UseLanguage";
  languageCode: Scalars["String"];
  languageLevel: LanguageLevel;
};

export type User = {
  __typename?: "User";
  applications?: Maybe<Array<Application>>;
  applicationsForSpecificPeriod: Array<Application>;
  capability?: Maybe<Capability>;
  careerAttachments?: Maybe<Array<CareerAttachment>>;
  contact?: Maybe<Contact>;
  contractable: Scalars["Boolean"];
  contracts?: Maybe<Array<Contract>>;
  coupon?: Maybe<Coupon>;
  couponHistories: Array<Coupon>;
  deposit?: Maybe<Deposit>;
  educationQualifications: Array<EducationQualification>;
  languageQualifications: Array<LanguageQualification>;
  profile?: Maybe<Profile>;
  requiredSubmissionState: RequiredSubmissionState;
  settlement: UserSettlement;
  skillCareers: Array<SkillCareer>;
  termAgreement?: Maybe<TermAgreement>;
};

export type UserFixedSettlementCriteriaInput = {
  authUuids?: InputMaybe<Array<Scalars["UUID"]>>;
  baseDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  baseDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  createdDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  depositType?: InputMaybe<DepositType>;
  states?: InputMaybe<Array<FixedSettlementState>>;
};

export type UserSettlement = {
  __typename?: "UserSettlement";
  fixedSettlement: Array<FixedSettlement>;
  fixedSettlementPage: FixedSettlementPageResponse;
  targetSettlementGrouped?: Maybe<TargetSettlementGroupedPageResponse>;
  targetSettlementPage: TargetSettlementPageResponse;
  totalFixedSettlementPayValue: Array<UserSettlementTotalResponse>;
  totalTargetSettlementPayValue: Array<UserSettlementTotalResponse>;
};

export type UserSettlementFixedSettlementArgs = {
  criteria?: InputMaybe<UserFixedSettlementCriteriaInput>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type UserSettlementFixedSettlementPageArgs = {
  criteria?: InputMaybe<UserFixedSettlementCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type UserSettlementTargetSettlementGroupedArgs = {
  criteria?: InputMaybe<UserTaskSettlementGroupCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type UserSettlementTargetSettlementPageArgs = {
  criteria?: InputMaybe<UserTargetSettlementCriteriaInput>;
  page?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type UserSettlementTotalFixedSettlementPayValueArgs = {
  criteria?: InputMaybe<UserFixedSettlementCriteriaInput>;
};

export type UserSettlementTotalTargetSettlementPayValueArgs = {
  criteria?: InputMaybe<UserTargetSettlementCriteriaInput>;
};

export type UserSettlementTotalResponse = {
  __typename?: "UserSettlementTotalResponse";
  currencyCode: Scalars["String"];
  payValue: Scalars["Float"];
};

export type UserTargetSettlementCriteriaInput = {
  contentTypeCode?: InputMaybe<Scalars["String"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
  requestedDateFrom?: InputMaybe<Scalars["OffsetDateTime"]>;
  requestedDateTo?: InputMaybe<Scalars["OffsetDateTime"]>;
  states?: InputMaybe<Array<TargetSettlementState>>;
};

export type UserTaskSettlementGroupCriteriaInput = {
  requestedDateFrom: Scalars["OffsetDateTime"];
  requestedDateTo: Scalars["OffsetDateTime"];
  types?: InputMaybe<Array<SettlementType>>;
};

export enum UserType {
  Normal = "NORMAL",
  Vendor = "VENDOR",
}

export type Work = BaseAggregateRoot & {
  __typename?: "Work";
  bid: Scalars["String"];
  createdBy?: Maybe<Auth>;
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  endPosition: Scalars["Int"];
  modifiedBy?: Maybe<Auth>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  operation: Operation;
  operationUuid: Scalars["UUID"];
  startPosition: Scalars["Int"];
  tasks: Array<Task>;
  uuid: Scalars["UUID"];
  version: Scalars["Int"];
  workUnit: WorkUnit;
};

export type WorkPriceGqlModel = {
  __typename?: "WorkPriceGqlModel";
  amount: Scalars["BigDecimal"];
  bid: Scalars["String"];
  createdBy: Auth;
  createdDate: Scalars["OffsetDateTime"];
  currency: CodeAssociation;
  deleted: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  eventVersion: Scalars["Long"];
  id: Scalars["UUID"];
  name?: Maybe<Scalars["String"]>;
  operationType?: Maybe<CodeAssociation>;
  prefix: Scalars["String"];
  sourceLanguage?: Maybe<CodeAssociation>;
  targetLanguage?: Maybe<CodeAssociation>;
  unit?: Maybe<CodeAssociation>;
  version: Scalars["Long"];
};

export type WorkPriceGqlModelBidArgs = {
  format?: InputMaybe<GqlBusinessIdFormat>;
};

export type WorkPriceSpecInput = {
  version: Scalars["Long"];
  workPrice: AggregateAssociationInput;
};

export enum WorkUnit {
  Episode = "EPISODE",
  ParagraphIndex = "PARAGRAPH_INDEX",
  Second = "SECOND",
}

export type WorkUnitCode = BaseAggregateRoot &
  CommonCode & {
    __typename?: "WorkUnitCode";
    code: Scalars["String"];
    contentTypeCodes: Array<Scalars["String"]>;
    createdDate?: Maybe<Scalars["OffsetDateTime"]>;
    description?: Maybe<Scalars["String"]>;
    index: Scalars["Int"];
    modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
    name: Scalars["String"];
  };

export type WorkableDay = BaseAggregateRoot & {
  __typename?: "WorkableDay";
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  day: Scalars["OffsetDateTime"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  uuid: Scalars["UUID"];
};

export type WorkableDayPageResponse = {
  __typename?: "WorkableDayPageResponse";
  content: Array<WorkableDay>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type Worker = BaseAggregateRoot & {
  __typename?: "Worker";
  auth: Auth;
  authUuid: Scalars["UUID"];
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  latestStartTask?: Maybe<Task>;
  messengers: Array<Messenger>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  taskgroups: Array<TaskGroup>;
  tasks: Array<Task>;
  timezoneCode?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
  workable: Scalars["Boolean"];
  workerQualification?: Maybe<WorkerQualification>;
  workerType: WorkerType;
};

export type WorkerLatestStartTaskArgs = {
  date: Scalars["OffsetDateTime"];
};

export type WorkerCandidateSelectionFactor = MatchingAlgorithmWorkerCandidateSelectionFactor;

export enum WorkerCandidateSelectionType {
  Manual = "MANUAL",
  MatchingAlgorithm = "MATCHING_ALGORITHM",
}

export type WorkerGroup = BaseAggregateRoot & {
  __typename?: "WorkerGroup";
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  creatorAuth: Auth;
  creatorAuthUuid: Scalars["UUID"];
  deleted: Scalars["Boolean"];
  deletedDate?: Maybe<Scalars["OffsetDateTime"]>;
  description?: Maybe<Scalars["String"]>;
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  name: Scalars["String"];
  /** @deprecated Deleted */
  skill?: Maybe<DeprecatedSkill>;
  skillTypeCode: Scalars["String"];
  sourceLanguageCode?: Maybe<Scalars["String"]>;
  targetLanguageCode?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
  workerUuids: Array<Scalars["UUID"]>;
  workers: Array<Worker>;
};

export type WorkerGroupPageResponse = {
  __typename?: "WorkerGroupPageResponse";
  content: Array<WorkerGroup>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export enum WorkerMatchingAlgorithm {
  ProposalManual = "PROPOSAL_MANUAL",
  SequentialGroupProposal = "SEQUENTIAL_GROUP_PROPOSAL",
}

export type WorkerMatchingAlgorithmOption = SequentialGroupProposalAlgorithmOption;

export type WorkerMatchingCandidateDeleteRequestInput = {
  workerMatchingCandidate: AggregateAssociationInput;
};

export type WorkerMatchingCandidateDeleteResponse = CommonResponse & {
  __typename?: "WorkerMatchingCandidateDeleteResponse";
  succeed: Scalars["Boolean"];
  workerMatchingCandidate: WorkerMatchingCandidateGqlModel;
};

export type WorkerMatchingCandidateDeselectRequestInput = {
  workerMatchingCandidate: AggregateAssociationInput;
};

export type WorkerMatchingCandidateDeselectResponse = CommonResponse & {
  __typename?: "WorkerMatchingCandidateDeselectResponse";
  succeed: Scalars["Boolean"];
  workerMatchingCandidate: WorkerMatchingCandidateGqlModel;
};

export type WorkerMatchingCandidateGqlModel = {
  __typename?: "WorkerMatchingCandidateGqlModel";
  acceptance?: Maybe<AcceptanceStatus>;
  bid: Scalars["String"];
  candidateSelectionFactor?: Maybe<WorkerCandidateSelectionFactor>;
  candidateSelectionType: WorkerCandidateSelectionType;
  createdBy: Auth;
  createdDate: Scalars["OffsetDateTime"];
  deleted: Scalars["Boolean"];
  eventVersion: Scalars["Long"];
  id: Scalars["UUID"];
  matchingProcess: WorkerMatchingProcessGqlModel;
  order?: Maybe<Scalars["Int"]>;
  prefix: Scalars["String"];
  proposalSentDate?: Maybe<Scalars["OffsetDateTime"]>;
  quotationWorkerProposal?: Maybe<QuotationWorkerProposalGqlModel>;
  selected: Scalars["Boolean"];
  version: Scalars["Long"];
  worker: Auth;
};

export type WorkerMatchingCandidateGqlModelBidArgs = {
  format?: InputMaybe<GqlBusinessIdFormat>;
};

export type WorkerMatchingCandidateQuerySpecInput = {
  deleted?: InputMaybe<Scalars["Boolean"]>;
  ids?: InputMaybe<Array<Scalars["UUID"]>>;
  matchingProcesses?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type WorkerMatchingCandidateRespondRequestInput = {
  accepted: Scalars["Boolean"];
  workerMatchingCandidate: AggregateAssociationInput;
};

export type WorkerMatchingCandidateRespondResponse = CommonResponse & {
  __typename?: "WorkerMatchingCandidateRespondResponse";
  succeed: Scalars["Boolean"];
  workerMatchingCandidate: WorkerMatchingCandidateGqlModel;
};

export type WorkerMatchingCandidateSelectRequestInput = {
  workerMatchingCandidate: AggregateAssociationInput;
};

export type WorkerMatchingCandidateSelectResponse = CommonResponse & {
  __typename?: "WorkerMatchingCandidateSelectResponse";
  succeed: Scalars["Boolean"];
  workerMatchingCandidate: WorkerMatchingCandidateGqlModel;
};

export type WorkerMatchingProcessGqlModel = {
  __typename?: "WorkerMatchingProcessGqlModel";
  bid: Scalars["String"];
  candidateSelectionCompleted: Scalars["Boolean"];
  candidateSelectionType: WorkerCandidateSelectionType;
  candidates: Array<WorkerMatchingCandidateGqlModel>;
  completed: Scalars["Boolean"];
  createdBy: Auth;
  createdDate: Scalars["OffsetDateTime"];
  deleted: Scalars["Boolean"];
  eventVersion: Scalars["Long"];
  id: Scalars["UUID"];
  matchingAlgorithm: WorkerMatchingAlgorithm;
  matchingAlgorithmOption?: Maybe<WorkerMatchingAlgorithmOption>;
  nextProcessingDate?: Maybe<Scalars["OffsetDateTime"]>;
  paused: Scalars["Boolean"];
  prefix: Scalars["String"];
  quotationPreset?: Maybe<QuotationPresetGqlModel>;
  targetWorkerSelectionCount: Scalars["Int"];
  trinoRequest?: Maybe<AggregateAssociation>;
  version: Scalars["Long"];
};

export type WorkerMatchingProcessGqlModelBidArgs = {
  format?: InputMaybe<GqlBusinessIdFormat>;
};

export type WorkerMatchingProcessNextProcessUpdateRequestInput = {
  workerMatchingProcess: AggregateAssociationInput;
};

export type WorkerMatchingProcessNextProcessUpdateResponse = CommonResponse & {
  __typename?: "WorkerMatchingProcessNextProcessUpdateResponse";
  succeed: Scalars["Boolean"];
  workerMatchingProcess: WorkerMatchingProcessGqlModel;
};

export type WorkerPageResponse = {
  __typename?: "WorkerPageResponse";
  content: Array<Worker>;
  empty: Scalars["Boolean"];
  first: Scalars["Boolean"];
  last: Scalars["Boolean"];
  number: Scalars["Int"];
  numberOfElements: Scalars["Int"];
  size: Scalars["Int"];
  totalElements: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type WorkerQualification = BaseAggregateRoot & {
  __typename?: "WorkerQualification";
  bid: Scalars["String"];
  createdDate?: Maybe<Scalars["OffsetDateTime"]>;
  handleAdultWork: Scalars["Boolean"];
  modifiedDate?: Maybe<Scalars["OffsetDateTime"]>;
  preferGenreCodes: Array<Scalars["String"]>;
  skills: Array<DeprecatedSkill>;
  useLanguages: Array<UseLanguage>;
  uuid: Scalars["UUID"];
  worker: Worker;
  workerUuid: Scalars["UUID"];
};

export enum WorkerType {
  Normal = "NORMAL",
  Vendor = "VENDOR",
}

export type WorkerWorkableCriteriaInput = {
  contentTypeCode?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["OffsetDateTime"]>;
  skillTypeCode: Scalars["String"];
  sourceLanguageCode?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["OffsetDateTime"]>;
  targetLanguageCode?: InputMaybe<Scalars["String"]>;
};

export type Workload = {
  __typename?: "Workload";
  amount: Scalars["BigDecimal"];
  unit: CodeAssociation;
};

export type WorkloadInput = {
  amount: Scalars["BigDecimal"];
  unit: CodeAssociationInput;
};

export type WorkloadPerWeek = {
  __typename?: "WorkloadPerWeek";
  maxAmount?: Maybe<Scalars["BigDecimal"]>;
  minAmount?: Maybe<Scalars["BigDecimal"]>;
  unit?: Maybe<CodeAssociation>;
};

export type WorkloadPerWeekInput = {
  maxAmount?: InputMaybe<Scalars["BigDecimal"]>;
  minAmount?: InputMaybe<Scalars["BigDecimal"]>;
  unit?: InputMaybe<CodeAssociationInput>;
};

export type CreateDocumentQuotationRequestMutationVariables = Exact<{
  request: DocumentQuotationRequestCreateRequestInput;
}>;

export type CreateDocumentQuotationRequestMutation = {
  __typename?: "Mutation";
  createDocumentQuotationRequest: {
    __typename?: "DocumentQuotationRequestCreateResponse";
    quotationRequest: { __typename?: "QuotationRequestGqlModel"; id: any };
  };
};

export type CreateFileTemporaryMutationVariables = Exact<{
  request: FileTemporaryCreateRequestInput;
}>;

export type CreateFileTemporaryMutation = {
  __typename?: "Mutation";
  createFileTemporary: {
    __typename?: "FileTemporaryCreateResponse";
    succeed: boolean;
    file: {
      __typename?: "FileGqlModel";
      id: any;
      s3Object: { __typename?: "S3ObjectGqlModel"; bucket: string; key: string; s3ObjectUrl: string };
      resource?:
        | { __typename?: "DocumentResource"; wordCount?: any | null; letterCount?: any | null }
        | { __typename?: "ToonResource" }
        | null;
    };
  };
};

export const CreateDocumentQuotationRequestDocument = gql`
  mutation CreateDocumentQuotationRequest($request: DocumentQuotationRequestCreateRequestInput!) {
    createDocumentQuotationRequest(request: $request) {
      ... on DocumentQuotationRequestCreateResponse {
        quotationRequest {
          id
        }
      }
    }
  }
`;
export type CreateDocumentQuotationRequestMutationFn = Apollo.MutationFunction<
  CreateDocumentQuotationRequestMutation,
  CreateDocumentQuotationRequestMutationVariables
>;

/**
 * __useCreateDocumentQuotationRequestMutation__
 *
 * To run a mutation, you first call `useCreateDocumentQuotationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentQuotationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentQuotationRequestMutation, { data, loading, error }] = useCreateDocumentQuotationRequestMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateDocumentQuotationRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDocumentQuotationRequestMutation,
    CreateDocumentQuotationRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDocumentQuotationRequestMutation, CreateDocumentQuotationRequestMutationVariables>(
    CreateDocumentQuotationRequestDocument,
    options,
  );
}
export type CreateDocumentQuotationRequestMutationHookResult = ReturnType<
  typeof useCreateDocumentQuotationRequestMutation
>;
export type CreateDocumentQuotationRequestMutationResult =
  Apollo.MutationResult<CreateDocumentQuotationRequestMutation>;
export type CreateDocumentQuotationRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateDocumentQuotationRequestMutation,
  CreateDocumentQuotationRequestMutationVariables
>;
export const CreateFileTemporaryDocument = gql`
  mutation createFileTemporary($request: FileTemporaryCreateRequestInput!) {
    createFileTemporary(request: $request) {
      ... on FileTemporaryCreateResponse {
        succeed
        file {
          id
          s3Object {
            bucket
            key
            s3ObjectUrl
          }
          resource {
            ... on DocumentResource {
              wordCount
              letterCount
            }
          }
        }
      }
    }
  }
`;
export type CreateFileTemporaryMutationFn = Apollo.MutationFunction<
  CreateFileTemporaryMutation,
  CreateFileTemporaryMutationVariables
>;

/**
 * __useCreateFileTemporaryMutation__
 *
 * To run a mutation, you first call `useCreateFileTemporaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileTemporaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileTemporaryMutation, { data, loading, error }] = useCreateFileTemporaryMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateFileTemporaryMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFileTemporaryMutation, CreateFileTemporaryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFileTemporaryMutation, CreateFileTemporaryMutationVariables>(
    CreateFileTemporaryDocument,
    options,
  );
}
export type CreateFileTemporaryMutationHookResult = ReturnType<typeof useCreateFileTemporaryMutation>;
export type CreateFileTemporaryMutationResult = Apollo.MutationResult<CreateFileTemporaryMutation>;
export type CreateFileTemporaryMutationOptions = Apollo.BaseMutationOptions<
  CreateFileTemporaryMutation,
  CreateFileTemporaryMutationVariables
>;
