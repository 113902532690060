import * as S from "./Payment.style";
import React, { useState } from "react";
import usePayment from "pages/Payment/usePayment";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "@voithru/front-core";
import useLanguageCode from "src/hooks/useLanguageCode";
import useSourceCode from "src/hooks/useSourceCode";
import {
  CodeAssociation,
  DocumentContentMetadata,
  DocumentQuotationSchedule,
  DocumentQuotationWork,
} from "src/types/graphql";
import { langKeyType } from "src/utils/textTranslation";
import { logging } from "src/utils/logger";

interface IPaymentMethod {
  type: string;
  name: string;
}

const paymentMethods: IPaymentMethod[] = [{ type: "card", name: "카드결제" }];

const Payment = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    loadingFetch,
    prepaymentWaiting,
    createPrepaymentWaiting,
    processMobileIamportPayment,
  } = usePayment({
    prepaymentWaitingId: new URLSearchParams(location.search)
      ?.get("prepaymentWaitingId")!
      .toString(),
  });

  React.useEffect(() => {
    if (prepaymentWaiting?.id == null) {
      return;
    }

    const isIamportSuccess =
      new URLSearchParams(location.search).get("imp_success") === "true";
    const isIamportFail =
      new URLSearchParams(location.search).get("imp_success") === "false";

    if (isIamportFail) {
      alert("결제를 실패했어요");
      return;
    }

    if (isIamportSuccess) {
      processMobileIamportPayment();
      navigate(
        "/paymentCompletion" + `?prepaymentWaitingId=` + prepaymentWaiting!!.id,
      );
    }
  }, [location.search, prepaymentWaiting?.id]);

  const {
    quotation: { content: { metadata } = { metadata: undefined } } = {
      content: {},
    },
    work,
    schedule,
  } = (prepaymentWaiting?.quotation?.quotationProducts[0] as {
    quotation?: { content?: { metadata?: DocumentContentMetadata } };
    work: DocumentQuotationWork;
    schedule: DocumentQuotationSchedule;
  }) || {};

  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod>(
    paymentMethods[0],
  );

  const handlePayment = async () => {
    try {
      await createPrepaymentWaiting();
      logging(
        "click",
        JSON.stringify({
          location: "payment",
          type: "submit",
          detail: `Complete payment`,
        }),
      );
      navigate(
        "/paymentCompletion" + `?prepaymentWaitingId=` + prepaymentWaiting!!.id,
      );
    } catch (e) {
      console.log(e);
      if ((e as Error).message === "Expired date") {
        alert("결제를 실패했어요");
        return;
      }

      alert("결제를 실패했어요");
    }
  };

  const sourceType = metadata?.type;

  const sourceLanguageCode = work?.sourceLanguage?.id;

  const targetLanguagesCodes = work?.targetLanguages;

  const expired =
    prepaymentWaiting?.expiredDate &&
    new Date(prepaymentWaiting.expiredDate) <= new Date();

  if (loadingFetch) {
    return null;
  }

  return (
    <S.PaymentWrapper>
      <h2 className="h2-text">
        결제
        <span className="notice-text">
          (
          {prepaymentWaiting?.expiredDate &&
            formatDate(
              new Date(prepaymentWaiting.expiredDate),
              "YYYY-MM-DD hh:mm:ss ",
              { isLocale: true },
            )}
          이후 결제 불가)
        </span>
      </h2>
      <section>
        <div className="row">
          <strong className="important-text">결제요약</strong>
          <div>
            {/*<b className="bold-text">결제금액</b>*/}
            <strong className={`important-text price`}>
              {prepaymentWaiting?.quotation.quotationProducts
                .reduce(
                  (acc, cur) =>
                    acc + Number(cur.productPrice!.proposedPrice!.amount),
                  0,
                )
                ?.toLocaleString()}
            </strong>
            <b className="bold-text price">원</b>
          </div>
        </div>
        <div className="row">
          <span className="left-text">1. 분야</span>
          <span className="right-text">
            {sourceType && <SourceType code={sourceType} />}
          </span>
        </div>
        <div className="row">
          <span className="left-text">2. 의뢰 파일</span>
          <span className="right-text">
            {prepaymentWaiting?.quotation?.sourceDirectory?.files[0].name}
          </span>
        </div>
        <div className="row">
          <span className="left-text">3. 언어 선택</span>
          {/*<span className="right-TextSelection">한국어 - 일본어</span>*/}
          <span className="right-text">
            {sourceLanguageCode && <LanguageText code={sourceLanguageCode} />} -{" "}
            {targetLanguagesCodes
              ?.map((it: CodeAssociation) => <LanguageText code={it.id} />)
              //@ts-ignore
              .reduce((prev: langKeyType, curr: langKeyType) => [
                prev,
                ", ",
                curr,
              ])}
          </span>
        </div>
        <div className="row">
          <span className="left-text">4. 납품 예정일</span>
          {/*<span className="right-TextSelection">2023. 04. 01</span>*/}
          <span className="right-text">
            {schedule?.deliveryDeadline &&
              formatDate(schedule?.deliveryDeadline, "YYYY. MM . DD", {
                isLocale: true,
              })}
          </span>
        </div>
      </section>
      <section className="section2">
        <strong className="important-text">결제 수단</strong>
        <div className="payment-method-area">
          {paymentMethods.map((it, index) => {
            return (
              <button
                className="payment-method-button"
                onClick={() => setPaymentMethod(it)}
              >
                <label className={"radio-box"}>
                  <input
                    id={it.type}
                    type="radio"
                    name="paymentMethod"
                    value={index}
                    checked={true}
                  />
                  <span className="checkmark"></span>
                  <span className="radio-text">{it.name}</span>
                </label>
              </button>
            );
          })}
        </div>
      </section>
      <button
        className="payment-btn"
        disabled={expired}
        onClick={expired ? undefined : handlePayment}
      >
        {!expired && "결제하기"}
        {expired && "결제 기한이 지나서 만료된 주문입니다"}
      </button>
    </S.PaymentWrapper>
  );
};

const SourceType = ({ code }: { code: string }) => {
  const { source } = useSourceCode(code);
  return <>{source?.name}</>;
};

const LanguageText = ({ code }: { code: CodeAssociation | string }) => {
  const { languageCode } = useLanguageCode(code);
  return <>{languageCode?.name}</>;
};

export default Payment;
