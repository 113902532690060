import { Link, useNavigate } from "react-router-dom";
import * as S from "./EstimateRequestCompletion.style";
import Logo from "src/assets/images/img-texttranlation-logo.png";
import useWindowSize from "src/utils/useWindowSize";
import { useEffect } from "react";
import { logging } from "src/utils/logger";

const Index = () => {
  const isWeb = useWindowSize();
  const navigate = useNavigate();

  const handleClickHomeButton = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigate("https://panoplay.io/");
  };

  return (
    <S.EstimateRequestCompletionWrapper>
      <img
        src={Logo}
        alt="panoplay"
        title="panoplay"
      />
      <p className="msg-1">
        견적 요청이 완료되었습니다
        <br />
        작성하신 메일로 {!isWeb && <br />}답변 드릴 예정입니다
      </p>
      <p className="msg-2">
        3일 이내에 견적을 받지 못 하시는 경우
        <br />
        sales@voithru.com 혹은{!isWeb && <br />} 파노플레이 고객센터로
        문의주세요
      </p>
      <Link
        to={"https://panoplay.io/"}
        target="_self"
        className="link"
        onClick={handleClickHomeButton}
      >
        홈으로 가기
      </Link>
    </S.EstimateRequestCompletionWrapper>
  );
};
export default Index;
