import * as S from "./Aside.style";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { requirementAtom, sourceAtom, textAtom, uploadFileAtom, worksAtom } from "src/recoil/atoms/textTranslation";
import { formatDateToYYYYMMDD } from "utils/date";
import { fieldOptions } from "utils/textTranslation";

const Aside = forwardRef<HTMLElement>((props) => {
  const defaultHeight = 262;
  const [height, setHeight] = useState(defaultHeight);
  const textContentRef = useRef(null);
  const source = useRecoilValue(sourceAtom);
  const works = useRecoilValue(worksAtom);
  const requirement = useRecoilValue(requirementAtom);
  const uploadFile = useRecoilValue(uploadFileAtom);
  const text = useRecoilValue(textAtom);

  const calculatedAisdeHeight = () => {
    const targetText = uploadFile.name || text;
    setHeight(defaultHeight + targetText.length / 2);
  };

  useEffect(() => {
    calculatedAisdeHeight();
  }, [text, uploadFile.name]);

  const calculatedOption = () => {
    const findOption = fieldOptions.find((option: { key: string; option: string }) => option.key === source.type);

    if (findOption) {
      return findOption.option;
    }

    return "";
  };

  return (
    <>
      <S.Aside style={{ height: `${height}px` }}>
        <b>MY 텍스트번역</b>
        <dl>
          <dt>1. 분야 선택</dt>
          <dd>{calculatedOption() || "번역할 분야를 선택해 주세요"}</dd>
          <dt>2. 텍스트 또는 파일 첨부</dt>
          <dd ref={textContentRef}>{uploadFile.name || text || "텍스트 또는 파일을 첨부하세요"}</dd>
          <dt>3. 언어 선택</dt>
          <dd className="aside-language">{works.sourceLanguage + "-" + works.targetLanguages}</dd>
          <dt>4. 희망 납품일</dt>
          <dd>{formatDateToYYYYMMDD(requirement.expectedDeliveryDate) || "상관없음"}</dd>
        </dl>
      </S.Aside>
    </>
  );
});
export default Aside;
