import * as S from "./TextTranslation.style";
import LangSelection from "components/TextTranslation/LangSelection";
import FieldSelection from "components/TextTranslation/FieldSelection";
import Attachments from "components/TextTranslation/Attachments";
import TextSelection from "components/TextTranslation/TextSelection";
import Aside from "components/Layout/Aside";
import DesiredDeadline from "components/TextTranslation/DesiredDeadline";
import AdditionalRequest from "components/TextTranslation/AdditionalRequest";
import PriceSelection from "components/TextTranslation/PriceSelection";
import RequestEstimate from "components/TextTranslation/RequestEstimate";
import Banner from "components/Layout/Banner";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { TextFieldStatus } from "@voithru/voithru-design-system-dev/dist/Stories/TextFields/TextArea/Interfaces";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  sourceAtom,
  textAtom,
  uploadFileAtom,
  worksAtom,
} from "src/recoil/atoms/textTranslation";
import ModalPortal from "components/ModalPortal";
import useWindowSize from "src/utils/useWindowSize";
import { logging } from "src/utils/logger";

const modalContentData = {
  genre: "번역할 분야를 선택해 주세요",
  file: "번역이 필요한 파일 혹은 \n" + "텍스트를 첨부해주세요",
  lang: "번역언어를 선택해주세요",
};

const TextTranslation = () => {
  const isWeb = useWindowSize();
  const [isActiveFileOrText, setIsActiveFileOrText] = useState(true);

  const [source, setSource] = useRecoilState(sourceAtom);
  const [genreStatus, setGenreStatus] = useState<TextFieldStatus>("normal");
  const genreRef = useRef<HTMLElement | null>(null);

  const [works, setWorks] = useRecoilState(worksAtom);
  const [langStatus, setLangStatus] = useState<TextFieldStatus>("normal");
  const langRef = useRef<HTMLElement | null>(null);

  const [text, setText] = useRecoilState(textAtom);
  const [textStatus, setTextStatus] = useState<TextFieldStatus>("normal");
  const textRef = useRef<HTMLElement | null>(null);

  const uploadFile = useRecoilValue(uploadFileAtom);
  const uploadFileRef = useRef<HTMLElement | null>(null);

  const [isShowModal, setIsShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const checkGenre = () => {
    if (source.type === "") {
      genreRef.current?.scrollIntoView({ behavior: "smooth" });
      setGenreStatus("error");
      setIsShowModal(true);
      setModalContent(modalContentData.genre);
      return true;
    }
    return false;
  };

  const checkLang = () => {
    if (works.targetLanguages.length === 0) {
      langRef.current?.scrollIntoView({ behavior: "smooth" });
      setLangStatus("error");
      setIsShowModal(true);
      setModalContent(modalContentData.lang);
      return true;
    }
    return false;
  };

  const checkFile = () => {
    if (uploadFile.size === 0) {
      uploadFileRef.current?.scrollIntoView({ behavior: "smooth" });
      setIsShowModal(true);
      setModalContent(modalContentData.file);
      return true;
    }
    return false;
  };

  const checkText = () => {
    if (text.length < 100 || uploadFile.size === 0) {
      textRef.current?.scrollIntoView({ behavior: "smooth" });
      setIsShowModal(true);
      setModalContent(modalContentData.file);
      return true;
    }
    return false;
  };

  const checkParams = () => {
    if (checkGenre()) return false;
    if (!isActiveFileOrText && checkText()) return false;
    if (isActiveFileOrText && checkFile()) return false;
    if (checkLang()) return false;

    return true;
  };

  const location = useLocation();

  return (
    <S.TextTranslationWrapper>
      <Banner
        isActiveFileOrText={isActiveFileOrText}
        setIsActiveFileOrText={setIsActiveFileOrText}
      />
      {location.pathname === "/textTranslation" ? (
        <div className="estimate-form-wrapper">
          {isWeb && <Aside />}
          <div className="estimate-form">
            <FieldSelection
              genreStatus={genreStatus}
              setGenreStatus={setGenreStatus}
              genreRef={genreRef}
            />
            {isActiveFileOrText ? (
              <Attachments
                uploadFileRef={uploadFileRef}
                setIsActiveFileOrText={setIsActiveFileOrText}
              />
            ) : (
              <TextSelection
                textStatus={textStatus}
                setTextStatus={setTextStatus}
                textRef={textRef}
                isActiveFileOrText={isActiveFileOrText}
                setIsActiveFileOrText={setIsActiveFileOrText}
              />
            )}
            <LangSelection
              langStatus={langStatus}
              setLangStatus={setLangStatus}
              langRef={langRef}
            />
            <DesiredDeadline />
            <AdditionalRequest />
            <PriceSelection />
            <RequestEstimate checkParams={checkParams} />
            {isShowModal && (
              <ModalPortal
                handleCancel={() => setIsShowModal(false)}
                modalContent={modalContent}
              />
            )}
          </div>
        </div>
      ) : (
        <Outlet />
      )}
    </S.TextTranslationWrapper>
  );
};
export default TextTranslation;
