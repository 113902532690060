import { useResetRecoilState } from "recoil";
import {
  estimatedProductPriceAtom,
  sourceAtom,
  textAtom,
  uploadFileAtom,
  wordCountAtom,
  worksAtom,
} from "src/recoil/atoms/textTranslation";

const useResetFile = () => {
  const resetGenre = useResetRecoilState(sourceAtom);
  const resetUploadFile = useResetRecoilState(uploadFileAtom);
  const resetSource = useResetRecoilState(sourceAtom);
  const resetWordCount = useResetRecoilState(wordCountAtom);
  const resetText = useResetRecoilState(textAtom);
  const resetWorks = useResetRecoilState(worksAtom);
  const resetEstimatedProductPrice = useResetRecoilState(estimatedProductPriceAtom)

  const clearFileState = () => {
    resetGenre();
    resetUploadFile();
    resetSource();
    resetWordCount();
    resetText();
    resetWorks();
    resetEstimatedProductPrice();
  };

  return { clearFileState };
};

export default useResetFile;
