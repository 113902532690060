import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { DocumentType } from "src/types/graphql";

const { persistAtom } = recoilPersist();

// Contact atom
export const contactAtom = atom({
  key: "contactAtom",
  default: {
    name: "",
    email: { address: "" },
    phone: {
      callingCode: { id: "+82" },
      number: "",
    },
  },
});

// Estimated price atom
export const estimatedProductPriceAtom = atom<number | null>({
  key: "estimatedProductPriceAtom",
  default: 0,
});

// Requirement atom
export const requirementAtom = atom<{
  notes: string;
  expectedDeliveryDate: Date | null;
}>({
  key: "requirementAtom",
  default: {
    notes: "",
    expectedDeliveryDate: null,
  },
});

// Text atom
export const textAtom = atom({
  key: "textAtom",
  default: "",
});

// Source atom
export const sourceAtom = atom<{
  files: { id: string }[];
  type: DocumentType | "";
}>({
  key: "sourceAtom",
  default: {
    files: [{ id: "" }],
    type: "",
  },
});

// upload File atom
export const uploadFileAtom = atom({
  key: "uploadFileAtom",
  default: {
    name: "",
    size: 0,
  },
});

// Workload atom
export const workloadAtom = atom<{
  amount: number;
  unit: { id: string };
}>({
  key: "workloadAtom",
  default: {
    amount: 0,
    unit: { id: "" },
  },
});

// Works atom
export const worksAtom = atom({
  key: "worksAtom",
  default: {
    sourceLanguage: "한국어",
    targetLanguages: [] as string[],
  },
});

export const wordCountAtom = atom({
  key: "wordCountAtom",
  default: {
    wordCount: 0,
    letterCount: 0,
  },
});
