import * as S from "./FieldSelection.style";
import Dropdown from "src/components/common/Dropdown";
import { useRecoilState } from "recoil";
import { sourceAtom } from "src/recoil/atoms/textTranslation";
import { DocumentType } from "src/types/graphql";
import { TextFieldStatus } from "@voithru/voithru-design-system-dev/dist/Stories/TextFields/TextArea/Interfaces";
import useEstimatePriceCalculator from "src/hooks/useEstimatePriceCalculator";
import { useEffect } from "react";

import { fieldOptions } from "utils/textTranslation";

interface IProps {
  genreStatus: TextFieldStatus;
  setGenreStatus: React.Dispatch<React.SetStateAction<TextFieldStatus>>;
  genreRef: React.MutableRefObject<HTMLElement | null>;
}

const FieldSelection = ({ genreStatus, setGenreStatus, genreRef }: IProps) => {
  const [source, setSource] = useRecoilState(sourceAtom);
  const { calculatedEstimatedProductPrice } = useEstimatePriceCalculator();

  const handleClickedSource = (paramKey: DocumentType) => {
    setSource({ ...source, type: paramKey });
    setGenreStatus("normal");
  };

  useEffect(() => {
    calculatedEstimatedProductPrice();
  }, [source.type]);

  return (
    <S.FieldSelection ref={genreRef}>
      <h4 className="h4-text">1. 번역할 분야 선택</h4>
      <Dropdown
        className="drop-down"
        status={genreStatus}
        placeholder={"분야 선택"}
        callbackFunc={handleClickedSource}
        value={source.type ?? ""}
        setValue={setSource}
        options={fieldOptions}
      />
      {genreStatus === "error" && (
        <span className="error-text">분야를 선택해주세요.</span>
      )}
    </S.FieldSelection>
  );
};
export default FieldSelection;
