import styled from "styled-components";
import unActiveCheckMarkImage from "src/assets/images/ic-radio-disabled-square-grey-30-18.png";
import activeCheckMarkImage from "src/assets/images/ic-radio-active-square-18.png";
import { tabletSize } from "src/utils/layout";

export const DesiredDeadlineWrapper = styled.section`
  padding: 20px;
  background: #fff;

  .header {
    display: flex;

    .guide-msg {
      font-size: 13px;
      margin-left: 7px;
      align-self: center;
    }
  }

  .deadline-area {
    margin-top: 20px;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 1px 5px 0 rgba(122, 139, 169, 0.1);
    border: solid 1px rgba(151, 151, 151, 0.3);
    background-color: #fff;
    display: flex;
    align-items: center;
    gap: 16px;

    .radio-area {
      align-self: center;

      label[class|="radio-box"] {
        vertical-align: middle;
        height: 18px;
        display: inline-block;
      }

      label[class|="radio-box-right"] {
        margin-left: 8px;
      }

      .label-title {
        font-size: 13px;
        font-weight: 600;

        margin-left: 4px;
      }

      input {
        display: none;
      }

      input[type="radio"] + .checkmark {
        width: 18px;
        height: 18px;
        border-radius: 4px;
        background-image: url(${unActiveCheckMarkImage});
        background-size: cover;
      }

      input[type="radio"]:checked + .checkmark {
        width: 18px;
        height: 18px;
        background-image: url(${activeCheckMarkImage});
        background-size: cover;
      }
    }

    .date-picker-wrapper {
      .date-picker {
        width: 110px;
        font-size: 14px;
        font-weight: 600;

        border-radius: 6.3px;
        background-color: #f3f4f7;
        padding: 10px;
        flex: 2 1 0;
      }
    }

    .text {
      align-self: center;
      font-size: 14px;
      font-weight: 600;
    }
  }

  @media (min-width: ${tabletSize}) {
    margin-top: 30px;
    padding: 0;
    background: transparent;

    .deadline-area {
      padding: 32px 19px;
      display: flex;
      flex-direction: row;
      gap: 0;

      .radio-area {
        label[class|="radio-box-right"] {
          margin-left: 88px;
        }
      }

      .date-picker-wrapper {
        margin-left: 18px;
        margin-right: 8px;

        .date-picker {
          width: 140px;
        }
      }

      .label-title {
        font-size: 15px;
      }
    }
  }
`;
