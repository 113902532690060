const useCreateTextFile = () => {
  const createFile = (text: string) => {
    const fileBlob = new Blob([text], { type: "text/plain" });

    const replaceFileNameByText = (text: string) => {
      return text
                 .replaceAll(/!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?/g,'_')
                 .replaceAll(/\r\n|\r|\n/g, '_')
                 .slice(0, 30);

    }

    return new File(
      [fileBlob], 
      `${replaceFileNameByText(text)}.txt`
      );
  };

  return { createFile };
};

export default useCreateTextFile;
