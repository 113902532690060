import { useRecoilValue, useResetRecoilState } from "recoil";
import {
  contactAtom,
  estimatedProductPriceAtom,
  requirementAtom,
  sourceAtom,
  uploadFileAtom,
  workloadAtom,
  worksAtom,
} from "src/recoil/atoms/textTranslation";
import { useMutation } from "@apollo/client";
import { CREATE_DOCUMENT_QUOTATION_REQUEST } from "src/api/document";
import { langKeyType, languageType } from "utils/textTranslation";
import { useState } from "react";

const useTextTranslation = () => {
  const [createEstimateRequest] = useMutation(
    CREATE_DOCUMENT_QUOTATION_REQUEST,
  );

  const contact = useRecoilValue(contactAtom);
  const estimatedProductPrice = useRecoilValue(estimatedProductPriceAtom);
  const requirement = useRecoilValue(requirementAtom);
  const source = useRecoilValue(sourceAtom);

  const workload = useRecoilValue(workloadAtom);
  const works = useRecoilValue(worksAtom);

  const resetUploadFile = useResetRecoilState(uploadFileAtom);
  const resetWorkload = useResetRecoilState(workloadAtom);

  const [isError, setIsError] = useState(false);

  const calculatedWorks = () => {
    const { sourceLanguage, targetLanguages } = works;

    const convertSourceLanguage = languageType[sourceLanguage];

    // @ts-ignore
    return targetLanguages.map((target: langKeyType) => {
      return {
        sourceLanguage: { id: convertSourceLanguage },
        targetLanguage: { id: languageType[target] },
      };
    });
  };

  const handleSubmitEstimate = async () => {
    // call createEstimateRequest mutation
    const estimateData = {
      request: {
        contact,
        estimatedProductPrice,
        requirement: {
          ...requirement,
          expectedDeliveryDate: requirement.expectedDeliveryDate?.toISOString(),
        },
        source: { files: source.files, type: source.type },
        workload: { ...workload, unit: workload.unit },
        works: calculatedWorks(),
      },
    };

    const response = await createEstimateRequest({ variables: estimateData });

    if (response) {
      alert("성공적으로 견적이 작성되었습니다.");
      return true;
    } else {
      return setIsError(true);
    }
  };

  const resetFileData = () => {
    resetUploadFile();
    resetWorkload();
  };

  return {
    handleSubmitEstimate,
    resetFileData,
    isError,
    setIsError,
  };
};

export default useTextTranslation;
