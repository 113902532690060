import * as S from "./Banner.style";
import useResetFile from "src/hooks/useResetFile";

interface IProps {
  isActiveFileOrText: boolean;
  setIsActiveFileOrText: React.Dispatch<React.SetStateAction<boolean>>;
}

const Banner = ({ isActiveFileOrText, setIsActiveFileOrText }: IProps) => {
  const { clearFileState } = useResetFile();

  const handleToggleTextOrFile = (type: boolean) => {
    if (type === isActiveFileOrText) return;
    clearFileState();
    setIsActiveFileOrText(type);
  };

  return (
    <S.BannerWrapper>
      <div className="banner-background">
        <div className="contents">
          <strong className="new-text">NEW</strong>
          <p className="description">
            소설·문서의 번역 가격
            <br />
            지금 바로 확인해보세요
          </p>
          <p className="sub-description">
            빠른 견적을 원하시나요?
            <br />몇 번의 클릭으로 예상 견적을 확인하실 수 있습니다.
          </p>
        </div>
      </div>
    </S.BannerWrapper>
  );
};

export default Banner;
