import { tabletSize } from "src/utils/layout";
import styled from "styled-components";

export const TextSelection = styled.section`
  background: #fff;

  .header {
    margin-top: 30px;
    padding: 0 14px;
    display: flex;
    justify-content: space-between;

    .header-inner {
      .h4-text {
        align-self: center;
      }
      .sub-text {
        font-size: 10px;
      }
    }

    .file-change-button {
      padding: 10px 8px;
      border-radius: 3px;
      background-blend-mode: multiply;
      background-image: linear-gradient(to bottom, #f0f1f5, #f0f1f5);
      font-size: 14px;
      font-weight: 600;
      background-color: #000;
      color: #fff;
    }

    @media (min-width: ${tabletSize}) {
      .file-change-button {
        padding: 10px 14px;
      }
    }
  }

  .text-add-button-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 0 14px;

    .text-success {
      color: #1fa878;
      font-size: 14px;
    }

    .text-add-button {
      padding: 10px 14px;
      border-radius: 3px;
      background-blend-mode: multiply;
      background-image: linear-gradient(to bottom, #f0f1f5, #f0f1f5);
      font-size: 14px;
      font-weight: 600;
      background-color: #000;
      color: #fff;
    }
  }

  .text-area {
    padding: 0 14px;
    margin-top: 20px;
  }

  @media (min-width: ${tabletSize}) {
    margin-top: 30px;
    background: transparent;
    .header {
      padding: 0;
      .header-inner {
        display: flex;
        align-items: center;
        gap: 10px;

        .sub-text {
          font-size: 13px;
        }
      }
    }
    .text-area {
      padding: 0;
    }

    .text-add-button-wrapper {
      position: relative;
      padding: 0;
      margin-bottom: 0;

      .text-add-button-inner {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
`;
