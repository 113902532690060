import { AxiosInstance } from "axios";
import { Parameter } from "@voithru/front-core";
import { API_VERSION } from "src/constants/api";

const PATH = `/graphql/${API_VERSION}/graphql`;

interface GraphqlRequestData<Variables> {
  name: string;
  query: string;
  variables: Variables;
}

interface GraphqlResponseData<Response> {
  data: Response;
}

export interface GraphqlResponseError {
  errors: {
    message: string;
    locations: { line: number; column: number }[];
    path: string[];
  }[];
}

type GraphqlResponse<Response> =
  | GraphqlResponseData<Response>
  | GraphqlResponseError;

function apiGQL(instance: AxiosInstance) {
  return {
    post: <Response, Variables>(data: GraphqlRequestData<Variables>) => {
      const path =
        PATH + `?` + Parameter.toURLSearchParams({ name: data.name });
      return instance.post<GraphqlResponse<Response>>(path, data);
    },
  };
}

export default apiGQL;
