import "./styles/font.css";
import Header from "components/Layout/Header";
import Footer from "components/Layout/Footer";
import Routes from "src/routes/Routes";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { RecoilRoot } from "recoil";

import client from "./utils/apollo";
import ChannelIo from "./components/Library/ChannelIo";
import { logging } from "./utils/logger";
import { useEffect } from "react";

function App() {
  ChannelIo();

  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "prod") {
      setInterval(() => {
        logging("stay");
      }, 3000);
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      logging(location.pathname + location.search);
    };

    const unmount = () => {
      handleRouteChange();
    };

    return unmount;
  }, [location]);

  return (
    <ApolloProvider client={client}>
      <RecoilRoot>
        <Header />
        <div className="App">{Routes()}</div>
        <Footer />
      </RecoilRoot>
    </ApolloProvider>
  );
}

export default App;
