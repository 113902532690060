import * as S from "./Attachments.style";
import { ChangeEvent, useRef, useState } from "react";
import {
  uploadFileAtom,
  wordCountAtom,
  worksAtom,
} from "src/recoil/atoms/textTranslation";
import { useRecoilValue } from "recoil";
import { getByteString } from "utils/util";
import useTextTranslation from "src/hooks/useTextTranslation";
import useCreateS3File from "src/hooks/useCreateS3File";
import useResetFile from "src/hooks/useResetFile";
import LoadingSpinner from "components/common/LoadingSpinner";
import GlobalModal from "components/common/GlobalModal";
import fileDeleteImg from "src/assets/images/btn-texttranslation-file-delete@3x.png";
import { arrowFileType } from "src/utils/file";
import useWindowSize from "src/utils/useWindowSize";
import HelpTooltip from "src/components/common/HelpTooltip";
import { logging } from "src/utils/logger";

window.Buffer = window.Buffer || require("buffer").Buffer;

interface IProps {
  setIsActiveFileOrText: React.Dispatch<React.SetStateAction<boolean>>;
  uploadFileRef: React.MutableRefObject<HTMLElement | null>;
}

const Attachments = ({ setIsActiveFileOrText, uploadFileRef }: IProps) => {
  const isWeb = useWindowSize();
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [isFileError, setIsFileError] = useState(false);

  const uploadFile = useRecoilValue(uploadFileAtom);
  const { wordCount, letterCount } = useRecoilValue(wordCountAtom);
  const { sourceLanguage, targetLanguages } = useRecoilValue<{
    sourceLanguage: String;
    targetLanguages: String[];
  }>(worksAtom);

  const { resetFileData } = useTextTranslation();
  const { clearFileState } = useResetFile();
  const { s3FileUpload } = useCreateS3File();

  const fileInput = useRef<HTMLInputElement>(null);

  const fileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = fileInput.current?.files?.[0];

    if (file) {
      setIsLoadingModal(true);
      const extension = file.name.split(".").pop();

      if (extension && !arrowFileType.includes(extension)) {
        setTimeout(() => {
          setIsLoadingModal(false);
          alert("허용할 수 없는 파일 입니다.");
        }, 1000);
        return;
      }

      const res = await s3FileUpload(file);

      if (res === "error") {
        setIsFileError(true);
      } else {
        setIsFileError(false);
      }

      setIsLoadingModal(false);
      logging(
        "fileUpload",
        JSON.stringify({
          location: "textTranslation",
          type: "file-btn",
          detail: `extension: ${extension}`,
        }),
      );
    }
  };

  const isLetterLanguage = () => {
    return sourceLanguage === "일본어" || sourceLanguage === "중국어";
  };

  const calculatedWordOrLetterCount = () => {
    if (isLetterLanguage()) return letterCount.toLocaleString();
    else return wordCount.toLocaleString();
  };

  return (
    <S.AttachMentsWrapper>
      <S.AttachMentsHeader>
        <h4 className="h4-text">2. 파일 첨부</h4>
        <button
          className="text-change-button"
          onClick={() => {
            setIsActiveFileOrText(false);
            clearFileState();
          }}
        >
          텍스트로 입력 ＞
        </button>
      </S.AttachMentsHeader>
      <S.AttachMentsContent ref={uploadFileRef}>
        <strong className="file-text">파일첨부</strong>
        <span className="file-types">docx, xlsx, txt, srt, pptx, pdf문서</span>
        {uploadFile.name === "" ? (
          <>
            <div className="file-upload">
              <label htmlFor="file">파일업로드</label>
              <input
                type="file"
                id="file"
                ref={fileInput}
                accept="application/pdf,application/vnd.ms-excel,text/plain,.pdf,.xlsx,.srt,.pptx,.docx"
                onChange={(e) => fileUpload(e)}
              ></input>
            </div>
          </>
        ) : (
          <div className="file-info">
            <div className="file-left-area">
              <span className="file-name">{uploadFile?.name}</span>
              <span className="file-type">
                {uploadFile.name.split(".").pop()}
              </span>
            </div>
            <div className="file-box">
              <img
                className="file-remove-btn"
                src={fileDeleteImg}
                alt="file remove Image"
                onClick={resetFileData}
              />
              <div className="file-row-wrapper">
                <div className="file-row">
                  <span className="total">
                    총 {isLetterLanguage() ? "글자" : "단어"}수
                  </span>
                  <span className="words">{calculatedWordOrLetterCount()}</span>
                </div>
                <div className="file-row">
                  <span className="file-size">
                    {getByteString(uploadFile?.size)}
                  </span>
                  <span className={isFileError ? "file-error" : "file-correct"}>
                    {isFileError ? "실패 오류" : "100%완료"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {isWeb && <HelpTooltip pos={{ top: 145, left: -230 }} />}
        {isLoadingModal && (
          <GlobalModal size={isWeb ? "medium" : "small"}>
            <LoadingSpinner size={isWeb ? "large" : "medium"} />
          </GlobalModal>
        )}
      </S.AttachMentsContent>
    </S.AttachMentsWrapper>
  );
};
export default Attachments;
