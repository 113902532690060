import { AxiosError } from "axios";
import { hasProperty } from "../utils";

export interface APIResponseError {
  message: string;
  code: string;
  status: number;
}

export function isAxiosError<ResData, ReqData>(
  error: unknown,
): error is AxiosError<ResData, ReqData> {
  return Boolean(
    hasProperty(error, ["isAxiosError"]) && error.isAxiosError === true,
  );
}

export function isAPIError(error: unknown): error is APIResponseError {
  return Boolean(
    hasProperty(error, ["success", "error"]) && error.success === false,
  );
}
