import React from "react";
import ReactDom from "react-dom";
import * as S from "./GlobalModal.style";
import Overlay from "../Overlay";

interface IProps {
  size: "xSmall" | "small" | "medium" | "large" | "xLarge";
  handleCancelClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  children: React.ReactNode;
}

const GlobalModal = ({ handleCancelClick, size, children }: IProps) => {
  return ReactDom.createPortal(
    <>
      <Overlay handleCancelClick={handleCancelClick} />
      <S.GlobalModal size={size}>{children}</S.GlobalModal>
    </>,
    document.getElementById("globalModal-root") as HTMLElement,
  );
};

export default GlobalModal;
