import { tabletSize } from "src/utils/layout";
import styled, { css } from "styled-components";

const selectSize = (size: string) => {
  switch (size) {
    case "xSmall":
      return css`
        width: 5rem;
        height: 5rem;
      `;
    case "small":
      return css`
        width: 16rem;
        height: 10rem;
      `;
    case "medium":
      return css`
        width: 30rem;
        height: 14.875rem;
      `;
    case "large":
      return css`
        width: 40rem;
        height: 25rem;
      `;
    default:
      throw new Error(`${size} type is not found`);
  }
};

export const GlobalModal = styled.section<{ size: string }>`
  ${({ size }) => selectSize(size)}
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 999;
  padding: 40px;
  border-radius: 2rem;

  .globalModal-content-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
      display: flex;
      align-items: center;
      gap: 8px;

      .header-warn-img {
        width: 24px;
        height: 24px;
      }

      .header-text {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.6px;
        color: #1d2230;
      }
    }

    .confirm-button {
      width: 81px;
      height: 38px;
      margin-left: auto;
      border-radius: 4px;
      background-color: #000;
      color: #fff;
      cursor: pointer;
    }
  }

  @media (min-width: ${tabletSize}) {
    .header-text {
      font-size: 24px;
    }
  }
`;
