import { css } from "styled-components";

export const ellipsis = (lines = 1) => css`
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  ${lines > 1
    ? css`
        display: -webkit-box;
        -webkit-line-clamp: ${lines};
        -webkit-box-orient: vertical;
      `
    : css`
        white-space: nowrap;
      `}
`;
