import { tabletSize } from "src/utils/layout";
import styled from "styled-components";

export const FieldSelection = styled.section`
  background-color: #fff;
  padding: 20px;

  .h4-text {
    margin-bottom: 12px;
  }

  .error-text {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: #ff3636;
    display: block;
    margin: 3px 0 0 16px;
  }

  @media (min-width: ${tabletSize}) {
    margin-top: 40px;
    padding: 0;
    background-color: transparent;
    .h4-text {
      margin-bottom: 20px;
    }
  }
`;
