import * as S from "./RequestEstimate.style";
import { Tooltip } from "@voithru/voithru-design-system-dev/dist";
import { useNavigate } from "react-router-dom";
import { logging } from "src/utils/logger";

interface IProps {
  checkParams: () => boolean;
}

const RequestEstimate = ({ checkParams }: IProps) => {
  const navigate = useNavigate();
  // 실견적요청 함수
  const handleEstimateSubmit = () => {
    if (!checkParams()) {
      alert("누락된 값이 존재합니다. 확인해주세요.");
      return;
    }

    navigate(`/textTranslation/userInfo`);
    logging(
      "click",
      JSON.stringify({
        location: "textTranslation",
        type: "submit",
        detail: `clicked actual request`,
      }),
    );
  };

  return (
    <S.RequestEstimate>
      <div className="submit-area">
        <Tooltip
          id="tooltip"
          className="tooltip"
          pointerDirection={"bottom"}
          color={"#1b2133"}
          fontSize={"13"}
          backgroundColor={"#ffffff"}
          pointerPosition="18"
          boxShadow={
            "0 0 8px 0 rgba(67, 86, 100, 0.12), 0 0 4px 0 rgba(67, 86, 100, 0.1)"
          }
        >
          <span style={{ fontSize: "14px" }}>
            전문 매니저가 정확한 견적가를 산출합니다
          </span>
        </Tooltip>
        <button
          className="submit-btn"
          onClick={handleEstimateSubmit}
        >
          실견적 요청
        </button>
      </div>
    </S.RequestEstimate>
  );
};

export default RequestEstimate;
