import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  estimatedProductPriceAtom,
  sourceAtom,
  uploadFileAtom,
  wordCountAtom,
  workloadAtom,
  worksAtom,
} from "src/recoil/atoms/textTranslation";
import { workloadUnitType } from "utils/textTranslation";

const part1PriceObj = {
  한국어: {
    영어: 135,
    일본어: 135,
    "중국어(간체)": 135,
    "중국어(번체)": 135,
    스페인어: 170,
    베트남어: 170,
    인도네시아어: 170,
    태국어: 170,
    러시아어: 170,
  },
  영어: {
    한국어: 120,
  },
  일본어: {
    한국어: 65,
  },
  중국어: {
    한국어: 65,
  },
};
const part2PriceObj = {
  한국어: {
    영어: 270,
    일본어: 270,
    "중국어(간체)": 270,
    "중국어(번체)": 270,
    스페인어: 330,
    베트남어: 330,
    인도네시아어: 330,
    태국어: 330,
    러시아어: 330,
  },
  영어: {
    한국어: null,
  },
  일본어: {
    한국어: null,
  },
  중국어: {
    한국어: null,
  },
};

const part3PriceObj = {
  한국어: {
    영어: 160,
    일본어: 160,
    "중국어(간체)": 160,
    "중국어(번체)": 160,
    스페인어: 230,
    베트남어: 230,
    인도네시아어: 230,
    태국어: 230,
    러시아어: 230,
  },
  영어: {
    한국어: 160,
  },
  일본어: {
    한국어: 90,
  },
  중국어: {
    한국어: 90,
  },
};

const typePriceMap = {
  NOVEL: part1PriceObj,
  SCREENPLAY: part1PriceObj,
  SYNOPSIS: part1PriceObj,
  INTRODUCTION: part1PriceObj,
  LECTURE: part1PriceObj,
  THESIS: part2PriceObj,
  CONTRACT: part2PriceObj,
  OTHER: part3PriceObj,
};

const useEstimatePriceCalculator = () => {
  const { type } = useRecoilValue(sourceAtom);
  const { sourceLanguage, targetLanguages } = useRecoilValue<{
    sourceLanguage: String;
    targetLanguages: String[];
  }>(worksAtom);

  const setEstimatedProductPrice = useSetRecoilState(estimatedProductPriceAtom);
  const setWorkload = useSetRecoilState(workloadAtom);
  const { wordCount, letterCount } = useRecoilValue(wordCountAtom);
  const uploadFile = useRecoilValue(uploadFileAtom);

  const calculatedEstimatedProductPrice = () => {
    let savedPrice = 0;

    if (type === "" || sourceLanguage === "" || uploadFile.size === 0) return;
    if (wordCount === null && letterCount === null) return;
    if (targetLanguages.length === 0) {
      setEstimatedProductPrice(0);
      return;
    }

    try {
      for (const targetLanguage of targetLanguages) {
        const wordCountOrLetterCount =
          sourceLanguage === "일본어" || sourceLanguage === "중국어"
            ? letterCount
            : wordCount;

        // @ts-ignore
        const price = typePriceMap[type]?.[sourceLanguage]?.[targetLanguage];

        if (price) {
          savedPrice += price * wordCountOrLetterCount;
        }
      }

      setEstimatedProductPrice(savedPrice === 0 ? null : savedPrice);
      setWorkload({
        amount:
          sourceLanguage === "일본어" || sourceLanguage === "중국어"
            ? letterCount
            : wordCount,
        unit:
          sourceLanguage === "일본어" || sourceLanguage === "중국어"
            ? { id: workloadUnitType.LETTER }
            : { id: workloadUnitType.WORD },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return { calculatedEstimatedProductPrice };
};

export default useEstimatePriceCalculator;
