import * as S from "./DesiredDeadline.style";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useRecoilState } from "recoil";
import { requirementAtom } from "src/recoil/atoms/textTranslation";
import { useState } from "react";
import useWindowSize from "src/utils/useWindowSize";

interface PropsType {}

const DesiredDeadline = (props: PropsType) => {
  const isWeb = useWindowSize();
  const [requirement, setRequirement] = useRecoilState(requirementAtom);
  const [isDirectDate, setIsDirectDate] = useState(false);

  return (
    <S.DesiredDeadlineWrapper>
      <div className="header">
        <h4 className="h4-text">4. 희망 납품일</h4>
        {isWeb && (
          <p className="guide-msg">
            1만 단어 당 약 일주일이 소요되며, 정확한 작업 일정은 실제 견적과 함께 안내드립니다.
          </p>
        )}
      </div>
      <div className="deadline-area">
        <div className="radio-area">
          <label className="radio-box">
            <input
              id="radio1"
              type="radio"
              name="deadline"
              value={0}
              checked={!isDirectDate}
              onChange={() => {
                setIsDirectDate(false);
                setRequirement({
                  ...requirement,
                  expectedDeliveryDate: null,
                });
              }}
            />

            <span className="checkmark"></span>
          </label>
          <label
            className="label-title"
            htmlFor="radio1"
          >
            상관없음
          </label>
          <label className="radio-box-right">
            <input
              id="radio2"
              type="radio"
              name="deadline"
              value={1}
              checked={isDirectDate}
              onChange={() => {
                setIsDirectDate(true);
                setRequirement({
                  ...requirement,
                  expectedDeliveryDate: new Date(),
                });
              }}
            />
            <span className="checkmark"></span>
          </label>
          <label
            className="label-title"
            htmlFor="radio2"
          >
            직접입력
          </label>
        </div>
        <div className="date-picker-wrapper">
          <DatePicker
            className="date-picker"
            dateFormat="yyyy/MM/dd"
            selected={requirement.expectedDeliveryDate}
            minDate={new Date()}
            onChange={(date) => {
              if (date) {
                setRequirement({
                  ...requirement,
                  expectedDeliveryDate: new Date(date),
                });
                setIsDirectDate(true);
              }
            }}
          />
        </div>
        {isWeb && <span className="text">까지 희망합니다.</span>}
      </div>
    </S.DesiredDeadlineWrapper>
  );
};
export default DesiredDeadline;
