import * as S from "./LoadingSpinner.style";

interface IProps {
  size: string;
}

const LoadingSpinner = ({ size = "small" }: IProps) => {
  return <S.LoadingSpinner size={size} />;
};
export default LoadingSpinner;
