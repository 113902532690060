import {gql} from "@apollo/client";

export const COMPLETE_PREPAYMENTWAITING_REQUEST = gql`
    mutation completePrepaymentWaiting(
        $request: PrepaymentWaitingCompleteRequestInput!
    ) {
        completePrepaymentWaiting(request: $request) {
            succeed
        }
    }
`;