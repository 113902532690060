import { useEffect, useState } from "react";

const useWindowSize = () => {
  const [size, setSize] = useState(true);
  useEffect(() => {
    function updateSize() {
      if (window.innerWidth > 786) {
        setSize(true);
      } else {
        setSize(false);
      }
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export default useWindowSize;
