import { tabletSize } from "src/utils/layout";
import styled from "styled-components";
import closeBtnImg from "src/assets/images/btn-mainmenu-close-active-32@3x.png";

export const Header = styled.header`
  height: 79px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background-color: #fff;
  position: relative;

  .hamburger-menu {
    position: absolute;
    top: 15px;
    left: 12px;
    width: 0;
    height: 0;

    #menuToggle {
      display: block;
      position: relative;
      z-index: 1;
    }

    #menuToggle input {
      position: absolute;
      cursor: pointer;

      opacity: 0; /* hide this */
      z-index: 2; /* and place it over the hamburger */
    }

    #menuToggle span {
      display: block;
      width: 16px;
      height: 1px;
      margin-bottom: 6px;
      position: relative;

      background: #000;

      z-index: 1;

      transform-origin: 4px 0px;
    }
  }

  .close-btn {
    position: fixed;
    font-size: 16px;
    top: 12px;
    right: 10%;
    width: 32px;
    height: 32px;
    background: url(${closeBtnImg});
    background-size: cover;
    z-index: 1001;
  }

  #menu {
    width: 300px;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1001;
    background: #fff;
  }

  #menu li {
    padding: 15px 50px;
    border-bottom: 1px solid #f3f3f3;
    cursor: pointer;
  }

  #menuToggle input:checked ~ ul {
    transform: none;
  }

  .header-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .panoplay-logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 58px;
    text-align: center;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  }

  .panoplay-logo {
    height: 16px;
  }

  nav,
  a {
    align-self: center;
  }

  .gnb-menu {
    display: flex;
    gap: 40px;
    height: 42px;
    a {
      color: #212121;
      font-weight: 400;
      font-size: 12px;
    }
  }

  .menu-li {
    display: flex;
    align-items: center;
    height: 30px;
  }

  .estimate-link {
    display: none;
  }

  .active {
    border-bottom: 2px solid #212121;
  }

  @media (min-width: ${tabletSize}) {
    width: 100%;
    padding: 15px 83px;

    .panoplay-logo-wrapper {
      width: 10%;
      border-bottom: none;
      justify-content: flex-start;
    }

    .hamburger-menu {
      display: none;
    }

    .panoplay-logo {
      height: 26px;
    }

    .header-inner {
      width: 1200px;
      flex-direction: row;
      justify-content: space-between;
    }

    .gnb-menu {
      margin: 0;
      a {
        color: #000;
        font-size: 16px;
      }
    }

    .estimate-link {
      display: block;
      background-color: #000;
      color: white;
      padding: 11px 24px;
      border-radius: 4px;
    }
  }
`;
