import styled from "styled-components";

import translationPcBg from "src/assets/images/bg_texttranslation_compression.png";
import translationMobileBg from "src/assets/images/bg_texttranslation_compression_mob@3x.png";
import { tabletSize } from "src/utils/layout";

export const BannerWrapper = styled.div`
  width: 100%;
  height: 238px;
  position: relative;

  .banner-background {
    height: 238px;
    padding: 30px 0 28px 0;
    box-sizing: border-box;
    background-image: url(${translationMobileBg});
    background-size: cover;
  }

  .contents {
    height: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: center;

    .new-text {
      display: block;
      color: red;
      font-size: 10px;
      font-weight: 600;
      text-align: center;
    }

    .description {
      display: block;

      font-size: 21px;
      font-weight: bold;

      color: #fff;
      text-align: center;
    }

    .sub-description {
      font-size: 12px;
      font-weight: 600;
      line-height: 1.5;

      color: #fff;
      text-align: center;
      opacity: 0.84;
    }

    .button-wrap {
      display: flex;
      column-gap: 9px;
      justify-content: center;

      button {
        width: 79px;
        height: 34px;
        border-radius: 4.9px;
        background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0.3)
          ),
          linear-gradient(111deg, #825e45 -3%, #314a4a 95%);
        color: white;
        font-size: 11.2px;
      }

      .active {
        background-color: #e9edf5;
        background-image: unset;
        color: black;
      }
    }
  }

  @media (min-width: ${tabletSize}) {
    height: 340px;
    border-radius: 25.9px;
    .banner-background {
      background-image: url(${translationPcBg});
      max-width: 1200px;
      height: 340px;
      margin: 23px auto 0;
    }

    .contents {
      gap: 25px;
      .new-text {
        font-size: 14px;
      }
      .description {
        font-size: 30px;
        font-weight: 600;
      }

      .sub-description {
        font-size: 14px;
      }

      .button-wrap {
        button {
          width: 110px;
          height: 49px;
          font-size: 16px;
        }
      }
    }
  }
`;
