import styled from "styled-components";
import { ellipsis } from "src/styles/mixin";

export const Aside = styled.aside`
  position: sticky;
  top: 45%;
  width: 175px;
  margin-top: 80px;
  box-sizing: border-box;
  border: solid 1px rgba(151, 151, 151, 0.5);
  background-color: #fff;
  border-radius: 14px;
  height: max-content;
  max-height: 300px;
  margin-bottom: 54px;

  b {
    font-size: 12px;
    font-weight: 600;
    padding: 16.5px;
    padding-bottom: 12.5px;
    border-bottom: solid 1px rgba(151, 151, 151, 0.5);
    display: block;
  }

  dl {
    border-top: 0;
    padding: 16.5px;

    dt {
      &:not(:first-child) {
        margin-top: 17px;
      }

      font-size: 12px;
      font-weight: 600;
    }

    dd {
      font-size: 12px;
      margin-top: 3px;
      color: #3c4558;
      ${ellipsis(4)};
    }

    .aside-language {
      ${ellipsis(1)};
    }
  }
`;
