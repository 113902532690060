import api from "src/api/service";
import {
  S3MultipartCompletePostRequest,
  S3MultipartHeaderPostRequest,
  S3MultipartHeaderPostResponse,
  S3PostRequest,
  S3PostResponse,
} from "src/types/api/file";
import { NamedFile } from "src/types/file";
import { array } from "src/types/utils";
import { isAPIError, isAxiosError } from "src/types/api/axios";
import EventerGroup from "src/utils/events";
import QueueTask from "src/utils/queueTask";
import { ByteSize, sha256Checksum } from "src/utils/util";

type ETag = string;

type VoidHandler = (it: MultipartUpload) => void;
type ProgressEventHandler = (e: ProgressEvent) => void;

interface MultipartUploadAddEventListener {
  progress: ProgressEventHandler;
  ready: VoidHandler;
  start: VoidHandler;
  cancel: VoidHandler;
  done: VoidHandler;
}

const DEFAULT_CHUNK_SIZE = 50 * ByteSize.MEGA;
const DEFAULT_UPLOAD_COUNT = 5;
const UPLOAD_PART_RETRY_COUNT = 4;

interface MultipartUploadOptions {
  chunkSize: number;
  countOfOnce: number;
}

export interface MultipartUploadProgress {
  max: number;
  current: number;
}

export type MultipartUploadStatus =
  | "INIT"
  | "READY"
  | "START"
  | "CANCEL"
  | "DONE";

class MultipartUpload {
  public status: MultipartUploadStatus = "INIT";
  public progress: MultipartUploadProgress = {
    max: this.namedFile.file.size,
    current: 0,
  };
  public s3Path?: string;
  #task = new QueueTask();
  // Multipart Options
  #options: MultipartUploadOptions;
  // Exist after `ready`
  #dataReg?: S3PostResponse;
  // Events
  #events = new EventerGroup<MultipartUploadAddEventListener>();
  public addEventListener = this.#events.addEventListener;

  constructor(
    public readonly namedFile: NamedFile,
    option?: Partial<MultipartUploadOptions>,
  ) {
    this.#options = {
      chunkSize: option?.chunkSize || DEFAULT_CHUNK_SIZE,
      countOfOnce: option?.countOfOnce || DEFAULT_UPLOAD_COUNT,
    };

    this.#events.addEventListener("ready", () => (this.status = "READY"));
    this.#events.addEventListener("start", () => (this.status = "START"));
    this.#events.addEventListener("cancel", () => (this.status = "CANCEL"));
    this.#events.addEventListener("done", () => (this.status = "DONE"));
  }

  // File parts count
  private get partNumbers() {
    const count = Math.ceil(this.namedFile.file.size / this.#options.chunkSize);
    return array(count, (it) => it + 1);
  }

  public ready = () => this.#task.dispatch(this.#readyFn);

  public start = () => this.#task.dispatch(this.#startFn);

  public onProgress(partNumber: number) {
    return function (this: MultipartUpload, e: ProgressEvent) {
      if (!e.lengthComputable) {
        return;
      }

      this.progress.current =
        this.#options.chunkSize * (partNumber - 1) + e.loaded;

      this.#events.run("progress", e);
    }.bind(this);
  }

  #readyFn = async () => {
    const checkSum = sha256Checksum(await this.namedFile.file.arrayBuffer());

    const data: S3PostRequest = {
      fileName: this.namedFile.name,
      sha256Checksum: checkSum,
    };

    const res = await api.s3.post(data);

    if (isAxiosError(res) || isAPIError(res.data)) {
      throw res;
    }

    // @ts-ignore
    this.#dataReg = res.data.response;
    this.#events.run("ready", this);
  };

  /**
   * 2. Start file upload
   */
  #startFn = async () => {
    if (!this.#dataReg) {
      throw new Error("MultipartUpload: Not Ready");
    }

    this.#events.run("start", this);

    const tags: string[] = [];
    for (const partNumber of this.partNumbers) {
      try {
        const etag = await this.uploadPart(partNumber);
        tags.push(etag);
      } catch (error) {
        throw new Error("MultipartUpload.start: Error");
      }
    }

    const res = await this.completeUpload(tags);
    this.#events.run("done", this);

    return res;
  };

  private uploadPart = async (part: number): Promise<ETag> => {
    for (const _ of array(UPLOAD_PART_RETRY_COUNT + 1)) {
      try {
        const offset = this.#options.chunkSize * (part - 1);
        const blob = this.namedFile.file.slice(
          offset,
          offset + this.#options.chunkSize,
          this.namedFile.file.type,
        );
        const buff = await blob.arrayBuffer();
        const checksum = sha256Checksum(buff);

        // 2-1. Get S3 Auth header
        const header = await this.getPartHeader(part, checksum);

        // 2-2. Upload part to S3
        return await this.uploadPartToS3(part, blob, header);
      } catch (error) {}
    }

    throw new Error("MultipartUpload.uploadPart: Failed retry");
  };

  private getPartHeader = async (part: number, sha256Checksum: string) => {
    if (!this.#dataReg) {
      throw new Error("MultipartUpload.getPartHeader: Invalid dataReg");
    }

    const req: S3MultipartHeaderPostRequest = {
      ...this.#dataReg,
      partNumber: part,
      sha256Checksum,
    };
    const res = await api.s3.uploadPartHeaderValue.post(req);
    if (isAxiosError(res) || isAPIError(res.data)) {
      throw res;
    }

    // @ts-ignore
    return res.data.response;
  };

  private uploadPartToS3 = async (
    partNumber: number,
    blob: Blob,
    header: S3MultipartHeaderPostResponse,
  ): Promise<ETag> => {
    if (!this.#dataReg) {
      throw new Error("MultipartUpload.uploadPartToS3: Invalid dataReg");
    }

    const options = {
      bucket: header.bucketName,
      key: this.#dataReg.key,
      params: { partNumber, uploadId: this.#dataReg.uploadId },
      headers: {
        Authorization: header.authorization,
        "x-amz-date": header.date,
        "x-amz-content-sha256": header.sha256Checksum,
        "content-type": "multipart/form" as const,
      },
    };
    const axiosOptions = {
      onUploadProgress: this.onProgress(partNumber),
    };

    // @ts-ignore
    const res = await api.s3.upload(blob, options, axiosOptions);
    if (isAxiosError(res) || res.status !== 200) {
      throw res;
    }

    return res.headers.etag;
  };

  /**
   * 3. Complete Upload
   * @param etags
   * @returns
   */
  private completeUpload = async (etags: string[]) => {
    if (!this.#dataReg) {
      throw Error("MultipartUpload.completeUpload: Invalid this.multipartData");
    }

    const req: S3MultipartCompletePostRequest = {
      ...this.#dataReg,
      partNumbers: this.partNumbers,
      etags,
    };
    const res = await api.s3.completeMultipartUpload.post(req);
    if (isAxiosError(res) || isAPIError(res.data)) {
      throw res;
    }

    // @ts-ignore
    this.s3Path = res.data.response.s3Path;
    // @ts-ignore
    return res.data.response;
  };
}

export default MultipartUpload;
