import * as S from "./LangSelection.style";
import Dropdown from "src/components/common/Dropdown";
import { useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  estimatedProductPriceAtom,
  worksAtom,
} from "src/recoil/atoms/textTranslation";
import { TextFieldStatus } from "@voithru/voithru-design-system-dev/dist/Stories/TextFields/TextArea/Interfaces";
import useEstimatePriceCalculator from "src/hooks/useEstimatePriceCalculator";
import { logging } from "src/utils/logger";

interface IProps {
  langStatus: TextFieldStatus;
  setLangStatus: React.Dispatch<React.SetStateAction<TextFieldStatus>>;
  langRef: React.MutableRefObject<HTMLElement | null>;
}

const LangSelection = ({ langStatus, setLangStatus, langRef }: IProps) => {
  const [works, setWorks] = useRecoilState(worksAtom);
  const resetEstimatedProductPrice = useResetRecoilState(
    estimatedProductPriceAtom,
  );
  const { calculatedEstimatedProductPrice } = useEstimatePriceCalculator();

  const handleClickedSourceLanguage = (paramKey: string) => {
    setWorks({
      sourceLanguage: paramKey,
      targetLanguages: [],
    });
    logging(
      "toggle",
      JSON.stringify({
        location: "textTranslation",
        type: "dropdown",
        detail: `sourceLanguage: ${paramKey}`,
      }),
    );
  };

  const handleClickedTargetLanguages = async (paramKeys: string[]) => {
    setWorks({
      ...works,
      targetLanguages: paramKeys,
    });
    setLangStatus("normal");
    logging(
      "toggle",
      JSON.stringify({
        location: "textTranslation",
        type: "dropdown",
        detail: `targetLanguages: ${paramKeys}`,
      }),
    );
  };

  const sourceLanguageOptions = [
    { key: "한국어", option: "한국어", code: "LGC0001" },
    { key: "영어", option: "영어", code: "LGC0002" },
    { key: "중국어", option: "중국어", code: "LGC0004" },
    { key: "일본어", option: "일본어", code: "LGC0003" },
  ];

  const [targetLanguageOptions, setTargetLanguageOptions] = useState([
    { key: "한국어", option: "한국어", code: "LGC0001" },
  ]);

  useEffect(() => {
    if (works.sourceLanguage === "한국어") {
      setTargetLanguageOptions([
        { key: "영어", option: "영어", code: "LGC0002" },
        { key: "일본어", option: "일본어", code: "LGC0004" },
        { key: "중국어(간체)", option: "중국어(간체)", code: "LGC0004" },
        { key: "중국어(번체)", option: "중국어(번체)", code: "LGC0005" },
        { key: "스페인어", option: "스페인어", code: "LGC0006" },
        { key: "베트남어", option: "베트남어", code: "LGC0007" },
        { key: "인도네시아어", option: "인도네시아어", code: "LGC0008" },
        { key: "태국어", option: "태국어", code: "LGC0009" },
        { key: "러시아어", option: "러시아어", code: "LGC0010" },
      ]);
    } else if (works.sourceLanguage === "영어") {
      setTargetLanguageOptions([
        { key: "한국어", option: "한국어", code: "LGC0001" },
      ]);
    } else if (works.sourceLanguage === "중국어") {
      setTargetLanguageOptions([
        { key: "한국어", option: "한국어", code: "LGC0001" },
      ]);
    } else if (works.sourceLanguage === "일본어") {
      setTargetLanguageOptions([
        { key: "한국어", option: "한국어", code: "LGC0001" },
      ]);
    }
  }, [works.sourceLanguage]);

  useEffect(() => {
    calculatedEstimatedProductPrice();
  }, [works.targetLanguages]);

  useEffect(() => {
    resetEstimatedProductPrice();
  }, [works.sourceLanguage]);

  return (
    <S.LangSelectionWrapper ref={langRef}>
      <h4>3. 언어 선택</h4>
      <div className="select-wrap">
        <div className="left-drop-down">
          <span className="title">출발어</span>
          <Dropdown
            id="sourceLanguage"
            className="category-select-box"
            status={"normal"}
            placeholder={"언어 선택"}
            value={works.sourceLanguage}
            setValue={setWorks}
            callbackFunc={handleClickedSourceLanguage}
            options={sourceLanguageOptions}
          />
        </div>
        <div className="right-drop-down">
          <span className="title">도착어</span>
          <Dropdown
            id="targetLanguage"
            className="category-select-box"
            status={langStatus}
            value={works.targetLanguages}
            setValue={setWorks}
            callbackFunc={handleClickedTargetLanguages}
            options={targetLanguageOptions}
            multiple={true}
          />
          {langStatus === "error" && (
            <span className="error-text">번역언어를 선택해주세요</span>
          )}
        </div>
      </div>
    </S.LangSelectionWrapper>
  );
};
export default LangSelection;
