import { tabletSize } from "src/utils/layout";
import styled from "styled-components";

export const Footer = styled.footer`
  background-color: #000000;
  color: rgba(255, 255, 255, 0.8);
  padding: 40px 0;

  .wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    max-width: 1190px;
    margin: 0 auto;

    a,
    span {
      color: rgba(255, 255, 255, 0.8);
      display: block;
      font-size: 13px;
    }

    .title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 40px;
      display: block;
      color: #fff;
    }

    .address {
      display: flex;
      flex-direction: column;
      gap: 6px;
      font-size: 12px;
      font-style: normal;
      margin-top: 40px;
    }

    .service-box {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }

    .logo-inner-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
    }

    .logo {
      width: 168px;
      height: 38px;
    }

    .service-area {
    }

    .contact-area {
      .contact-box {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
      span {
        font-size: 13px;
        font-weight: 400;
      }

      .bottom {
        margin-top: 15px;
      }
    }

    .family-site {
      margin-bottom: 10px;
      .family-site-box-wrapper {
        display: flex;
        gap: 16px;
      }

      a {
        width: 100px;
        font-size: 11px;
        font-weight: 700;
        border: 1px solid white;
        padding: 10px;
        box-sizing: border-box;
        text-align: center;
      }
    }
  }

  .policy-area {
    max-width: 1158px;
    margin: 0 auto;
    padding: 16px;
    border-top: 1px solid rgba(151, 151, 151, 0.5);
    display: flex;
    column-gap: 15px;

    a {
      font-size: 12px;
      color: #fff;
    }
  }

  @media (min-width: ${tabletSize}) {
    .wrapper {
      flex-direction: row;
      justify-content: space-between;

      .logo-inner-wrapper {
        display: block;
        height: 30px;
        .logo {
        }
      }

      .family-site {
        margin-bottom: 40px;
        .family-site-box-wrapper {
          flex-direction: column;
          gap: 20px;
        }
      }

      .policy-area {
      }
    }
  }
`;
