import * as S from "./PriceSelection.style";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { estimatedProductPriceAtom } from "src/recoil/atoms/textTranslation";
import useEstimatePriceCalculator from "src/hooks/useEstimatePriceCalculator";

const PriceSelection = () => {
  const estimatedProductPrice = useRecoilValue(estimatedProductPriceAtom);
  const { calculatedEstimatedProductPrice } = useEstimatePriceCalculator();

  useEffect(() => {
    calculatedEstimatedProductPrice();
  }, [calculatedEstimatedProductPrice]);

  return (
    <S.PriceSelection>
      <div className="price-inner-wrapper">
        <div className="left-area">
          <p className="guide-msg">
            단어와 글자 수를 바탕으로 낸 예상 견적입니다.
            <br /> 실제 견적액은 난이도와 요청 사항에 따라 조정될 수 있으며,
            “실견적 요청”버튼을 클릭 시, 전문 매니저가 신속하고 정확하게
            견적서를 보내드립니다.
          </p>
        </div>
        <div className="right-area">
          {estimatedProductPrice === null ? (
            <strong className="amount">상세 견적 확인 필요</strong>
          ) : (
            <>
              <span className="text">최소</span>
              <strong className="amount">
                {estimatedProductPrice.toLocaleString()}
              </strong>
              <span className="text">원 (vat 별도)</span>
            </>
          )}
        </div>
      </div>
    </S.PriceSelection>
  );
};

export default PriceSelection;
