import { tabletSize } from "src/utils/layout";
import styled from "styled-components";

export const LangSelectionWrapper = styled.section`
  padding: 20px;
  background: #fff;
  .select-wrap {
    border-radius: 12px;
    margin-top: 20px;
    background-color: white;
    display: flex;
    column-gap: 28px;
    padding: 28px;
    border-radius: 12px;
    box-shadow: 0 1px 5px 0 rgba(122, 139, 169, 0.1);
    border: solid 1px rgba(151, 151, 151, 0.3);

    .title {
      color: rgb(86, 97, 120);
      font-size: 12px;
      margin-bottom: 7px;
    }

    .category-select-box {
      img {
        vertical-align: -1px;
      }
    }

    .left-drop-down {
      width: 100%;
    }

    .right-drop-down {
      width: 100%;

      .error-text {
        font-size: 12px;
        letter-spacing: -0.4px;
        color: #ff3636;
        display: block;
        margin: 3px 0 0 16px;
      }
    }
  }

  @media (min-width: ${tabletSize}) {
    margin-top: 30px;
    padding: 0;
    background: transparent;
  }
`;
