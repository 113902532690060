import { ApolloClient, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_PUBLIC_API_SERVER}${process.env.REACT_APP_PUBLIC_GQL_VERSION}`,
  cache: new InMemoryCache(),

  defaultOptions: {
    query: {
      notifyOnNetworkStatusChange: true,
    },
    watchQuery: {
      notifyOnNetworkStatusChange: true,
    },
  },
});

export default client;
