import React from "react";
import { IPropsWithStyled } from "themes/interface";
import useExceptClick from "utils/hooks/useOutsideClick";
import { DropDown as DesignSystemDropdown } from "@voithru/voithru-design-system-dev/dist";
import styled from "styled-components";
import { DropDownStatusType } from "@voithru/voithru-design-system-dev/dist/Stories/DropDowns/Interfaces";

interface ICommonDropdownProps {
  status?: DropDownStatusType;
  placeholder?: string;
  callbackFunc?: (paramKey: any) => void;

  options: {
    key: string;
    option: string;
    color?: string;
    tip?: string;
  }[];
}

interface ISingleValueDropDownProps {
  multiple?: false;
  value: string | any;
  setValue: (value: string | any) => void;
}

interface IMultipleValueDropDownProps {
  multiple: true;
  value: string[] | any;
  setValue: (value: string[] | any) => void;
}

type IProps = (ISingleValueDropDownProps | IMultipleValueDropDownProps) &
  ICommonDropdownProps &
  IPropsWithStyled;

const Dropdown: React.ForwardRefRenderFunction<HTMLDivElement, IProps> = (
  props,
  ref,
) => {
  const {
    multiple,
    status,
    placeholder,
    callbackFunc,
    className,
    value,
    setValue,
    options,
  } = props;
  const [isOpen, setIsOpen] = React.useState(false);

  const wrapperRef = React.useRef<HTMLDivElement>(null);

  useExceptClick(wrapperRef, () => {
    setIsOpen(false);
  });

  const singleDropdownOptions = React.useMemo(
    () => (multiple ? undefined : options.map((it) => it.option)),
    [multiple, options],
  );

  const handleSingleSelect = (targetValue: string) => {
    if (multiple) {
      throw new Error("");
    }

    const key = options.find((it) => it.option === targetValue)?.key;

    if (callbackFunc) {
      callbackFunc(key);
    } else setValue(key);
  };

  const multipleDropdownOptions = React.useMemo(
    () =>
      !multiple ? undefined : options.map((it) => ({ option: it.option })),
    [multiple, options],
  );

  const handleMultipleSelect = (targetValues: { option: string }[] | null) => {
    if (!multiple) {
      throw new Error("");
    }

    const keys = options
      .filter((it) => targetValues?.map((i) => i.option)?.includes(it.option))
      .map((it) => it.key);

    if (callbackFunc) {
      callbackFunc(keys);
    } else setValue(keys);
  };

  const singleDropdownValues = React.useMemo(
    () => options.find((it) => it.key === value)?.option,
    [value, options],
  );

  const multipleDropdownValues = React.useMemo(
    () => options.filter((it) => value.includes(it.key)),
    [value, options],
  );

  return (
    <StyledWrapper ref={wrapperRef}>
      <DesignSystemDropdown
        clicked={isOpen}
        setClicked={setIsOpen}
        className={className}
        notCloseOnOptionClick={multiple ? true : false}
        status={status}
        multiple={multiple}
        width={"100%"}
        placeholder={placeholder}
        value={multiple ? multipleDropdownValues : singleDropdownValues}
        setValue={multiple ? handleMultipleSelect : handleSingleSelect}
        options={multiple ? multipleDropdownOptions : singleDropdownOptions}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  height: 48px;
  border-radius: 4px;
  flex: 1 1 auto;
  background-color: #fff;

  & > div {
    & > div {
      width: 100%;
    }
  }
`;

export default Dropdown;
