import { Navigate, Route, Routes } from "react-router-dom";
import TextTranslation from "pages/TextTranslation";
import UserInfo from "pages/UserInfo/UserInfo";
import Payment from "pages/Payment";
import Index from "pages/EstimateRequestCompletion";

import PaymentCompletion from "pages/PaymentCompletion";

export default function CreateRoutes() {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <Navigate
            to="/textTranslation"
            replace
          />
        }
      />
      <Route
        path="/textTranslation/*"
        element={<TextTranslation />}
      >
        <Route
          path="userInfo"
          element={<UserInfo />}
        />
      </Route>
      <Route
        path="/estimateRequestCompletion"
        element={<Index />}
      />
      <Route
        path="/payment"
        element={<Payment />}
      />
      <Route
        path="/paymentCompletion"
        element={<PaymentCompletion />}
      />
    </Routes>
  );
}
