import { NamedFile } from "src/types/file";
import MultipartUpload from "src/utils/multipartUpload";
import { nanoid } from "src/utils/nanoid";
import { once, selectFile, useForceUpdate } from "@voithru/front-core";
import React from "react";

function useFileUpload() {
  const uploaders = React.useRef<MultipartUpload[]>([]);
  const forceUpdate = useForceUpdate();

  const startUpload = React.useCallback(async () => {
    const target = uploaders.current.find((it) => it.status === "READY");
    const uploading = uploaders.current.find((it) => it.status === "START");
    if (!target || uploading) {
      return;
    }

    try {
      target.start().then(() => startUpload());
    } catch (error) {
      console.error(error);
    }
  }, [uploaders]);

  React.useEffect(() => {
    const target = uploaders.current.find((it) => it.status !== "DONE");
    if (!target) {
      return;
    }

    startUpload();
  }, [startUpload, uploaders]);

  const uploadFileUnit = React.useCallback(
    (file: File, customName?: string) => {
      try {
        const namedFile: NamedFile = {
          NANOID: nanoid(),
          name: customName || file.name,
          file,
        };
        const uploader = new MultipartUpload(namedFile);

        uploader.addEventListener("progress", () => forceUpdate());
        uploader.addEventListener("start", () => forceUpdate());
        uploader.addEventListener("cancel", () => {
          uploaders.current = uploaders.current.filter((it) => it !== uploader);
          forceUpdate();
        });
        uploader.addEventListener("done", () => forceUpdate());

        uploaders.current = uploaders.current.concat(uploader);
        forceUpdate();

        uploader.ready().then(() => startUpload());
        startUpload();
        return uploader;
      } catch (error) {
        console.error("ProjectCreateInfo.uploadFile", error);
      }
    },
    [forceUpdate, startUpload],
  );
  const uploadFile = React.useCallback(
    (file: File | File[] | FileList) => {
      const arr = file instanceof File ? [file] : Array.from(file);

      for (const file of arr) {
        uploadFileUnit(file);
      }

      return uploaders.current;
    },
    [uploadFileUnit],
  );

  const handleSelectFile = React.useMemo(
    () =>
      once(async () => {
        const files = await selectFile({ multiple: true });
        if (!files) {
          return;
        }

        const arr = Array.from(files);

        if (arr.length === 0) {
          return;
        }

        return await uploadFile(arr);
      }),
    [uploadFile],
  );

  const clear = React.useCallback(
    (namedFileId: string) => {
      const index = uploaders.current.findIndex(
        (it) => it.namedFile.NANOID === namedFileId,
      );
      if (index < 0) {
        return;
      }

      // uploaders.current[index]?.cancel();
      uploaders.current.splice(index, 1);
      forceUpdate();
    },
    [forceUpdate],
  );

  return {
    uploaders: uploaders.current,
    uploadFile,
    uploadFileUnit,
    handleSelectFile,
    clear,
  };
}

export default useFileUpload;
