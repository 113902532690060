import * as S from "./Footer.style";
import { Link } from "react-router-dom";
import { forwardRef } from "react";

const Footer = forwardRef((props) => {
  return (
    <>
      <S.Footer className="footer">
        <div className="wrapper">
          {/* Logo section */}
          <article className="logo-wrapper">
            <div className="logo-inner-wrapper">
              <img
                className="logo"
                src="https://cdn.imweb.me/thumbnail/20210902/911622663ed69.png"
                alt="panoplay"
                title="panoplay"
              />
            </div>
            <address className="address">
              <span>(주)보이스루</span>
              <span>서울특별시 강남구 강남대로 374,10층</span>
              <span>(역삼동,케이스퀘어 강남2)</span>
              <span>대표자: 이상헌 | 사업자등록번호: 342-88-01221 </span>
            </address>
          </article>

          {/* service section */}
          <article className="service-area">
            <strong className="title">Service</strong>
            <div className="service-box">
              <Link to="https://panoplay.io/videotranslation">영상 콘텐츠 번역</Link>
              <Link to="https://jamakeblack.imweb.me/text_novel_translation">텍스트﹒웹소설 번역</Link>
              <Link to="https://jamakeblack.imweb.me/webtoon_translation">웹툰 번역</Link>
            </div>
          </article>

          {/* contact section */}
          <article className="contact-area">
            <strong className="title">Contact</strong>
            <div className="contact-box">
              <span>문의 가능 시간: 10시~19시</span>
              <span>영상 번역 문의: 070-8820-3116</span>
              <span>웹툰/웹소설 번역 문의: 070-4709-3117</span>
            </div>

            <div className="bottom contact-box">
              <Link to={"mailto:vendor.hr@voithru.com"}>번역가 채용 문의</Link>
              <Link to={"mailto:sales@voithru.com"}>기업 번역 문의</Link>
              <Link to={"mailto:business@voithru.com"}>사업 제휴 문의</Link>
            </div>
          </article>

          {/* family-site section */}
          <article className="family-site">
            <span className="title">Family site</span>
            <div className="family-site-box-wrapper">
              <Link
                to={"https://voithru.com"}
                target="_blank"
              >
                Voithru
              </Link>
              <Link
                to={"https://totus.pro"}
                target="_blank"
              >
                totus
              </Link>
              <Link
                to={"https://jamake.io/ko"}
                target="_blank"
              >
                Jamake
              </Link>
            </div>
          </article>
        </div>
        {/* policy-area section */}
        <article className="policy-area">
          <Link
            to={"https://voithru.notion.site/fbbb8f8dfb574cd3b47f64c18959c181"}
            target="_blank"
          >
            이용약관
          </Link>
          <Link
            to={"https://voithru.notion.site/2894398c0a3e47a3baae51e6fc1d1d96"}
            target="_blank"
          >
            개인정보처리방침
          </Link>
        </article>
      </S.Footer>
    </>
  );
});
export default Footer;
