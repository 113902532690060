import wraningImg from "images/ic-emoji-warning-24@3x.png";
import GlobalModal from "components/common/GlobalModal";
import useWindowSize from "src/utils/useWindowSize";

interface IProps {
  handleCancel: () => void;
  modalContent: string;
}

const ModalPortal = ({ handleCancel, modalContent }: IProps) => {
  const isWeb = useWindowSize();
  return (
    <GlobalModal
      size={isWeb ? "medium" : "small"}
      handleCancelClick={handleCancel}
    >
      <div className="globalModal-content-wrapper">
        <header className="header">
          <img
            className="header-warn-img"
            src={wraningImg}
            alt="warning Image"
          />
          <span className="header-text">{modalContent}</span>
        </header>
        <button
          className="confirm-button"
          onClick={handleCancel}
        >
          확인
        </button>
      </div>
    </GlobalModal>
  );
};
export default ModalPortal;
