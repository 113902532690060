import axios from "axios";

const loggingApiInstance = axios.create({
  baseURL: process.env.REACT_APP_LOGGER_BASE_URL,
  timeout: 10000,
  withCredentials: true,
});

export const loggingAction = (type: string, data?: string, path?: string) => {
  loggingApiInstance.post("api/logs", {
    app_name: "panoplay-platform-front",
    env: process.env.REACT_APP_ENV,
    type,
    user_agent: navigator.userAgent,
    path: path || window.location.href,
    datetime: new Date().toISOString(),
    referer: document.referrer,
    data,
  });
};
