import React from "react";
import {gql, useMutation} from "@apollo/client";
import {useMount} from "@voithru/front-core";
import {COMPLETE_PREPAYMENTWAITING_REQUEST} from "pages/Payment/mutation";
import {useLoadPrepaymentWaiting} from "pages/Payment/useLoadPrepaymentWaiting";
import {IPrepaymentWaiting} from "pages/Payment/model/PrepaymentWaiting";
import {estimatedProductPrice} from "utils/util";
import { useLocation } from "react-router-dom";



interface IProps {
    prepaymentWaitingId: string
}

let isIamportScriptMount = false

function useIamportPayment(){
    const [isMount, setIsMount] = React.useState(false)
    const location = useLocation();

    useMount(() => {
        if(isIamportScriptMount){
            return
        }
        
        const iamportScript = document.createElement("script");
        iamportScript.src = "https://cdn.iamport.kr/v1/iamport.js";
        iamportScript.async = true;
        document.body.appendChild(iamportScript);
        iamportScript.addEventListener("load", () => {setIsMount(true)})
        setIsMount(true)
        isIamportScriptMount = true
    })

    const processPayment = async (prepaymentWaiting: IPrepaymentWaiting) => {
        return new Promise((resolve, reject) => {
            const {IMP} = window as any;
            IMP.init("imp61739018");
            IMP.request_pay({
                pg: "html5_inicis",
                pay_method: "card",
                merchant_uid: prepaymentWaiting.id,
                name: prepaymentWaiting.quotation.sourceDirectory?.files[0]?.name,
                amount: prepaymentWaiting.quotation.quotationProducts.reduce((acc, cur) => acc + Number(cur.productPrice?.proposedPrice?.amount), 0),
                buyer_email: prepaymentWaiting.quotation.customer.email?.address,
                buyer_name: prepaymentWaiting.quotation.customer.name,
                buyer_tel: prepaymentWaiting.quotation.customer.phone?.number,
                m_redirect_url: `${window.location.origin}/payment?prepaymentWaitingId=${prepaymentWaiting.id}`
            }, (rsp: any) => {
                if (rsp.success) {
                    resolve(rsp)
                } else {
                    reject(rsp)
                }
            })
        })
    }

    return {
        isMount,
        processPayment
    }
}


function usePayment (props: IProps){
    const {prepaymentWaitingId} = props;
    const {loading: loadingFetch, prepaymentWaiting} = useLoadPrepaymentWaiting(prepaymentWaitingId);
    const {processPayment, isMount} = useIamportPayment()
    const [completePrepaymentWaiting, { data, loading, error }] = useMutation(
        COMPLETE_PREPAYMENTWAITING_REQUEST,
    );

    const createPrepaymentWaiting = async (
        // paymentMethod: string
    ): Promise<IPrepaymentWaiting> => {
        if(prepaymentWaiting == null){
            throw new Error("Quotation is null")
        }

        if(!isMount){
            throw new Error("Iamport is not mounted")
        }

        if(prepaymentWaiting.expiredDate < new Date()){
            throw new Error("Expired date")
        }

        const rsp = await processPayment(prepaymentWaiting) as {merchant_uid: string}
        await completePrepaymentWaiting({variables: {
            request: {
                prepaymentWaiting: {id: prepaymentWaiting?.id},
                paymentKey: rsp.merchant_uid,
                provider: "IAMPORT",
            }
    }})
        return prepaymentWaiting!
    }

    const processMobileIamportPayment = async () => {
        const isIamportSuccess = new URLSearchParams(location.search).get("imp_success") === "true"
        const isIamportFail = new URLSearchParams(location.search).get("imp_success") === "false"
        const merchantUid = new URLSearchParams(location.search).get("merchant_uid")

        if(isIamportFail){
            throw new Error("결제 실패")
        }

        if(isIamportSuccess){
            completePrepaymentWaiting({variables: {
                request: {
                    prepaymentWaiting: {id: prepaymentWaiting?.id},
                    paymentKey: merchantUid,
                    provider: "IAMPORT",
                }
            }})
        }
    }

    return {
        loadingFetch,
        prepaymentWaiting,
        createPrepaymentWaiting,
        processMobileIamportPayment,
    }


}

export default usePayment