import * as S from "./HelpTooltip.style";
import { useState } from "react";
import HelpIcon from "src/assets/images/ic-texttranslation-help.png";
import { Tooltip } from "@voithru/voithru-design-system-dev/dist";

export interface PosProps {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface IProps {
  pos: PosProps;
}

const HelpTooltip = (props: IProps) => {
  const [isActiveToolTip, setActiveToolTip] = useState(false);
  const { pos } = props;

  return (
    <S.HelpTooltip pos={pos}>
      {isActiveToolTip && (
        <Tooltip
          id="tooltip"
          className={"tooltip"}
          pointerDirection={"right"}
          color={"#1d2230"}
          backgroundColor={"#ffffff"}
          boxShadow={
            "0 0 8px 0 rgba(67, 86, 100, 0.12), 0 0 4px 0 rgba(67, 86, 100, 0.1)"
          }
        >
          · 파일 속 텍스트 양을 산출해 예상 견적을 내 드립니다.
          <br /> · 이미지 내의 텍스트는 견적에서 제외됩니다.
          <br /> · 파일은 견적 외 목적으로 사용되지 않으며, 보안을 엄수합니다.{" "}
          <br />· 파일은 1개만 첨부할 수 있습니다.
        </Tooltip>
      )}

      <button
        className="help-btn"
        onMouseOver={() => setActiveToolTip(true)}
        onMouseOut={() => setActiveToolTip(false)}
      >
        <img
          src={HelpIcon}
          alt="도움말"
          title="도움말"
        />
        <span className="text">도움말</span>
      </button>
    </S.HelpTooltip>
  );
};

export default HelpTooltip;
