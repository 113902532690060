import styled from "styled-components";
import { ellipsis } from "src/styles/mixin";
import { tabletSize } from "src/utils/layout";

export const AttachMentsHeader = styled.header`
  display: flex;
  justify-content: space-between;

  .h4-text {
    align-self: center;
  }

  .text-change-button {
    padding: 10px 14px;
    border-radius: 3px;
    background-blend-mode: multiply;
    background-image: linear-gradient(to bottom, #f0f1f5, #f0f1f5);
    font-size: 14px;
    font-weight: 600;
    background-color: #000;
    color: #fff;
  }

  @media (min-width: ${tabletSize}) {
    margin-top: 30px;
  }
`;

export const AttachMentsContent = styled.section`
  margin-top: 20px;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 1px 11px 0 rgba(122, 139, 169, 0.1);
  border: solid 1px rgba(151, 151, 151, 0.3);
  background-color: #fff;
  box-sizing: border-box;
  position: relative;
  .file-text {
    font-size: 22px;
    font-weight: 700;
  }

  .file-types {
    display: block;
    margin-top: 11px;
    color: #3e4553;
    font-size: 12px;
    font-weight: 400;
  }

  .file-limit {
    margin-top: 5px;
    opacity: 0.5;
    color: #3e4553;
    font-size: 14px;
  }

  .limit-size-text {
    color: #fb625b;
    margin-top: 5px;
    font-size: 14px;
  }

  .file-info {
    position: relative;
    margin-top: 52px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    border-radius: 11px;
    padding: 15px 24px;
    border: solid 0.7px rgba(151, 151, 151, 0.3);
    background-color: #fff;

    .file-remove-btn {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 6px;
      right: 6px;
      cursor: pointer;
    }

    .file-left-area {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      align-items: center;

      .file-name {
        font-weight: 400;
        color: #3e4553;
        max-width: 460px;
        vertical-align: middle;
        ${ellipsis(1)};
      }

      .file-type {
        padding: 5px 9px;
        border-radius: 16px;
        border: solid 1px #3e4553;
        font-weight: 400;
        margin-left: 12px;
        color: #3e4553;
        vertical-align: middle;
      }
    }

    .file-row-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .file-row {
      display: flex;
      align-items: center;
      gap: 10px;

      .total {
        width: 50px;
        font-size: 14px;
        font-weight: 400;
      }

      .words {
        text-align: right;
        font-size: 14px;
        color: #3e4553;
        font-weight: 600;
      }

      .file-size {
        width: 55px;
        color: #7b7b7b;
        text-align: right;
        font-size: 11px;
        font-weight: 500;
      }

      .file-correct {
        width: 62px;
        text-align: right;
        color: #3981ff;
        font-size: 11px;
        font-weight: 500;
      }

      .file-error {
        color: red;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .file-upload {
    text-align: right;

    input[type="file"] {
      display: none;
    }

    label {
      font-size: 17.2px;
      font-weight: 500;

      cursor: pointer;
      border-radius: 4.9px;
      background-color: #000;
      color: white;
      padding: 14px 15px;
      margin-top: 68px;
      display: inline-block;
    }
  }

  @media (min-width: ${tabletSize}) {
    .file-text {
      font-size: 28px;
    }

    .file-types {
      font-size: 16px;
      color: #3e4553;
    }

    .file-info {
      flex-direction: row;
      .file-left-area {
        flex-direction: row;

        .file-type {
          border-radius: 16px;
          padding: 7px 12px;
        }
      }

      .file-row-wrapper {
        flex-direction: column;
        gap: 6px;
      }

      .file-row {
        .total {
          width: 58px;
          font-size: 16px;
        }

        .words {
          width: 60px;
        }

        .word {
          font-size: 16px;
        }
      }
    }
  }
`;

export const AttachMentsWrapper = styled.section`
  background: #fff;
  padding: 20px;

  @media (min-width: ${tabletSize}) {
    background: transparent;
    padding: 0;
  }
`;
