import styled from "styled-components";
import { PosProps } from "./index";

export const HelpTooltip = styled.div<{ pos: PosProps }>`
  .tooltip {
    position: absolute;
    top: ${(props) => props.pos.top && props.pos.top}px;
    left: ${(props) => props.pos.left && props.pos.left}px;
    bottom: ${(props) => props.pos.bottom && props.pos.bottom}px;
    right: ${(props) => props.pos.right && props.pos.right}px;
    width: 253px;

    font-size: 12px;
    line-height: 1.42;
    font-weight: 500;
  }

  .help-btn {
    height: 23px;
    box-sizing: border-box;
    padding: 0 6px;
    border-radius: 12px;
    border: solid 0.5px rgba(151, 151, 151, 0.2);
    background-color: rgba(248, 248, 252, 0.2);

    .text {
      font-size: 9.8px;
      margin-left: 5px;
      color: black;
      vertical-align: 1px;
    }
  }
`;
