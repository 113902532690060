import { tabletSize } from "src/utils/layout";
import styled from "styled-components";

export const EstimateRequestCompletionWrapper = styled.section`
  text-align: center;
  padding: 100px 0 20px 0;

  .msg-1 {
    font-size: 26px;
    font-weight: 700;
    margin-top: 41px;
    margin-bottom: 29px;
    line-height: 1.27;
    letter-spacing: -0.74px;
  }

  .msg-2 {
    opacity: 0.7;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 56px;
    line-height: 1.43;
    letter-spacing: -0.4px;
  }

  .link {
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    background-color: black;
    text-align: center;
    display: inline-block;
    width: 307px;
    padding: 20px 0;
    border-radius: 7px;
  }

  @media (min-width: ${tabletSize}) {
    padding: 200px 0;
    .msg-1 {
      margin-top: 22px;
      font-size: 42px;
    }

    .msg-2 {
      font-size: 20px;
    }
  }
`;
