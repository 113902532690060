import { DocumentType } from "src/types/graphql";

export const fieldOptions = [
  { key: DocumentType.Novel, option: "소설" },
  { key: DocumentType.Screenplay, option: "대본" },
  { key: DocumentType.Synopsis, option: "시놉시스" },
  { key: DocumentType.Introduction, option: "소개서" },
  { key: DocumentType.Lecture, option: "강의자료" },
  { key: DocumentType.Thesis, option: "논문" },
  { key: DocumentType.Contract, option: "계약서" },
  { key: DocumentType.Other, option: "기타" },
];

export type langKeyType =
  | "한국어"
  | "영어"
  | "일본어"
  | "중국어(간체)"
  | "중국어(번체)"
  | "스페인어"
  | "베트남어"
  | "인도네시아어"
  | "태국어"
  | "러시아어";

export const languageType: Record<string, string> = {
  한국어: "LGC0001",
  영어: "LGC0002",
  일본어: "LGC0003",
  중국어: "LGC0004",
  "중국어(간체)": "LGC0004",
  "중국어(번체)": "LGC0005",
  스페인어: "LGC0006",
  베트남어: "LGC0007",
  인도네시아어: "LGC0008",
  태국어: "LGC0009",
  러시아어: "LGC0010",
};

export const workloadUnitType = {
  WORD: "WUC0005",
  LETTER: "WUC0004",
};
