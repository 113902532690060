import { tabletSize } from "src/utils/layout";
import styled from "styled-components";

export const PaymentcompletionWrapper = styled.section`
  text-align: center;
  padding: 80px 20px;
  max-width: 840px;
  margin: 0 auto;

  .msg-1 {
    font-size: 42px;
    font-weight: 700;
    margin: 18px 0;
  }

  .msg-2 {
    opacity: 0.7;
    font-size: 24px;
    font-weight: 700;

    margin-bottom: 49px;
  }

  section {
    border-radius: 11px;
    box-shadow: 0 1px 10px 0 rgba(122, 139, 169, 0.1);
    border: solid 0.9px rgba(151, 151, 151, 0.3);
    background-color: #fff;
    padding: 30px;

    .row {
      display: flex;
      justify-content: space-between;

      .left-text {
        text-align: left;
        display: inline-block;
        width: 180px;
      }

      .right-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14.4px;
        letter-spacing: -0.36px;
      }

      &:not(:last-child) {
        padding: 17.5px 0;
        border-bottom: solid 0.9px rgba(151, 151, 151, 0.3);
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-top: 17.5px;
      }

      .important-text {
        font-size: 25.2px;
        font-weight: 600;
      }

      .price {
        margin-left: 5px;
        margin-right: 2px;
      }

      .bold-text {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  @media (min-width: ${tabletSize}) {
    padding: 134px 0;

    .msg-2 {
      font-size: 36px;
    }
  }
`;
