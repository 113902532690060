import axios from "axios";
import { trimFractionDigits } from "@voithru/front-core";
import { extensionCode } from "utils/file";

const sha256 = require("fast-sha256");

class UploadRequest {
  private acl = "private";
  private "X-Amz-Algorithm" = "AWS4-HMAC-SHA256";
  private "x-amz-server-side-encryption" = "AES256";
  private "x-amz-meta-tag" = "";
  private key: string;
  private "Content-Type": string;
  private "X-Amz-Credential": string;
  private "X-Amz-Date": string;
  private policy: string;
  private "x-amz-signature": string;
  private file: File;

  constructor(
    key: string,
    contentType: string,
    credential: string,
    date: string,
    policy: string,
    signature: string,
    file: File,
  ) {
    this.key = key;
    this["Content-Type"] = contentType;
    this["X-Amz-Credential"] = credential;
    this["X-Amz-Date"] = date;
    this.policy = policy;
    this["x-amz-signature"] = signature;
    this.file = file;
  }

  public toFormData() {
    const formData = new FormData();
    for (const key of Object.keys(this)) {
      // @ts-ignore
      formData.append(key, this[key]);
    }
    return formData;
  }
}

export interface Cancel {
  message: string;
}

export interface Canceler {
  (message?: string): void;
}

export interface CancelToken {
  promise: Promise<Cancel>;
  reason?: Cancel;

  throwIfRequested(): void;
}

export interface CancelTokenSource {
  token: CancelToken;
  cancel: Canceler;
}

export const uploadFileToS3 = (
  bucket: string,
  uploadRequest: UploadRequest,
  cancelToken: CancelTokenSource,
) => {
  const instance = axios.create();
  const requestFormData = uploadRequest.toFormData();
  return instance.post(`https://${bucket}.s3.amazonaws.com/`, requestFormData, {
    headers: { "Content-Type": "multipart/form-data" },
    cancelToken: cancelToken.token,
  });
};

export const sha256Checksum = (buffer: ArrayBuffer) => {
  const SHA256 = sha256(new Uint8Array(buffer));
  return Buffer.from(SHA256).toString("hex");
};

export enum ByteSize {
  BYTE = 1,
  KILO = ByteSize.BYTE * 1000,
  MEGA = ByteSize.KILO * 1000,
  GIGA = ByteSize.MEGA * 1000,
}

export function getByteString(byte: number, length = 2) {
  if (byte / ByteSize.GIGA >= 1) {
    return trimFractionDigits(byte / ByteSize.GIGA, length) + " GB";
  } else if (byte / ByteSize.MEGA >= 1) {
    return trimFractionDigits(byte / ByteSize.MEGA, length) + " MB";
  } else if (byte / ByteSize.KILO >= 1) {
    return trimFractionDigits(byte / ByteSize.KILO, length) + " KB";
  }

  return byte + " bytes";
}

export function makeUuid(): string {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

export const estimatedProductPrice = (fileName: string) => {
  if (fileName !== null) {
    const extension = fileName.split(".").pop()?.toUpperCase();
    const findExtension = extensionCode.find((ex) => ex.name === extension);
    return findExtension?.code;
  }
};
