import { tabletSize } from "src/utils/layout";
import styled from "styled-components";

export const AdditionalRequest = styled.section`
  padding: 20px;
  background: #fff;

  .partial {
    font-size: 13px;
    font-weight: 500;
    margin-left: 5px;
    vertical-align: 2px;
    opacity: 0.66;
  }

  .request-text-area {
    margin-top: 20px;
    box-shadow: 0 1px 11px 0 rgba(1, 139, 169, 0.1);
  }

  @media (min-width: ${tabletSize}) {
    margin-top: 30px;
    padding: 0;
    background: transparent;
  }
`;
