import * as S from "./UserInfo.style";
import { Input } from "@voithru/voithru-design-system-dev/dist";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { contactAtom } from "src/recoil/atoms/textTranslation";
import useTextTranslation from "src/hooks/useTextTranslation";
import ModalPortal from "src/components/ModalPortal";
import { logging } from "src/utils/logger";

enum inputStatus {
  Normal = "normal",
  Disabled = "disabled",
  Error = "error",
  Loading = "loading",
  Success = "success",
  Must = "must",
}

const UserInfo = () => {
  const { handleSubmitEstimate, isError, setIsError } = useTextTranslation();
  const navigate = useNavigate();
  const [nameStatus, setNameStatus] = useState(inputStatus.Must);
  const [emailStatus, setEmailStatus] = useState(inputStatus.Must);
  const [phoneStatus, setPhoneStatus] = useState(inputStatus.Must);

  const [contact, setContact] = useRecoilState(contactAtom);

  const phoneRegExp = /^\d{2,3}-?\d{3,4}-?\d{4}$/;
  const emailRegExp =
    /([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

  const handleClickedSubmit = async () => {
    const { email, name, phone } = contact;

    if (name === "") {
      alert("업체명 / 담당자명을 입력해주세요.");
      return;
    }

    if (email.address === "" || emailStatus !== inputStatus.Success) {
      alert("이메일을 입력해주세요.");
      return;
    }

    if (phone.number === "" || phoneStatus !== inputStatus.Success) {
      alert("전화번호를 입력해주세요.");
      return;
    }

    const success = await handleSubmitEstimate();

    if (success) {
      navigate("/estimateRequestCompletion");
      logging(
        "click",
        JSON.stringify({
          location: "userInfo",
          type: "btn",
          detail: "Click the Send button and send an estimate",
        }),
      );
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setContact({ ...contact, email: { address: e.target.value } });
    if (emailRegExp.test(e.target.value)) {
      setEmailStatus(inputStatus.Success);
    } else {
      setEmailStatus(inputStatus.Error);
    }
  };

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    setContact({
      ...contact,
      phone: { callingCode: { id: "+82" }, number: e.target.value },
    });
    if (phoneRegExp.test(e.target.value)) {
      setPhoneStatus(inputStatus.Success);
    } else {
      setPhoneStatus(inputStatus.Error);
    }
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setContact({ ...contact, name: e.target.value });
    if (contact.name.trim().length > 0) {
      setNameStatus(inputStatus.Success);
    } else {
      setNameStatus(inputStatus.Error);
    }
  };

  if (isError) {
    return (
      <ModalPortal
        handleCancel={() => setIsError(false)}
        modalContent={"알 수 없는 에러 발생. 다시 작성해주세요."}
      />
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.UserInfoWrapper>
      <section>
        <h4 className="h4-text">1. 업체명 / 담당자명</h4>
        <Input
          className="user-input"
          label={"업체명 / 담당자명을 적어주세요"}
          placeholder={"업체명 / 담당자명을 적어주세요"}
          status={nameStatus}
          value={contact.name}
          onChange={(e) => handleNameChange(e)}
          width={"100%"}
        />
      </section>
      <section>
        <div className="email-phone-area">
          <div className="left-area">
            <h4 className="h4-text">2. 이메일</h4>
            <Input
              width={"100%"}
              className="user-input user-email"
              label={"이메일을 적어주세요"}
              placeholder={"이메일을 적어주세요"}
              status={emailStatus}
              value={contact.email.address}
              onChange={(e) => handleEmailChange(e)}
              type={"text"}
              name={"email"}
              autoComplete="off"
            />
          </div>
          <div className="right-area">
            <h4 className="h4-text">3. 전화번호</h4>
            <Input
              label={"전화번호를 적어주세요(-없이)"}
              className="user-input user-phone"
              placeholder={"전화번호를 적어주세요(-없이)"}
              value={contact.phone.number}
              width={"100%"}
              onChange={(e) => handlePhoneChange(e)}
              type={"phone"}
              status={phoneStatus}
            />
          </div>
        </div>
      </section>
      <div className="submit-btn-wrapper">
        <button
          type="button"
          className="submit-btn"
          onClick={handleClickedSubmit}
        >
          보내기
        </button>
      </div>
    </S.UserInfoWrapper>
  );
};
export default UserInfo;
