import { gql } from "@apollo/client";

export const CREATE_DOCUMENT_QUOTATION_REQUEST = gql`
  mutation CreateDocumentQuotationRequest($request: DocumentQuotationRequestCreateRequestInput!) {
    createDocumentQuotationRequest(request: $request) {
      ... on DocumentQuotationRequestCreateResponse {
        quotationRequest {
          id
        }
      }
    }
  }
`;

export const CREATE_TEMPORARY_FILE = gql`
  mutation createFileTemporary($request: FileTemporaryCreateRequestInput!) {
    createFileTemporary(request: $request) {
      ... on FileTemporaryCreateResponse {
        succeed
        file {
          id
          s3Object {
            bucket
            key
            s3ObjectUrl
          }
          resource {
            ... on DocumentResource {
              wordCount
              letterCount
            }
          }
        }
      }
    }
  }
`;
