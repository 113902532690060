import styled from "styled-components";

import kakaoImage from "src/assets/images/img-texttranslation-kakaopay.png";
import naverImage from "src/assets/images/img-npay.png";
import activeCheckMarkImage from "images/ic-radio-active-square-18.png";
import { tabletSize } from "src/utils/layout";

export const PaymentWrapper = styled.section`
  margin: 0 auto;
  margin-top: 7px;

  .h2-text {
    padding: 20px;
    font-size: 24px;

    .notice-text {
      margin-left: 6px;
    }
  }
  section {
    background-color: #fff;
    padding: 20px;

    .row {
      display: flex;
      justify-content: space-between;

      .left-text {
        display: inline-block;
        width: 180px;
      }

      .right-text {
        font-size: 14.4px;
        letter-spacing: -0.36px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:not(:last-child) {
        padding: 17.5px 0;
        border-bottom: solid 0.9px rgba(151, 151, 151, 0.3);
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-top: 17.5px;
      }

      .important-text {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.36px;
        color: #000;
      }

      .price {
        color: #b56b00;
        margin-left: 5px;
        margin-right: 5px;
      }

      .bold-text {
        font-weight: 600;
      }
    }
  }
  .section2 {
    margin-top: 7px;
    .important-text {
      display: block;
      font-size: 16px;
      font-weight: 600;
      border-bottom: 0;
    }

    .payment-method-area {
      margin-top: 18px;

      .payment-method-button {
        display: flex;
        border-radius: 8px;
        border: solid 1px #d2d8e4;
        background-color: #fff;
        padding: 20px;
        box-sizing: border-box;

        label[class|="radio-box"] {
          height: 18px;
          display: flex;
          margin-right: 11px;
        }

        label[for="kakaoPay"] {
          color: transparent;
          background-image: url(${kakaoImage});
          background-size: 59px 24px;
        }

        label[for="naverPay"] {
          color: transparent;
          background-image: url(${naverImage});
          background-size: 59px 24px;
        }

        .label-title {
          font-size: 15px;
          font-weight: 600;

          cursor: pointer;
          vertical-align: middle;
        }

        input {
          display: none;
        }

        input[type="radio"] + .checkmark {
          width: 18px;
          height: 18px;
          display: inline-block;
          border-radius: 6px;
          margin-right: 11px;
        }

        input[type="radio"]:checked + .checkmark {
          background-image: url("${activeCheckMarkImage}");
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .payment-btn {
    font-size: 18.2px;
    font-weight: 500;
    width: 100%;
    color: white;
    text-align: center;
    background-color: black;
    padding: 20px 0;
    border-radius: 4px;
    margin-bottom: 7px;

    &:disabled {
      background-color: #d2d8e4;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  @media (min-width: ${tabletSize}) {
    width: 840px;
    padding: 30px 0 50px 0;
    background: transparent;

    .h2-text {
      padding: 0;
      font-size: 40px;
      .notice-text {
        margin-left: 6px;
      }
    }

    .important-text {
      font-size: 25px;
    }

    section {
      padding: 30px;
      margin-top: 30px;
      border-radius: 11px;
      box-shadow: 0 1px 10px 0 rgba(122, 139, 169, 0.1);
      border: solid 0.9px rgba(151, 151, 151, 0.3);
    }

    .section2 {
      margin-top: 30px;
      .important-text {
        font-size: 28px;
        padding-bottom: 14.5px;
        border-bottom: solid 1px rgba(151, 151, 151, 0.3);
      }

      .payment-method-area {
        margin-top: 29.5px;
      }
    }
    .payment-btn {
      margin-top: 30px;
    }
  }
`;
