import { gql, useQuery } from "@apollo/client";
import { IPrepaymentWaiting } from "pages/Payment/model/PrepaymentWaiting";

const GET_GREETING = gql`
  query prepaymentWaiting($id: UUID!) {
    prepaymentWaiting(id: $id) {
      id
      completed
      createdDate
      expiredDate
      paymentAmount
      quotation {
        id
        sourceUrl
        sourceDirectory {
          files {
            name
          }
        }
        quotationProducts {
          quotation {
            content {
              metadata {
                ... on DocumentContentMetadata {
                  type
                }
              }
            }
          }
          work {
            ... on DocumentQuotationWork {
              sourceLanguage {
                id
              }
              targetLanguages {
                id
              }
              workload {
                amount
                unit {
                  id
                }
              }
            }
          }
          schedule {
            ... on DocumentQuotationSchedule {
              deliveryDeadline
            }
          }
          productPrice {
            proposedPrice {
              id
              amount
              currency {
                id
              }
            }
          }
        }
        quotationRequest {
          requirement {
            expectedDeliveryDate
          }
          source {
            ... on DocumentQuotationRequestSourceGqlModel {
              type
            }
          }
          work {
            ... on DocumentQuotationRequestWork {
              sourceLanguage {
                id
              }
              targetLanguages {
                id
              }
            }
          }
          workload {
            amount
            unit {
              id
            }
          }
        }
        customer {
          id
          name
          email {
            address
          }
          phone {
            number
          }
        }
      }
    }
  }
`;

export function useLoadPrepaymentWaiting(prepaymentWaitingId: string) {
  const { loading, error, data } = useQuery<{
    prepaymentWaiting: IPrepaymentWaiting;
  }>(GET_GREETING, {
    variables: {
      id: prepaymentWaitingId,
    },
  });

  return {
    loading,
    prepaymentWaiting: data?.prepaymentWaiting,
  };
}
