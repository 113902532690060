import { TextArea } from "@voithru/voithru-design-system-dev/dist";
import * as S from "./AdditionalRequest.style";
import { useRecoilState } from "recoil";
import { requirementAtom } from "src/recoil/atoms/textTranslation";

const AdditionalRequest = () => {
  const [requirement, setRequirement] = useRecoilState(requirementAtom);
  const onTextAreaChange = (_content: string) => {
    setRequirement({ ...requirement, notes: _content.slice(0, 500) });
  };

  return (
    <S.AdditionalRequest>
      <h4>
        5. 추가 요청사항<span className="partial">(선택)</span>
      </h4>
      <div className="request-text-area">
        <TextArea
          placeholder={"추가 요청사항을 작성해 주세요."}
          maxLength={500}
          status={"normal"}
          width={"100%"}
          height={"115px"}
          style={{ fontSize: "14px" }}
          value={requirement.notes}
          onChange={(e) => onTextAreaChange(e.target.value)}
        />
      </div>
    </S.AdditionalRequest>
  );
};
export default AdditionalRequest;
