import { KeyOfType } from "src/types/utils";
import { Eventer, UnknownFunction } from "@voithru/front-core";
import React from "react";

export function preventEvent(e: React.SyntheticEvent | Event) {
  e.preventDefault();
  e.stopPropagation();
}

export function stopPropagation(ev: React.SyntheticEvent | Event) {
  ev.stopPropagation();
}

type FunctionType<T> = T extends UnknownFunction ? T : never;

type FunctionParameters<T> = Parameters<FunctionType<T>>;
export type SubscribableEventGroup<E> = Pick<
  EventerGroup<E>,
  "addEventListener" | "once"
>;

class EventerGroup<E> {
  #eventer = new Map<keyof E, Eventer<UnknownFunction>>();

  public run = <K extends KeyOfType<E, UnknownFunction>>(
    key: K,
    ...args: FunctionParameters<E[K]>
  ) => {
    return this.#eventer.get(key)?.run(...args);
  };
  // 이벤트 핸들러를 등록한다.
  // 리턴하는 EventerRemoveHandler는 바닐라js의 removeEventListener에 대응하는 로직을 가진다.
  // React.useEffect 내부에서 addEventListener를 호출한 다음,
  // addEventListener의 리턴값을 React.useEffect의 cleanup 로직에 포함시켜 사용한다.
  public addEventListener = <K extends KeyOfType<E, UnknownFunction>>(
    key: K,
    fn: FunctionType<E[K]>,
  ) => {
    const eventer = this.getEventer(key);
    const unsubscribe = eventer.addEventListener(fn);
    return unsubscribe;
  };
  public once = <K extends KeyOfType<E, UnknownFunction>>(
    key: K,
    fn: FunctionType<E[K]>,
  ) => {
    const onceFn = (...args: unknown[]) => {
      unsubscribe();
      return fn(...args);
    };
    const unsubscribe = this.addEventListener(key, onceFn as typeof fn);
    return unsubscribe;
  };

  private getEventer = (key: keyof E) => {
    const instance = this.#eventer.get(key) || new Eventer<UnknownFunction>();
    this.#eventer.set(key, instance);
    return instance;
  };
}

export function waitEvent<E, K extends KeyOfType<E, VoidFunction>>(
  eventGroup: SubscribableEventGroup<E>,
  key: K,
) {
  let unsubscribe = (): void => undefined;
  const event = new Promise<FunctionParameters<E[keyof E]>>((resolve) => {
    const callback = (...args: FunctionParameters<E[K]>) => {
      resolve(args);
    };
    unsubscribe = eventGroup.once(key as never, callback as never);
  });
  return { unsubscribe, event };
}

export default EventerGroup;
