import React from "react";

export interface HookExceptClickOption<E extends HTMLElement> {
  root?: React.RefObject<E>;
}

export type HookExceptClickFunction = (e: MouseEvent) => void;
function useExceptClick<
  RefElement extends HTMLElement = HTMLElement,
  Root extends HTMLElement = HTMLElement,
>(
  ref: React.RefObject<RefElement>,
  onClick: HookExceptClickFunction,
  option?: HookExceptClickOption<Root>,
): React.RefObject<RefElement> {
  const { root } = option ?? {};

  const onExceptClick = React.useCallback(
    (e: Event) => {
      if (!ref.current || !e.target) {
        return;
      }

      if (
        ref.current !== e.target &&
        !ref.current.contains(e.target as HTMLElement)
      ) {
        onClick(e as MouseEvent);
      }
    },
    [ref, onClick],
  );

  React.useEffect(() => {
    const { current } = root ?? {};
    const target = current || globalThis.document?.body;
    target?.addEventListener("click", onExceptClick);
    return () => target?.removeEventListener("click", onExceptClick);
  }, [onExceptClick, root]);

  return ref;
}

export default useExceptClick;
