export type KeyOfType<T, U> = {
  [P in keyof T]: T[P] extends U ? P : never;
}[keyof T];

export function array(length: number): number[];
export function array<T>(length: number, map: (index: number) => T): T[];
export function array<T>(
  length: number,
  map?: (index: number) => T,
): (T | number)[] {
  if (!map) {
    return Array.from(Array(length).keys());
  }

  return Array.from(Array(length), (_, index) => map(index));
}

export function hasProperty<T, K extends PropertyKey>(
  obj: T,
  keys: K[],
): obj is T & Record<K, unknown> {
  return Boolean(
    typeof obj === "object" && obj !== null && keys.every((it) => it in obj),
  );
}

export interface Named {
  name: string;
}

export interface NamedS3Path extends Named {
  s3Path: string;
}
