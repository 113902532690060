import { NamedS3Path } from "src/types/utils";
import useFileUpload from "src/hooks/useFileUpload";
import { useMutation } from "@apollo/client";
import { CREATE_TEMPORARY_FILE } from "src/api/document";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  sourceAtom,
  uploadFileAtom,
  wordCountAtom,
} from "src/recoil/atoms/textTranslation";
import { estimatedProductPrice } from "utils/util";
import useEstimatePriceCalculator from "src/hooks/useEstimatePriceCalculator";
import { useEffect, useState } from "react";

const useCreateS3File = () => {
  const { uploadFileUnit } = useFileUpload();

  const { calculatedEstimatedProductPrice } = useEstimatePriceCalculator();

  const [source, setSource] = useRecoilState(sourceAtom);
  const setWordCount = useSetRecoilState(wordCountAtom);

  const [apiCreateFile, { loading, error }] = useMutation(
    CREATE_TEMPORARY_FILE,
  );
  const [uploadFile, setUploadFile] = useRecoilState(uploadFileAtom);

  const s3FileUpload = (file: File) => {
    // AWS S3 file upload API -> 받은 데이터로 아래
    return new Promise<NamedS3Path | string>((resolve) => {
      const uploader = uploadFileUnit(file);
      if (!uploader) {
        return;
      }

      uploader.addEventListener("done", async () => {
        const { namedFile, s3Path } = uploader;

        if (!s3Path) {
          return;
        }

        const name = namedFile.name || s3Path.split("/").splice(-1, 1)[0] || "";
        const filePath = { name, s3Path };

        try {
          if (name) {
            // TODO: 2개의 함수가 연속적으로 호출되므로 체이닝 형태로 함수 호출
            // TODO: uploadedFile -> converToRequest 함수(namedFile, s3Path) 대체 예정
            const uploadedFile = {
              request: {
                s3ObjectUrl: s3Path,
                extension: { id: estimatedProductPrice(namedFile.name) },
                name: namedFile.name,
                size: namedFile.file.size,
              },
            };

            // TODO: mutateFile(uploadedFile) 함수로 대체 예정
            const response = await apiCreateFile({
              variables: uploadedFile,
            });

            const {
              data: {
                createFileTemporary: { succeed },
              },
            } = response;

            if (succeed) {
              // TODO: useMutateFile의 data에서 file을 꺼내서 셋팅 예정.
              const { file } = response.data.createFileTemporary;

              setSource({ ...source, files: [{ id: file.id }] });
              setWordCount(file.resource || { wordCount: 0, letterCount: 0 });
            }
          }
          resolve(filePath);
        } catch (e) {
          resolve("error");
        } finally {
          setUploadFile(uploader.namedFile.file);
        }
      });
    });
  };

  useEffect(() => {
    calculatedEstimatedProductPrice();
  }, [uploadFile]);

  return { s3FileUpload };
};

export default useCreateS3File;
