import { TextArea } from "@voithru/voithru-design-system-dev/dist";
import * as S from "./TextSelection.style";
import { useRecoilState, useRecoilValue } from "recoil";
import { textAtom, uploadFileAtom } from "src/recoil/atoms/textTranslation";
import { TextFieldStatus } from "@voithru/voithru-design-system-dev/dist/Stories/TextFields/TextArea/Interfaces";
import useCreateTextFile from "src/hooks/useCreateTextFile";
import useCreateS3File from "src/hooks/useCreateS3File";
import useResetFile from "src/hooks/useResetFile";
import { useState } from "react";
import ModalPortal from "components/ModalPortal";
import GlobalModal from "src/components/common/GlobalModal";
import LoadingSpinner from "src/components/common/LoadingSpinner";
import useWindowSize from "src/utils/useWindowSize";
import HelpTooltip from "src/components/common/HelpTooltip";
import { logging } from "src/utils/logger";

interface IProps {
  textStatus: TextFieldStatus;
  setTextStatus: React.Dispatch<React.SetStateAction<TextFieldStatus>>;
  setIsActiveFileOrText: React.Dispatch<React.SetStateAction<boolean>>;
  isActiveFileOrText: boolean;
  textRef: React.MutableRefObject<HTMLElement | null>;
}

const TextSelection = ({
  textStatus,
  setTextStatus,
  textRef,
  isActiveFileOrText,
  setIsActiveFileOrText,
}: IProps) => {
  const isWeb = useWindowSize();
  const [text, setText] = useRecoilState(textAtom);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const uploadFile = useRecoilValue(uploadFileAtom);
  const { s3FileUpload } = useCreateS3File();
  const { createFile } = useCreateTextFile();
  const { clearFileState } = useResetFile();

  const onTextAreaChange = (_content: string) => {
    if (_content.length >= 0 && _content.length < 100) {
      setTextStatus("error");
    } else {
      setTextStatus("normal");
    }
    setText(_content);
  };

  const handleClickTextAddButton = () => {
    if (text.length < 100) {
      setModalContent("텍스트 100자 이상 입력해주세요.");
      setIsShowModal(true);
      return;
    }

    setIsLoadingModal(true);
    const file = createFile(text);

    if (file) {
      s3FileUpload(file).then(() => {
        setModalContent("파일이 정상적으로 등록되었습니다.");
        setIsLoadingModal(false);
        setIsShowModal(true);
        logging(
          "fileUpload",
          JSON.stringify({
            location: "textTranslation",
            type: "txt-btn",
            detail: `extension: txt`,
          }),
        );
      });
    }
  };

  return (
    <S.TextSelection ref={textRef}>
      <header className="header">
        <div className="header-inner">
          <h4 className="h4-text">2. 텍스트</h4>
          <span className="sub-text">
            내용 작성/변경 후 추가하기 버튼을 누르셔야 단어 수가 계산됩니다.
          </span>
        </div>
        <button
          type="button"
          className="file-change-button"
          onClick={() => {
            setIsActiveFileOrText(true);
            clearFileState();
          }}
        >
          파일로 첨부 ＞
        </button>
      </header>
      <div className="text-area">
        <TextArea
          placeholder={"최소 100자 이상 입력해 주세요."}
          maxLength={100}
          status={textStatus}
          width={"100%"}
          height={"115px"}
          style={{ fontSize: "14px" }}
          value={text}
          onChange={(e) => onTextAreaChange(e.target.value)}
        />
      </div>
      <div className="text-add-button-wrapper">
        <div className="text-add-button-inner">
          <span className="text-success">
            {!isActiveFileOrText && uploadFile.size
              ? "텍스트가 업로드 되었습니다."
              : ""}
          </span>
        </div>
        <button
          type="button"
          className="text-add-button"
          onClick={handleClickTextAddButton}
        >
          추가하기
        </button>
      </div>
      {isShowModal && (
        <ModalPortal
          handleCancel={() => setIsShowModal(false)}
          modalContent={modalContent}
        />
      )}
      {isLoadingModal && (
        <GlobalModal size={isWeb ? "medium" : "small"}>
          <LoadingSpinner size={isWeb ? "large" : "medium"} />
        </GlobalModal>
      )}
    </S.TextSelection>
  );
};
export default TextSelection;
