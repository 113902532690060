import { gql, useQuery } from "@apollo/client";
import { CodeAssociation, LanguageCode } from "src/types/graphql";
import React from "react";

const GET_LANGUAGE_CODE = gql`
  query languageCodeByCode($code: String!) {
    languageCodeByCode(code: $code) {
      code
      name
    }
  }
`;

function useLanguageCode(code: string | CodeAssociation) {
  const { data, refetch } = useQuery<{ languageCodeByCode: LanguageCode }>(GET_LANGUAGE_CODE, { variables: { code } });

  React.useEffect(() => {
    refetch();
  }, [code]);

  return {
    languageCode: data?.languageCodeByCode,
  };
}

export default useLanguageCode;
