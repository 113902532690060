import * as S from "./Header.style";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import HeaderLogo from "src/assets/images/img-panoplay-text-logo@3x.png";
import Overlay from "src/components/common/Overlay";
import useWindowSize from "src/utils/useWindowSize";

const Header = () => {
  const isWeb = useWindowSize();
  const [currentUrl, setCurrentUrl] = useState("/textTranslation");
  const [isMenu, setIsMenu] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setCurrentUrl(location.pathname + location.search);
  }, [location.search]);

  const toggleMenu = () => {
    setIsMenu(!isMenu);
  };

  return (
    <S.Header className="header-wrapper">
      {isMenu ? (
        <div
          className="hamburger-menu"
          onClick={toggleMenu}
        >
          <div id="menuToggle">
            <input type="checkbox" />

            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      ) : (
        <>
          <Overlay handleCancelClick={toggleMenu} />
          <button
            className="close-btn"
            onClick={toggleMenu}
          />
          <ul id="menu">
            {/* 번역 서비스 : https://panoplay.io/videotranslation
              SDH : https://panoplay.io/SDH
              MTPE : https://panoplay.io/MTPE
              사례소개 (기존 유지) : https://panoplay.io/casestudy
              회사소개 : https://voithru.com/ */}
            <Link
              to="https://panoplay.io/videotranslation"
              target={"_self"}
            >
              <li>번역 서비스</li>
            </Link>
            <Link
              to="https://panoplay.io/SDH"
              target={"_self"}
            >
              <li>SDH</li>
            </Link>
            <Link
              to="https://panoplay.io/MTPE"
              target={"_self"}
            >
              <li>MTPE</li>
            </Link>
            <Link
              to="https://panoplay.io/casestudy"
              target={"_self"}
            >
              <li>사례 소개</li>
            </Link>
            <Link
              to="https://voithru.com/"
              target={"_self"}
            >
              <li>회사 소개</li>
            </Link>
          </ul>
        </>
      )}

      <div className="header-inner">
        <div className="panoplay-logo-wrapper">
          <Link
            to="https://panoplay.io/"
            target={"_self"}
          >
            <img
              src={HeaderLogo}
              className="panoplay-logo"
              alt="logo"
              title="logo"
            />
          </Link>
        </div>
        <nav className="gnb-menu">
          <Link
            to="https://panoplay.io/videotranslation"
            target={"_self"}
            className="menu-li"
          >
            번역 서비스
          </Link>
          <Link
            to="https://panoplay.io/SDH"
            target={"_self"}
            className="menu-li"
          >
            SDH
          </Link>
          <Link
            to="https://panoplay.io/MTPE"
            target={"_self"}
            className="menu-li"
          >
            MTPE
          </Link>
          <Link
            to="https://panoplay.io/casestudy"
            target={"_self"}
            className="menu-li"
          >
            사례 소개
          </Link>
          <Link
            to="https://voithru.com/"
            target={"_self"}
            className="menu-li"
          >
            회사 소개
          </Link>
        </nav>
        <Link
          to="https://panoplay.io/quotation"
          target={"_self"}
          className="estimate-link"
        >
          견적 의뢰하기
        </Link>
      </div>
    </S.Header>
  );
};
export default Header;
