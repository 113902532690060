import { tabletSize } from "src/utils/layout";
import styled from "styled-components";

export const UserInfoWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  section {
    padding: 20px;
    margin-bottom: 7px;
    background-color: #fff;

    .h4-text {
      margin-bottom: 28px;
    }

    .user-input {
      width: 100%;

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }

      > div {
        width: 100%;
        background-color: white;
      }
    }

    .email-phone-area {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 30px;

      .left-area,
      .right-area {
        width: 100%;
      }
    }
  }

  .submit-btn-wrapper {
    margin-top: 7px;
    background-color: #fff;
    padding: 20px;
  }

  .submit-btn {
    width: 100%;
    font-size: 18.2px;
    font-weight: 500;

    color: white;
    text-align: center;
    background-color: black;
    padding: 20px 0;
    border-radius: 4px;
  }

  @media (min-width: ${tabletSize}) {
    padding-bottom: 80px;
    margin-top: 56px;
    padding-left: 20px;
    padding-right: 20px;

    section {
      padding: 0;
      margin-bottom: 28px;
      background-color: transparent;

      .email-phone-area {
        flex-direction: row;
      }
    }

    .submit-btn-wrapper {
      margin-top: 60px;
      padding: 0;
    }

    .submit-btn {
    }
  }
`;
