import * as S from "./PaymentCompletion.style";

import Logo from "src/assets/images/img-texttranlation-logo.png";
import { useLocation } from "react-router-dom";
import { useLoadPrepaymentWaiting } from "pages/Payment/useLoadPrepaymentWaiting";
import { formatDate } from "@voithru/front-core";
import useSourceCode from "src/hooks/useSourceCode";
import useLanguageCode from "src/hooks/useLanguageCode";
import React, { useEffect } from "react";
import {
  CodeAssociation,
  DocumentContentMetadata,
  DocumentQuotationSchedule,
  DocumentQuotationWork,
} from "src/types/graphql";
import { langKeyType } from "src/utils/textTranslation";
import { logging } from "src/utils/logger";

const PaymentComplete = () => {
  const location = useLocation();
  const paymentId = new URLSearchParams(location.search)
    ?.get("prepaymentWaitingId")!
    .toString();
  const { prepaymentWaiting } = useLoadPrepaymentWaiting(paymentId);

  if (prepaymentWaiting == null) {
    return null;
  }

  const {
    quotation: { content: { metadata } = { metadata: undefined } } = {
      content: {},
    },
    work,
    schedule,
  } = (prepaymentWaiting?.quotation?.quotationProducts[0] as {
    quotation?: { content?: { metadata?: DocumentContentMetadata } };
    work: DocumentQuotationWork;
    schedule: DocumentQuotationSchedule;
  }) || {};

  const sourceType = metadata?.type;

  const sourceLanguageCode = work?.sourceLanguage?.id;

  const targetLanguagesCodes = work?.targetLanguages;

  const totalPrice = prepaymentWaiting.quotation?.quotationProducts?.reduce(
    (acc, cur) => acc + Number(cur.productPrice?.proposedPrice?.amount),
    0,
  );

  const fileName = prepaymentWaiting.quotation?.sourceDirectory?.files[0]?.name;

  return (
    <S.PaymentcompletionWrapper>
      <img
        src={Logo}
        alt="panoplay"
        title="panoplay"
      />
      <p className="msg-1">결제완료</p>
      <p className="msg-2">
        번역이 완료될 시
        <br />
        메일로 안내해 드리겠습니다.
      </p>
      <section>
        <div className="row">
          <strong className="important-TextSelection">결제요약</strong>
          <div>
            <b className="bold-TextSelection">결제금액</b>
            <strong className="important-text price">{totalPrice}</strong>
            <b className="bold-text">원</b>
          </div>
        </div>
        <div className="row">
          <span className="left-text">1. 분야</span>
          <span className="right-text">
            {sourceType && <SourceType code={sourceType} />}
          </span>
        </div>
        <div className="row">
          <span className="left-text">2. 의뢰 파일</span>
          <span className="right-text">{fileName}</span>
        </div>
        <div className="row">
          <span className="left-text">3. 언어 선택</span>
          <span className="right-text">
            {sourceLanguageCode && <LanguageText code={sourceLanguageCode} />} -{" "}
            {targetLanguagesCodes
              ?.map((it: CodeAssociation) => <LanguageText code={it.id} />)
              //@ts-ignore
              .reduce((prev: langKeyType, curr: langKeyType) => [
                prev,
                ", ",
                curr,
              ])}
          </span>
        </div>
        <div className="row">
          <span className="left-text">4. 납품 예정일</span>
          <span className="right-text">
            {schedule?.deliveryDeadline &&
              formatDate(schedule?.deliveryDeadline, "YYYY. MM . DD", {
                isLocale: true,
              })}
          </span>
        </div>
        <div className="row">
          <span>5. 결제수단</span>
          <span className="right-text">카드결제</span>
        </div>
      </section>
    </S.PaymentcompletionWrapper>
  );
};

const SourceType = ({ code }: { code: string }) => {
  const { source } = useSourceCode(code);
  return <>{source?.name}</>;
};

const LanguageText = ({ code }: { code: CodeAssociation | string }) => {
  const { languageCode } = useLanguageCode(code);
  return <>{languageCode?.name}</>;
};

export default PaymentComplete;
