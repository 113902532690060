import { API_VERSION } from "src/constants/api";
import { APIResponse } from "src/types/api";
import {
  S3DownloadRequest,
  S3MultipartCompletePostRequest,
  S3MultipartCompletePostResponse,
  S3MultipartHeaderPostRequest,
  S3MultipartHeaderPostResponse,
  S3PostRequest,
  S3PostResponse,
} from "src/types/api/file";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

interface S3PartUploadParams {
  partNumber: number;
  uploadId: string;
}

interface S3PutPartOptions {
  bucket: string;
  key: string;
  params: S3PartUploadParams;
  headers: {
    Authorization: string;
    "x-amz-date": string;
    "x-amz-content-sha256": string;
    "content-type": "multipart/form";
  };
}

const PATH = `/jamake/public/s3/${API_VERSION}`;

function apiS3(instance: AxiosInstance) {
  return {
    post: (data: S3PostRequest) =>
      instance.post<APIResponse<S3PostResponse>>(PATH + "/accelerate", data),
    uploadPartHeaderValue: {
      post: (data: S3MultipartHeaderPostRequest) =>
        instance.post<APIResponse<S3MultipartHeaderPostResponse>>(
          PATH + "/uploadPartHeaderValue/accelerate",
          data,
        ),
    },
    completeMultipartUpload: {
      post: (data: S3MultipartCompletePostRequest) =>
        instance.post<APIResponse<S3MultipartCompletePostResponse>>(
          PATH + "/completeMultipartUpload/accelerate",
          data,
        ),
    },

    downloadUrl: {
      post: (data: S3DownloadRequest) =>
        instance.post<APIResponse<string>>(PATH + "/downloadUrl", data),
    },

    upload: (
      file: File | Blob | ArrayBuffer,
      options: S3PutPartOptions,
      axiosOptions?: AxiosRequestConfig,
    ) => {
      const { bucket, key, params, headers } = options;

      const S3_PATH = `https://${bucket}.s3-accelerate.amazonaws.com/` + key;
      return axios.put(S3_PATH, file, { ...axiosOptions, params, headers });
    },
  };
}

export default apiS3;
