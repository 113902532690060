type DequeueEachCallback<T> = (value: T) => void | Promise<void>;

export interface ReadonlyQueue<T> {
  readonly array: readonly T[];
}

class Queue<T> implements ReadonlyQueue<T> {
  #array: T[] = [];
  public get array(): readonly T[] {
    return this.#array;
  }

  public enqueue(value: T): void {
    this.#array.push(value);
  }

  public dequeue(): T | undefined {
    const [value] = this.#array.splice(0, 1);
    return value;
  }

  public dequeueEach = async (
    fn: DequeueEachCallback<T>,
    isAwait?: boolean,
  ) => {
    while (this.#array.length > 0) {
      const value = this.dequeue();
      if (!value) {
        break;
      }

      const ret = fn(value);
      if (isAwait && ret instanceof Promise) {
        await ret;
      }
    }
  };
}

export default Queue;
