import { tabletSize } from "src/utils/layout";
import styled from "styled-components";

export const RequestEstimate = styled.section`
  position: sticky;
  bottom: 0;
  padding: 20px;
  .submit-area {
    position: relative;

    .tooltip {
    }

    .submit-btn {
      width: 100%;
      color: white;
      text-align: center;
      background-color: black;
      padding: 20px 0;
      border-radius: 4px;
    }
  }

  @media (min-width: ${tabletSize}) {
    position: relative;
    padding: 0;
    .tooltip {
      min-width: 465px;
      margin-left: 418px;
    }

    .submit-btn {
      margin-bottom: 58px;
    }
  }
`;
